<div *ngIf="varianti.length > 0" class="card widget widget-products d-none d-lg-block" style="margin-top: 20px;">
    <div class="widget__header">
        <div *ngIf="!isMobile" class="product__badge tag-badge tag-badge--new">{{'search.altreVarianti' | translate}}</div>
        <h4 *ngIf="isMobile">{{'search.altreVarianti' | translate}}</h4>
    </div>    
    <div class="widget-products__list">
        <div *ngFor="let product of varianti" class="widget-products__item" style="padding: 0 10px;">
            <div class="widget-products__image image image--type--product" style="width:50px;">
                <a href="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" class="image__body">
                    <img class="image__tag" [src]="product.foto" onerror="this.src='../../../../assets/images/no-image-found.jpg'" alt="{{product.modello}}">
                </a>
            </div>
            <div class="widget-products__info">
                <div class="widget-products__name">
                    <a href="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">{{product.modello | uppercase}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
