<div class="site__body">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
        <div class="container-fluid mainContent content-search">
            <div class="block-header__body">                
                <h1 class="block-header__title">PRIVACY POLICY</h1>
            </div>
        </div>
    </div>
    <div class="block-split block-split--has-sidebar">
        <div class="container-fluid mainContent content-search">
            <div class="post__body typography history">
                <div class="iub_content legal_pp">
                    <h3 id="navigatori">Información sobre el tratamiento de datos personales según los artículos 13-14 del Reglamento de la UE 2016/679</h3><br>
                    <h4>Personas afectadas: Usuarios del sitio web</h4>
                    
                    <p>
                        RMS S.P.A., en calidad de Responsable del Tratamiento de sus datos personales, de acuerdo con y para los efectos del Reglamento de la UE 2016/679, en adelante 'GDPR', le informa que la mencionada normativa prevé la protección de las personas afectadas en relación con el tratamiento de datos personales y que dicho tratamiento se basará en los principios de equidad, legalidad, transparencia y protección de su privacidad y derechos.<br><br>
                        Sus datos personales serán tratados de acuerdo con las disposiciones legislativas del reglamento mencionado y las obligaciones de confidencialidad previstas en el mismo.<br><br>
                        Finalidad y base legal del tratamiento: en particular, sus datos serán tratados para las siguientes finalidades relacionadas con la implementación de obligaciones legales o contractuales:
                    </p>
                    
                    <ul>
                        <li>Acceso técnico y funcional al sitio web, ningún dato se conserva después de cerrar el navegador;</li>
                        <li>Navegación avanzada o gestión de contenidos personalizados;</li>
                        <li>Finalidades estadísticas y de análisis de la navegación y los usuarios.</li>
                    </ul>
                    
                    <p>También se utilizarán sus datos para las siguientes finalidades relacionadas con la ejecución de medidas relacionadas con obligaciones contractuales o precontractuales:</p>
                    <ul>
                        <li>
                            Finalidades relacionadas con la ejecución de un contrato del que usted forma parte o con la ejecución de medidas precontractuales adoptadas a su solicitud (por ejemplo, solicitud de contacto a través del formulario de contacto, etc.).
                        </li>
                    </ul>
                    <p>
                        Modalidades del tratamiento. Sus datos personales podrán ser tratados de las siguientes maneras:
                    </p>
                    <ul>
                        <li>Mediante ordenadores electrónicos con el uso de sistemas de software gestionados por terceros;</li>
                        <li>Mediante ordenadores electrónicos con el uso de sistemas de software gestionados o programados directamente;</li>
                        <li>Tratamiento temporal en forma anónima.</li>
                    </ul>
                    <p>
                        Cada tratamiento se lleva a cabo respetando las modalidades especificadas en los artículos 6 y 32 del GDPR y mediante la adopción de las medidas de seguridad adecuadas según lo requerido.<br><br>
                        Sus datos serán tratados únicamente por personal expresamente autorizado por el Responsable del Tratamiento, en particular, por las siguientes categorías de empleados:
                    </p>
                    <ul>
                        <li>Programadores y Analistas;</li>
                        <li>Departamento de Marketing.</li>
                    </ul>
                    <p>
                        Comunicación: Sus datos pueden ser comunicados a terceros para una correcta gestión de la relación, en particular a las siguientes categorías de Destinatarios, incluyendo a todos los Encargados del Tratamiento debidamente nombrados:
                    </p>
                    <ul>
                        <li>Google Analytics: Publicidad dirigida, Análisis/Medición, Optimización.</li>
                    </ul>
                    <p>
                        Divulgación: Sus datos personales no serán divulgados de ninguna manera.<br><br>
                        Sus datos personales también pueden ser transferidos, limitados a los fines mencionados anteriormente, a los siguientes países:
                    </p>
                    <ul>
                        <li>Países de la UE;</li>
                        <li>Estados Unidos.</li>
                    </ul>
                    <p>
                        Período de retención. Le informamos que, de acuerdo con los principios de legalidad, limitación de fines y minimización de datos, según lo establecido en el artículo 5 del GDPR, el período de retención de sus datos personales es el siguiente:
                    </p>
                    <ul>
                        <li>
                            Establecido por un período que no exceda el logro de los fines para los cuales se recopilan y tratan para la ejecución y el cumplimiento de los fines contractuales;
                        </li>
                        <li>Establecido por un período que no exceda la prestación de los servicios ofrecidos;</li>
                        <li>
                            Establecido por un período que no exceda el logro de los fines para los cuales se recopilan y tratan, y de acuerdo con los plazos obligatorios establecidos por la ley.
                        </li>
                    </ul>
                    <p>
                        Gestión de cookies: en caso de que tenga dudas o preocupaciones sobre el uso de cookies, siempre puede intervenir para evitar su configuración y lectura, por ejemplo, modificando la configuración de privacidad en su navegador para bloquear ciertos tipos de cookies.<br><br>
                        Dado que cada navegador, y a menudo diferentes versiones del mismo navegador, difieren considerablemente entre sí, si prefiere actuar de manera independiente a través de las preferencias de su navegador, puede encontrar información detallada sobre el procedimiento necesario en la guía de su navegador. Para obtener una descripción general de las acciones para los navegadores más comunes, puede visitar el sitio web www.cookiepedia.co.uk.<br><br>
                        Las empresas publicitarias también permiten optar por no recibir anuncios dirigidos, si lo desea. Esto no impide la configuración de cookies, pero interrumpe el uso y la recopilación de algunos datos por parte de estas empresas.<br><br>
                        Para obtener más información y la posibilidad de optar por no participar, visite el sitio web www.youronlinechoices.eu/.<br><br>
                        Responsable: el Responsable del Tratamiento de los datos, según lo dispuesto por la ley, es RMS S.P.A. (Via Macalle 156, 20831 SEREGNO (MB); correo electrónico: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; teléfono: 036227301; NIF: 00864010962), en la persona de su representante legal en funciones.<br><br>
                        Usted tiene derecho a obtener del responsable la supresión (derecho al olvido), limitación, actualización, rectificación, portabilidad y oposición al tratamiento de los datos personales que le conciernen, así como, en general, ejercer todos los derechos previstos en los artículos 15, 16, 17, 18, 19, 20, 21, 22 del GDPR.
                    </p>
                    <p>
                        También puede consultar en cualquier momento la versión actualizada de esta información accediendo al siguiente enlace: <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>
                    
                    <br><h4 id="ecommerce">Sujetos interesados: Usuarios de comercio electrónico</h4>

                    <p>
                        RMS S.P.A., en calidad de Responsable del tratamiento de sus datos personales, de conformidad con el Reglamento UE 2016/679, en adelante 'RGPD', le informa que dicha normativa prevé la protección de los interesados en relación con el tratamiento de datos personales y que dicho tratamiento se basará en los principios de equidad, legalidad, transparencia y protección de su privacidad y sus derechos.<br><br>
                        Sus datos personales serán tratados de acuerdo con las disposiciones legales del mencionado reglamento y las obligaciones de confidencialidad previstas en el mismo.<br><br>
                        Objeto y base legal del tratamiento: en particular, sus datos serán tratados para las siguientes finalidades relacionadas con el cumplimiento de obligaciones legales o contractuales:
                    </p>

                    <ul>
                        <li>venta en línea.</li>
                    </ul>
                    <p>Modalidades de tratamiento. Sus datos personales podrán ser tratados de la siguiente manera:</p>
                    <ul>
                        <li>tratamiento mediante ordenadores electrónicos.</li>
                    </ul>
                    <p>
                        Cada tratamiento se realiza respetando las modalidades especificadas en los artículos 6 y 32 del RGPD y mediante la adopción de las medidas de seguridad adecuadas de acuerdo con los requisitos.<br><br>
                        Sus datos serán tratados únicamente por el personal expresamente autorizado por el Responsable del tratamiento, en particular, por las siguientes categorías de empleados:
                    </p>
                    <ul>
                        <li>Programadores y analistas;</li>
                        <li>Servicio de marketing.</li>
                    </ul>
                    <p>
                        Comunicación: Sus datos podrán ser comunicados a terceros para una correcta gestión de la relación, en particular a las siguientes categorías de destinatarios, incluyendo a todos los Responsables del Tratamiento debidamente nombrados:
                    </p>
                    <ul>
                        <li>transportistas, mensajeros, servicios postales, empresas de logística.</li>
                    </ul>
                    <p>
                        Difusión: Sus datos personales no serán difundidos en ningún caso.<br><br>
                        Período de conservación. Le informamos que, de acuerdo con los principios de legalidad, limitación de finalidades y minimización de datos, según lo establecido en el artículo 5 del RGPD, el período de conservación de sus datos personales es el siguiente:
                    </p>
                    <ul>
                        <li>
                            Establecido por un período que no exceda la consecución de los fines para los cuales se recopilan y tratan para la ejecución y cumplimiento de los fines contractuales;
                        </li>
                        <li>
                            Establecido por un período que no exceda la consecución de los fines para los cuales se recopilan y tratan, y de acuerdo con los plazos legales obligatorios prescritos por la ley.
                        </li>
                    </ul>
                    <p>
                        Responsable: El Responsable del tratamiento de datos, de conformidad con la ley, es RMS S.P.A. (Via Macalle 156, 20831 SEREGNO (MB); correo electrónico: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; teléfono: 036227301; número de IVA: 00864010962) en la persona de su representante legal temporal.<br><br>
                        Tiene derecho a obtener del responsable la supresión (derecho al olvido), limitación, actualización, rectificación, portabilidad y oposición al tratamiento de sus datos personales. También puede ejercer todos los derechos previstos en los artículos 15, 16, 17, 18, 19, 20, 21, 22 del RGPD.
                    </p>

                    <br><h4 id="mailinglist">Sujetos interesados: Usuarios de la lista de correo</h4>
                    <p>
                        RMS S.P.A., en calidad de Responsable del tratamiento de sus datos personales, de conformidad con el Reglamento UE 2016/679, en adelante 'RGPD', le informa que dicha normativa prevé la protección de los interesados en relación con el tratamiento de datos personales y que dicho tratamiento se basará en los principios de equidad, legalidad, transparencia y protección de su privacidad y sus derechos.<br><br>
                        Sus datos personales serán tratados de acuerdo con las disposiciones legales del mencionado reglamento y las obligaciones de confidencialidad previstas en el mismo.<br><br>
                        Objeto y base legal del tratamiento: en particular, sus datos serán tratados para las siguientes finalidades relacionadas con el cumplimiento de obligaciones legales o contractuales:
                    </p>
                    <ul>
                        <li>envío de información comercial por correo electrónico o SMS.</li>
                    </ul>
                    <p>La provisión de datos es opcional para usted con respecto a las finalidades mencionadas anteriormente, y su eventual rechazo al tratamiento no compromete la continuación de la relación o la adecuación del tratamiento en sí mismo.<br><br>
                    Modalidades de tratamiento. Sus datos personales podrán ser tratados de la siguiente manera:</p>
                    <ul>
                        <li>tratamiento mediante ordenadores electrónicos.</li>
                    </ul>
                    <p>
                        Cada tratamiento se realiza respetando las modalidades especificadas en los artículos 6 y 32 del RGPD y mediante la adopción de las medidas de seguridad adecuadas de acuerdo con los requisitos.<br><br>
                        Sus datos serán tratados únicamente por el personal expresamente autorizado por el Responsable del tratamiento, en particular, por las siguientes categorías de empleados:
                    </p>
                    <ul>
                        <li>Programadores y analistas;</li>
                        <li>Servicio de marketing.</li>
                    </ul>
                    <p>
                        Comunicación: Sus datos podrán ser comunicados a terceros para una correcta gestión de la relación, en particular a las siguientes categorías de destinatarios, incluyendo a todos los Responsables del Tratamiento debidamente nombrados:
                    </p>
                    <ul>
                        <li>Google Analytics: Publicidad dirigida, Analítica/Medición, Optimización</li>
                    </ul>
                    <p>
                        Difusión: Sus datos personales no serán difundidos en ningún caso.<br><br>
                        Periodo de Conservación. Le informamos que, de acuerdo con los principios de legalidad, limitación de finalidades y minimización de datos, según lo establecido en el artículo 5 del RGPD, el período de conservación de sus datos personales es el siguiente:
                    </p>
                    <ul>
                        <li>
                            Establecido por un período que no exceda la consecución de los fines para los cuales se recopilan y tratan para la ejecución y cumplimiento de los fines contractuales;
                        </li>
                        <li>
                            Establecido por un período que no exceda la consecución de los fines para los cuales se recopilan y tratan, y de acuerdo con los plazos legales obligatorios prescritos por la ley.
                        </li>
                    </ul>
                    <p>
                        Responsable: El Responsable del tratamiento de datos, de conformidad con la ley, es RMS S.P.A. (Via Macalle 156, 20831 SEREGNO (MB); correo electrónico: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; teléfono: 036227301; número de IVA: 00864010962) en la persona de su representante legal temporal.<br><br>
                        Tiene derecho a obtener del responsable la supresión (derecho al olvido), limitación, actualización, rectificación, portabilidad y oposición al tratamiento de sus datos personales. También puede ejercer todos los derechos previstos en los artículos 15, 16, 17, 18, 19, 20, 21, 22 del RGPD.
                    </p>
                    <p>
                        También puede consultar en cualquier momento la versión actualizada de esta información visitando el sitio web <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>

                    <br><h4 id="lavoro">Personas Interesadas: Candidatos a considerar para el establecimiento de una relación laboral</h4>

                    <p>
                        RMS S.P.A., en calidad de Responsable del tratamiento de sus datos personales, de acuerdo con el Reglamento UE 2016/679, en adelante denominado 'RGPD', le informa que dicha normativa prevé la protección de las personas interesadas en relación con el tratamiento de datos personales y que dicho tratamiento se basará en los principios de legalidad, lealtad, transparencia y protección de su privacidad y derechos.<br><br>
                        Sus datos personales serán tratados de acuerdo con las disposiciones legales del reglamento mencionado y las obligaciones de confidencialidad previstas en el mismo.<br><br>
                        Propósito y base legal del tratamiento: en particular, sus datos serán tratados para los siguientes fines relacionados con el cumplimiento de obligaciones legales o contractuales:
                    </p>
                    <ul>
                        <li>Selección de personal para el establecimiento de una relación laboral</li>
                    </ul>

                    <p>La proporcion de datos es opcional para usted con respecto a los fines mencionados anteriormente, y su eventual negativa al tratamiento no afecta la continuación de la relación o la legitimidad del tratamiento en sí.<br><br>
                    Modalidades de tratamiento. Sus datos personales podrán ser tratados de la siguiente manera:</p>
                    <ul>
                        <li>
                            Tratamiento mediante ordenadores electrónicos;
                        </li>
                        <li>
                            Tratamiento manual mediante archivos en papel.
                        </li>
                    </ul>
                    <p>
                        Cada tratamiento se realiza respetando las modalidades especificadas en los artículos 6 y 32 del RGPD y adoptando las medidas de seguridad adecuadas de acuerdo con los requisitos.<br><br>
                        Sus datos serán tratados únicamente por personal expresamente autorizado por el Responsable del tratamiento, en particular por las siguientes categorías de empleados:
                    </p>
                    <ul>
                        <li>En el ámbito de la gestión de personal.</li>
                    </ul>
                    <p>
                        Comunicación: Sus datos podrán ser comunicados a terceros para una gestión adecuada de la relación, en particular a las siguientes categorías de destinatarios, incluyendo a todos los Responsables del Tratamiento debidamente nombrados:
                    </p>
                    <ul>
                        <li>
                            Consultores y profesionales independientes, incluso en forma asociada.
                        </li>
                    </ul>
                    <p>
                        Divulgación: Sus datos personales no serán divulgados de ninguna manera.<br><br>
                        Sus datos personales también podrán ser transferidos, en la medida de las finalidades mencionadas anteriormente, a los siguientes estados:
                    </p>
                    <ul>
                        <li>Estados miembros de la UE;</li>
                        <li>Estados Unidos.</li>
                    </ul>
                    <p>
                        Período de conservación. Le informamos que, de acuerdo con los principios de legalidad, limitación de finalidades y minimización de datos, según lo previsto en el artículo 5 del RGPD, el período de conservación de sus datos personales es el siguiente:
                    </p>
                    <ul>
                        <li>Establecido por un período que no excede la finalización de los servicios prestados;</li>
                        <li>
                            Establecido por un período que no excede el logro de los fines para los cuales se recopilan y tratan, y de acuerdo con los plazos legales obligatorios prescritos por la ley.
                        </li>
                    </ul>
                    <p>
                        Responsable: El Responsable del tratamiento de datos, de acuerdo con la ley, es RMS S.P.A. (Via Macalle 156, 20831 SEREGNO (MB); correo electrónico: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; teléfono: 036227301; número de IVA: 00864010962) en la persona de su representante legal temporal.<br><br>
                        Tiene derecho a obtener del responsable la supresión (derecho al olvido), limitación, actualización, rectificación, portabilidad y oposición al tratamiento de sus datos personales. También puede ejercer todos los derechos previstos en los artículos 15, 16, 17, 18, 19, 20, 21, 22 del RGPD.
                    </p>

                    <br><h4>Reglamento UE 2016/679: Artículos 15, 16, 17, 18, 19, 20, 21, 22 - Derechos del Interesado</h4>

                    <p>
                        1. El interesado tiene derecho a obtener la confirmación de la existencia o no de datos personales que le conciernen, incluso si aún no están registrados, su comunicación de forma inteligible y la posibilidad de presentar una queja ante la Autoridad de Control.
                    </p>
                    <p>2. El interesado tiene derecho a obtener la siguiente información:</p>
                    <ul>
                        <li>a. El origen de los datos personales;</li>
                        <li>b. Los fines y modalidades del tratamiento;</li>
                        <li>c. La lógica aplicada en caso de tratamiento realizado con la ayuda de instrumentos electrónicos;</li>
                        <li>d. Los datos de identificación del responsable, de los responsables y del representante designado según el artículo 5, párrafo 2;</li>
                        <li>
                            e. Las personas o categorías de personas a las que se pueden comunicar los datos personales o que pueden conocerlos en calidad de representante designado en el territorio del Estado, de responsables o encargados del tratamiento.
                        </li>
                    </ul>
                    <p>3. El interesado tiene derecho a obtener:</p>
                    <ul>
                        <li>a. La actualización, rectificación o, cuando tenga interés, la integración de los datos;</li>
                        <li>
                            b. La supresión, la transformación en forma anónima o el bloqueo de los datos tratados en violación de la ley, incluidos aquellos cuya conservación no sea necesaria en relación con los fines para los que se recopilaron o trataron posteriormente;
                        </li>
                        <li>
                            c. La certificación de que las operaciones mencionadas en las letras a) y b) se han notificado, incluso en cuanto a su contenido, a las personas a las que se comunicaron o divulgaron, excepto cuando este cumplimiento resulte imposible o suponga un uso de medios manifiestamente desproporcionado respecto al derecho protegido;
                        </li>
                        <li>d. La portabilidad de los datos.
                    </li></ul>
                    <p>4. El interesado tiene derecho a oponerse, en todo o en parte:</p>
                    <ul>
                        <li>a. Por motivos legítimos al tratamiento de los datos personales que le conciernan, aunque sean pertinentes para los fines de la recogida;</li>
                        <li>
                            b. Al tratamiento de datos personales que le conciernan con fines de envío de material publicitario o de venta directa o para la realización de estudios de mercado o de comunicación comercial.
                        </li>
                    </ul>

                    <br><p>
                        Última modificación: 20 de noviembre de 2018
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>