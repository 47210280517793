import { ActivatedRoute } from "@angular/router";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { SeoService } from "src/services/seo.service";
import { StorageService } from "src/services/storage.service";
import { TranslatorService } from "src/services/translator.service";

export class BaseRoutedComponent {  
    public currentCulture!: string;
    protected browserCulture!: string;
    protected routeCulture!: string;
    
    constructor(protected route: ActivatedRoute,
                protected seoService: SeoService,
                protected storageService: StorageService,
                protected translatorService: TranslatorService,
                protected cookieService: NgcCookieConsentService)
    {
        this.setRouteCulture();
        this.seoService.setSeoData();
    }

    setRouteCulture() {        
        this.route.params.subscribe(params => {
            this.routeCulture = params['culture'];
            this.currentCulture = this.translatorService.getLanguage(this.routeCulture);
            this.translatorService.setComponentLanguage(this.currentCulture);

            this.translatorService.setGlobals(this.currentCulture);
        });

        let cookieConsent = this.storageService.contains("cookieConsent");

        if(!cookieConsent){
            this.cookieService.getConfig().content = this.cookieService.getConfig().content || {} ;
            // Override default messages with the translated ones
            this.cookieService.getConfig().content!.header = this.translatorService.getLocalization('cookie.header'); 
            this.cookieService.getConfig().content!.message = this.translatorService.getLocalization('cookie.message');
            this.cookieService.getConfig().content!.dismiss = this.translatorService.getLocalization('cookie.dismiss');
            this.cookieService.getConfig().content!.allow = this.translatorService.getLocalization('cookie.allow');
            this.cookieService.getConfig().content!.deny = this.translatorService.getLocalization('cookie.deny');
            this.cookieService.getConfig().content!.link = this.translatorService.getLocalization('cookie.link');
            this.cookieService.getConfig().content!.policy = this.translatorService.getLocalization('cookie.policy');
            this.cookieService.getConfig().content!.href = this.translatorService.getLocalization('cookie.href');

            this.cookieService.destroy(); // remove previous cookie bar (with default messages)
            this.cookieService.init(this.cookieService.getConfig()); // update config with translated messages
        }else{
            this.cookieService.fadeOut();
        }
    }

}