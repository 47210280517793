import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { Product } from 'src/classes/product';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-product-sponsored-medium',
  templateUrl: './product-sponsored-medium.component.html',
  styleUrls: ['./product-sponsored-medium.component.scss']
})
export class ProductSponsoredMediumComponent {
  @Input() product!: Product;


  constructor(public global:Globals,
    protected router: Router,
    protected utilyService: UtilyService,
    protected deviceService: DeviceDetectorService) { }
  
  get isMobile(){
    return this.deviceService.isMobile();
  }

  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }

  slugBrand(testo:string){
    return this.utilyService.encodeBrandDescription(testo);
  }
}