import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'src/classes/category';
import { SearchResult } from 'src/classes/search-result';
import { environment } from 'src/environments/environment';
import { KeyValue, KeyValueSlide } from 'src/classes/key-value';
import { Product, ProductVariant } from 'src/classes/product';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(protected http: HttpClient) { }
 
  getProductManuals(idCategoria:string | null) {
    var url = environment.api + "api/search/product/manual";
    if(idCategoria){
      url = url + "?idCategoria=" + idCategoria;
    }
    return this.http.get<Product[]>(url);
  }

  getCategorieBrand(idBrand:string): any {
    return this.http.get<Category>(environment.api + "api/search/categoriebrand/" + idBrand);
  }

  getCategorieComparto(idComparto:string): any {
    return this.http.get<Category>(environment.api + "api/search/categoriecomparto/" + idComparto);
  }

  getSingleProduct(codiceProdotto: string):any {
    return this.http.get<Product>(environment.api + "api/search/singleproduct/" + codiceProdotto);
  }

  getVarianti(idSchedaProdotto:string): any{
    return this.http.get<ProductVariant>(environment.api + "api/search/varianti/" + idSchedaProdotto);
  }

  getSearchFullText(keyword:string, quickSelezionate:Array<KeyValue>=[], minPrice: number|null =null, maxPrice: number|null, ordinamento:number, rowPage:number, page:number){
    let obj={
      "keyword": keyword,
      "minPrice": minPrice,
      "maxPrice": maxPrice,
      "quickSelezionate" : quickSelezionate,
      "rowPage" :rowPage,
      "page" :page,
      "ordinamento": ordinamento
    };
    return this.http.post<SearchResult>(environment.api +"api/search/fulltext", obj);
  }

  getSearchProduct(idCategoria:string="", idBrand: string="", idComparto:string="", rowPage:number, page:number, filtriQuick:boolean, quickSelezionate:Array<KeyValue>=[], quickSlideSelezionate:Array<KeyValueSlide>=[], minPrice: number|null =null, maxPrice: number|null, ordinamento:number): any {
    let obj={
      "idCategoria" :idCategoria,
      "idBrand" :idBrand,
      "idComparto" :idComparto,
      "rowPage" :rowPage,
      "page" :page,
      "filtriQuick" :filtriQuick,
      "quickSelezionate" : quickSelezionate,
      "quickSlideSelezionate": quickSlideSelezionate,
      "minPrice": minPrice,
      "maxPrice": maxPrice,
      "ordinamento": ordinamento     
    };

    return this.http.post<SearchResult>(environment.api +"api/search/products", obj);
  }

  getSearchQuicks(idCategoria:string="", idBrand: string="", idComparto:string="", rowPage:number, page:number, filtriQuick:boolean, quickSelezionate:Array<KeyValue>=[], quickSlideSelezionate:Array<KeyValueSlide>=[], minPrice: number|null =null, maxPrice: number|null, ordinamento:number): any {
    let obj={
      "idCategoria" :idCategoria,
      "idBrand" :idBrand,
      "idComparto" :idComparto,
      "rowPage" :rowPage,
      "page" :page,
      "filtriQuick" :filtriQuick,
      "quickSelezionate" : quickSelezionate,
      "quickSlideSelezionate": quickSlideSelezionate,
      "minPrice": minPrice,
      "maxPrice": maxPrice,
      "ordinamento": ordinamento
    };

    return this.http.post<any>(environment.api +"api/search/quicks", obj);
  }

  getSearchNewsProduct(idNews:number, quickSelezionate:Array<KeyValue>=[], ordinamento:number, rowPage:number, page:number): any {
    let obj={
      "quickSelezionate" : quickSelezionate,
      "ordinamento": ordinamento,
      "page": page,
      "rowPage": rowPage
    };

    return this.http.post<SearchResult>(environment.api +"api/search/news/products/" + idNews, obj);
  }
}
