import { Component, OnInit } from '@angular/core';
import { BaseRoutedComponent } from '../../base-route.component';
import { Globals } from 'src/classes/global';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/services/seo.service';
import { TranslatorService } from 'src/services/translator.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent extends BaseRoutedComponent {
  constructor(public global: Globals,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);
    }
}