import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ChiSiamoComponent } from './components/pages/chi-siamo/chi-siamo.component';
import { SearchFulltextComponent } from './components/pages/search-fulltext/search-fulltext.component';
import { DatasheetComponent } from './components/pages/datasheet/datasheet.component';
import { SearchProductsComponent } from './components/pages/search-products/search-products.component';
import { NotFoundComponent } from './components/pages/error/not-found/not-found.component';
import { ErrorComponent } from './components/pages/error/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { SearchApplicationComponent } from './components/pages/search-application/search-application.component';
import { StoreLocatorComponent } from './components/pages/store-locator/store-locator.component';
import { DownloadCatalogComponent } from './components/pages/download-catalog/download-catalog.component';
import { DownloadInstructionsComponent } from './components/pages/download-instructions/download-instructions.component';
import { NewsComponent } from './components/pages/news/news.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { seoNode: "pageDescriptors", pageInfo: "home" } },
  { path: ':culture', component: HomeComponent, data: { seoNode: "pageDescriptors", pageInfo: "home" } },
  { path: ':culture/home', component: HomeComponent, data: { seoNode: "pageDescriptors", pageInfo: "home" } },
  { path: ':culture/search/:keyword', component: SearchFulltextComponent, data: { seoNode: "pageDescriptors", pageInfo: "searchFullText" } },
  { path: ':culture/search-products', component: SearchProductsComponent, data: { seoNode: "pageDescriptors", pageInfo: "searchProducts" } },
  { path: ':culture/news/products/:id', component: NewsComponent, data: { seoNode: "pageDescriptors", pageInfo: "news" } },
  { path: ':culture/search-products/:id/:descrizione', component: SearchProductsComponent, data: { seoNode: "pageDescriptors", pageInfo: "searchProducts" } },
  { path: ':culture/search-application', component: SearchApplicationComponent, data: { seoNode: "pageDescriptors", pageInfo: "productApplication" } },
  { path: ':culture/search-application/:marca/:cilindrata/:modelloGenerico', component: SearchApplicationComponent, data: { seoNode: "pageDescriptors", pageInfo: "productApplication" } },
  { path: ':culture/search-application/:marca/:cilindrata/:modelloGenerico/:modello/:idCategoria/:telaio/:anno', component: SearchApplicationComponent, data: { seoNode: "pageDescriptors", pageInfo: "productApplication" } },  
  { path: ':culture/datasheet/:codiceprodotto/:titolo', component: DatasheetComponent, data: { seoNode: "pageDescriptors", pageInfo: "datasheet" } },
  { path: ':culture/download/catalog', component: DownloadCatalogComponent, data: { seoNode: "pageDescriptors", pageInfo: "downloadCatalogo" } },
  { path: ':culture/download/instructions', component: DownloadInstructionsComponent, data: { seoNode: "pageDescriptors", pageInfo: "downloadIstruzioni" } },
  { path: ':culture/about-us', component: ChiSiamoComponent, data: { seoNode: "pageDescriptors", pageInfo: "aboutUs" } },
  { path: ':culture/store-locator', component: StoreLocatorComponent, data: { seoNode: "pageDescriptors", pageInfo: "storeLocator" } },
  { path: ':culture/privacy-policy', component: PrivacyPolicyComponent,  data: { seoNode: "pageDescriptors", pageInfo: "privacyPolicy" } },
  { path: ':culture/error', component: ErrorComponent },
  { path: ':culture/not-found', component: NotFoundComponent },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation : 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]  
})
export class AppRoutingModule { }
