<div class="container-fluid main-content g-0">
    <div *ngIf="!isMobile" class="block-finder row no-gutters d-flex">
        <div  class="decor block-finder__decor decor--type--bottom">
            <div class="decor__body">
                <div class="decor__start"></div>
                <div class="decor__end"></div>
                <div class="decor__center"></div>
            </div>
        </div>
        <div id="boxCarousel" class="col-xl-12 col-12 p-0 order-0 order-lg-1">
            <app-home-carousel></app-home-carousel>
        </div>
    </div>
    
    <div class="block-space block-space--layout--divider-xl"></div>    

    <section id="servizi" class="container text-center" [ngClass]="{'section-mobile': isMobile}">
        <h3>NUESTROS PRODUCTOS</h3>
         <div class="row">
            <div class="item col-12 col-md-6 p-2 text-center">
                <a routerLink="/es/search-products/Q175-3020/Evok_performance">
                    <img src="https://static.rms.it/evok/servizi1.jpg" class="img-fluid" alt="Evok performance" />
                    <h5>EVOK PERFORMANCE</h5>
                    <p class="text-big">
                        Los repuestos Evok PERFORMANCE garantizan una mejora técnica y estética respecto a los repuestos originales.
                    </p>
                </a>
            </div>
            <div class="item col-12 col-md-6 p-2 text-center">
                <a routerLink="/es/search-products/Q175-5146/Evok_Oem_Series">
                    <img src="https://static.rms.it/evok/servizi2.jpg" class="img-fluid"  alt="Evok Oem" />
                    <h5>EVOK OEM SERIES</h5>
                    <p class="text-big">
                        Los productos Evok OEM SERIES se benefician de una calidad igual o superior a los repuestos originales, manteniendo las características técnicas.
                    </p>
                </a>
            </div>
        </div>
    </section>

    <section id="prod-ricercati" class="container-fluid text-center bg-light pb-5">
        <h4>NOVIDAD DE PRODUCTOS</h4>
        <div class="block-space block-space--layout--divider-nl"></div>
        <div class=" container">
          <app-product-slider></app-product-slider>
        </div>
    </section>

    <div class="block-space block-space--layout--divider-xl"></div>
    <div class="block block-posts-carousel block-posts-carousel--layout--grid" data-layout="grid">
        <app-home-news></app-home-news>
    </div>
    
    <div class="block-space block-space--layout--divider-xl"></div>    
    <section id="cerca-rivenditore" class="container-fluid d-flex align-items-center justify-content-center flex-column" type="button" routerLink="/it/store-locator">
        <div class="container text-center bg-glass-dark p-5 mb-3">
            <h3>LOCALIZADOR DE DISTRIBUIDORES</h3>
            <p class="text-big">
                ¡Encuentra tu distribuidor oficial Evok más cercano donde podrás adquirir los repuestos adaptados a tu vehículo!
            </p>
        </div>
    </section>  
</div>