import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Applicazione } from 'src/classes/applicazione';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {    
  constructor(protected http: HttpClient) { 
  }

  getMarcheApplicazioni(): any {
    return this.http.get<string>(environment.api + "api/marcheapplicazioni");
  }

  getCilindrataApplicazioni(filtri: any): any {
    return this.http.post<number>(environment.api + "api/cilindrataapplicazioni", filtri);
  }

  getModelloApplicazioni(filtri: any): any {
    return this.http.post<string>(environment.api + "api/modelloapplicazioni", filtri);
  }

  getModelloCompletoApplicazioni(filtri: any): any {
    return this.http.post<string>(environment.api + "api/modellocompletoapplicazioni", filtri );
  }

  getCategorieApplicazioni(filtri: any): any {
    return this.http.post<any>(environment.api + "api/categorieapplicazioni", filtri);
  }

  getAnnoApplicazioni(filtri: any): any {
    return this.http.post<number>(environment.api + "api/annoapplicazioni", filtri);
  }

  
  getTelaioapplicazioni(filtri: any): any {
    return this.http.post<string>(environment.api + "api/telaioapplicazioni", filtri);
  }


  getCategorieApplicazioniGrouped(filtri: any): any {
    return this.http.post<any>(environment.api + "api/categorieapplicazionigrouped", filtri);
  }


  getProdottiApplicazione(filtri: any){
    filtri.page=0;
    filtri.rowPage=50;

    return this.http.post<any>(environment.api + "api/prodottifromapplicazioni", filtri); 
  }


  getProdottiApplicazioneGroup(filtri:any): any {
    return this.http.post(environment.api + "api/modellocompletoapplicazionigrouped", filtri);
  }

  getApplicazioniFromCodice(codice:string): any {
    return this.http.get<Applicazione>(environment.api + "api/applicazionifromcodice?codice=" + codice);
  } 
}
