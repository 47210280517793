import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from 'src/classes/product';
import { VoceMenu } from 'src/classes/voce-menu';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(protected http: HttpClient) { }

  getProductTop(): any {
    return this.http.get<Product[]>(environment.api + "api/navigation/top");
  }

  sendEmail(form: any) {
    return this.http.post(environment.api + "api/navigation/contact", form);
  }

  getMenu(): any{
    return this.http.get<VoceMenu>(environment.api + "api/navigation/menu");
  }

  getStoreLocator(lat:number, lng:number, distance:number): any {
    var url = environment.api + "api/navigation/storelocator/" + lat + "/" + lng + "/" + distance;

    return this.http.get(url);
  }
  
  getNews(): any {
    return this.http.get(environment.api + "api/navigation/news");
  }

  getNewsDetails(idNews:number): any {
    return this.http.get(environment.api + "api/navigation/news/detail/" + idNews);
  }

  getCatalogs(): any {
    return this.http.get(environment.api + "api/navigation/catalogs");
  }
}
