<div class="container-fluid main-content g-0">
    <section *ngIf="!isMobile" id="headImage" class="bkgTestata" style="background-image: url('https://static.rms.it/evok/bkg-chi-siamo.jpg');">  
        <div class="bottombar">
            <div class="bottombar-left"></div>
            <div class="bottombar-middle"></div>
            <div class="bottombar-right"></div>
        </div>
    </section>
    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">WHO ARE WE</h3>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok was born in 2019 with its own ambitious goal: to guarantee an improvement in your vehicle, where performance begins.
            As of this moment, we have been driven by the determination to offer products to meet and exceed the needs of motorcyclists. 
            Each Evok spare part is the outcome of meticulous research and unconditional love for two-wheelers, designed to guarantee an improved performance. 
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">Evok's mission</em>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            The brand mission is to be the link between the original and performance. 
            The brand spare parts guarantee a technical and aesthetic improvement compared to the original, as well as providing a superior driving experience. 
            Evok is a pioneer of soft tuning, with a perfect balance between passion, dedication and constant development.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">The DNA of the brand</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok products are plug and play, we believe in simplicity and ease of use, without neglecting product quality.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">Our attention to the product</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">            
            All Evok components are subject to quality controls, from production to packaging, to ensure improved performance of your vehicle. 
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">                        
            Evok spare parts are designed to provide maximum compatibility and performance for a wide range of motorcycles. 
            Whether you own a Vespa, a scooter, a maxi scooter or a motorcycle from 50 to 125 cc, our spare parts are the ideal choice to ensure an improved performance. 
        </p>
        <br>
    </section>

    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">WHAT DISTINGUISHES US</h3>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/supporto.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>SUPPORT</h5>
                        <span class="text-small">
                            Evok support is defined by its excellence and dedication to the customer. 
                            The product specialist team is always ready to provide customized support, answer technical questions, offer product advice and help with the installation of spare parts.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/qualità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>QUALITY </h5>
                        <span class="text-small">
                            The quality of Evok products is a combination of first choice materials and rigorous production controls.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/performance.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PERFORMANCE</h5>
                        <span class="text-small">
                            With Evok, every trip turns into a unique driving experience, where innovation and passion combine to exceed all your expectations.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div  class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/affidabilità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>RELIABILITY</h5>
                        <span class="text-small">
                            For those seeking safety and comfort, Evok represents the ideal choice, offering widely reliable components.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/plugandplay.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PLUG & PLAY</h5>
                        <span class="text-small">
                            All Evok products are plug and play with an easy replacement and safe installation.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- VERSIONE MOBILE -->   
    <section *ngIf="isMobile" id="intro" class="container overflow-hidden">
        <h5 class="mb-5 text-center">WHAT DISTINGUISHES US</h5>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>SUPPORT</h6>
                <span class="text-small">
                    Evok support is defined by its excellence and dedication to the customer. 
                    The product specialist team is always ready to provide customized support, answer technical questions, offer product advice and help with the installation of spare parts.
                </span>                                            
            </div>           
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>QUALITY</h6>
                <span class="text-small">
                    The quality of Evok products is a combination of first choice materials and rigorous production controls.
                </span>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>PERFORMANCE</h6>
                <span class="text-small">
                    With Evok, every trip turns into a unique driving experience, where innovation and passion combine to exceed all your expectations.
                </span>                                            
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>RELIABILITY</h6>
                <span class="text-small">
                    For those seeking safety and comfort, Evok represents the ideal choice, offering widely reliable components.
                </span>
            </div>                
        </div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">                
                <h6>PLUG & PLAY</h6>
                <span class="text-small">
                    All Evok products are plug and play with an easy replacement and safe installation.
                </span>
            </div>
        </div>
    </section>
</div>
<div class="block-space block-space--layout--divider-xl"></div>
<div class="block-space block-space--layout--divider-xl"></div>