import { Component, OnDestroy } from '@angular/core';
import { FiltroQuick, FiltroQuickFlat, QuickValue, SearchResult } from 'src/classes/search-result';
import { BaseRoutedComponent } from '../../base-route.component';
import { faCheck, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Globals } from 'src/classes/global';
import { UtilyService } from 'src/services/utility.service';
import { SearchService } from 'src/services/search.service';
import { SeoService } from 'src/services/seo.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { TranslatorService } from 'src/services/translator.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { News } from 'src/classes/news';
import { KeyValue } from 'src/classes/key-value';
import { NavigationService } from 'src/services/navigation.service';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent extends BaseRoutedComponent implements OnDestroy {
  idNews!:number;
  news!:News;
  searchResult: SearchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
  ordinamento: number = 0;
  firstQuickSelected!: string|null;
  throttle = 200;
  scrollDistance = 5;
  scrollUpDistance = 2;
  page: number = 0;
  rowPage: number = 20;  
  disableScrolling:boolean = false;
  
  filtriSelezionati: Array<FiltroQuickFlat> = [];
  filtriQuick: Array<FiltroQuick> = [];

  faCheck = faCheck;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  navigationSubscription: Subscription;
  constructor(
    public global: Globals,
    protected router: Router,    
    protected utilityService: UtilyService,
    protected searchService: SearchService,
    protected deviceService: DeviceDetectorService,
    protected navigationService: NavigationService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      this.route.params.subscribe(params => {
        if(params['id']) {
          this.idNews = params['id'];
        }
      });

      if (e instanceof NavigationEnd) {
        this.searchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
        this.initialiseInvites();
      }
    });
  }

  get isMobile(){
    return this.deviceService.isMobile();
  }

  get isDesktop(){
    return this.deviceService.isMobile();
  }

  initialiseInvites() {    
    this.navigationService.getNewsDetails(this.idNews).subscribe((res:News) => {
      this.news = res;
      this.filtriSelezionati = [];
      this.ordinamento = 0;
      this.cerca(true);
    });    
  }

  dynamicOpenFilterTab(){
    this.filtriQuick.forEach((item) => {
      item.isOpen = true;
    });
  }

  switch(layout:string) {
      const layoutSwitcher = $(layout).closest('.layout-switcher');
      const productsView = $(layout).closest('.products-view');
      const productsList = productsView.find('.products-list');

      layoutSwitcher
          .find('.layout-switcher__button')
          .removeClass('layout-switcher__button--active')
          .removeAttr('disabled');

      $(layout).addClass('layout-switcher__button--active')
          .attr('disabled', '');

      productsList.attr('data-layout', $(layout).attr('data-layout'));
      productsList.attr('data-with-features', $(layout).attr('data-with-features'));
  }

  cerca(aggiornaFiltri: boolean) {
    this.page= 0;
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    this.searchService.getSearchNewsProduct(this.idNews, quickSel, this.ordinamento, this.rowPage, this.page)
      .subscribe((res: any) => {
       
        if(aggiornaFiltri) {
          this.filtriQuick = res.quicks;
        }
        else 
        {
          this.setInactive();

          res.quicks.forEach((f1: FiltroQuick) => {
            f1.valori.forEach((v1: QuickValue) => {

              this.filtriQuick.find((x) => {
                if (x.id == f1.id) {
                  x.valori.find(k => {
                    if (k.idValue == v1.idValue) {
                      k.inactive = false;
                    }
                  });
                }
              });

             });
          });
        }

        this.dynamicOpenFilterTab();

        this.searchResult = res;
        setTimeout(() => {
          this.filtersMobile();
        }, 1000);  
      });
  }

  filtersMobile() {
    const body = $('body');
    const sidebar = $('.sidebar');
    const offcanvas = sidebar.hasClass('sidebar--offcanvas--mobile') ? 'mobile' : 'always';
    const media = matchMedia('(max-width: 1280px)');

    if (sidebar.length) {
        const open = function () {
            if (offcanvas === 'mobile' && !media.matches) {
                return;
            }

            const bodyWidth = body.width();
            body.css('overflow', 'hidden');
            body.css('paddingRight', (body.width() - bodyWidth) + 'px');

            sidebar.addClass('sidebar--open');
        };
        const close = function () {
            body.css('overflow', 'auto');
            body.css('paddingRight', '');

            sidebar.removeClass('sidebar--open');
        };
        const onMediaChange = function () {
            if (offcanvas === 'mobile') {
                if (!media.matches && sidebar.hasClass('sidebar--open')) {
                    close();
                }
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onMediaChange);
        } else {
            media.addListener(onMediaChange);
        }

        $('.filters-button').on('click', function () {
            open();
        });
        $('.sidebar__backdrop, .sidebar__close').on('click', function () {
            close();
        });
        $('.btn-close-sidebar').on('click', function () {
            close();
        });
    }
  }

  addToFiltri(id: string, idQuick: string, voceFiltro: QuickValue) {

    const elem = { id: id, quick: idQuick, idValue: voceFiltro.idValue, value: voceFiltro.value } as FiltroQuickFlat;

    if (voceFiltro.checked) {
      this.filtriSelezionati.push(elem);
    }
    else {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == elem.id && obj.idValue == elem.idValue));
      this.firstQuickSelected = this.filtriSelezionati.length == 0 ? null : this.firstQuickSelected;
    }

    this.firstQuickSelected = this.firstQuickSelected == null ? id :this.firstQuickSelected;
    this.cerca(false);
  }

  cleanFiltriAttivi(id: string = '', idValue: string = ''): void {
    if (id != '' && idValue != '') {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == id && obj.idValue == idValue));

      this.filtriQuick.find((x) => {
        if (x.id == id) {
          x.valori.find(k => {
            if (k.idValue == idValue) {
              k.checked = false;
            }
          });
        }
      });
      this.cerca(false,);
    }
    else {      
      this.filtriSelezionati = [];
      this.cerca(true);
    }

    if((id == '' && idValue == '') ||  this.filtriSelezionati.length == 0) {
      this.firstQuickSelected = null;
    }
  }

  setInactive(idEccezione:string| null = null) {
    this.filtriQuick.forEach(element => {
      if(element.id != null && element.id != idEccezione){
        element.valori.forEach(v => {
          v.inactive = true;
        });
      }
    });
  }

  onScroll() {
    if(!this.disableScrolling){
      this.appendItem();
    }
  }

  appendItem() {
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    this.page += 1;
    this.disableScrolling= true;
    this.searchService.getSearchNewsProduct(this.idNews, quickSel, this.ordinamento, this.rowPage, this.page)
      .subscribe((res: any) => {
        this.disableScrolling = res.results.length != 0 ? false : true; //se torna risultati sblocco l'infinity scroll 
     
        res.results.forEach((item:any) => {
          this.searchResult.results.push(item);
        });
      });
  }

  cleanUp(testo: string): string {
    return this.utilityService.encodeURLParameter(testo);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}