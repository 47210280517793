<div class="slick-wrapper">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style="display:block; margin:0px;">
            <div ngxSlickItem *ngFor="let product of products" class="slide">
              <div class="item text-start m-3" style="cursor: pointer;">
                  <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">
                      <span class="zoom">
                          <img src="{{product.foto}}" alt="{{product.modello}}" onerror="this.src='../../../../assets/images/no-image-found.jpg'" class="img-fluid">
                      </span>
                  </a>
              </div>
          </div>
      </ngx-slick-carousel>
  </div>