
<div *ngIf="product" class="block-products-carousel__column">
    <div class="block-products-carousel__cell">
        <div class="product-card product-card--layout--grid">
            <div class="product-card__actions-list">
            </div>
            <div class="product-card__image">
                <div class="image image--type--product">
                    <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" class="image__body">
                        <img class="image__tag" [src]="product.foto" onerror="this.src='../../../../assets/images/no-image-found.jpg'" [alt]="product.modello">
                    </a>
                </div>
            </div>
            <div class="product-card__info">
                <div *ngIf="!isMobile" class="product-card__brandLogo">
                    <img src="https://static.rms.it/b2b/loghi/brand/{{slugBrand(product.descrizioneBrand)}}_small.png" [alt]="product.descrizioneBrand" class="img-fluid" style="padding-top: 0.5rem;" />
                </div>
                           
                <div class="product-card__name">
                    <div>
                        <div class="product-card__badges">
                            <div class="tag-badge tag-badge--theme" translate>{{'badgePiuAcquistati' | translate}}</div>
                        </div>
                        <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">{{product.modello | uppercase}}</a>
                    </div>
                </div>
                <div class="product-card__meta">
                    <span class="product-card__meta-title">
                        <strong>{{'codice' | translate | uppercase}}: </strong> <span>{{product.codiceProdotto}}</span><br>
                    </span> 
                    <span *ngIf="product.riferimentoOriginale.length == 1" class="product-card__meta-title">
                        <strong>{{'riferimentoOriginale' | translate | uppercase}}: </strong>
                        <span>{{product.riferimentoOriginale[0]}}</span>
                    </span>
                    <span *ngIf="product.riferimentoOriginale.length > 1" class="product-card__meta-title">
                        <strong>{{'riferimentiOriginali' | translate | uppercase}}: </strong><br>
                        <span>{{product.riferimentoOriginale[0]}}</span> - <span>{{product.riferimentoOriginale[1]}}</span>
                    </span>
                    <span *ngIf="product.upc.length > 1" class="product-card__meta-title">
                        <strong>{{'codFornitore' | translate | uppercase}}: </strong> <span>{{product.upc}}</span><br>
                    </span>
                    <span *ngIf="product.ean.length > 0" class="product-card__meta-title">
                        <strong>{{'ean' | translate | uppercase}}: </strong> <span *ngFor="let e of product.ean | slice:0:1">{{e}}<br></span>
                    </span>
                </div>
            </div>
            <div class="product-card__footer">
                <div class="product-card__prices">
                    <div class="product-card__price product-card__price--current">
                        {{product.prezzoPubblico | html:'prezzoPubblico':0}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>