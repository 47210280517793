import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { BaseRoutedComponent } from 'src/app/components/base-route.component';
import { Globals } from 'src/classes/global';
import { SeoService } from 'src/services/seo.service';
import { StorageService } from 'src/services/storage.service';
import { TranslatorService } from 'src/services/translator.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseRoutedComponent implements OnInit {
  constructor(public global: Globals,
    protected router: Router,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);
    }

  ngOnInit(): void {
    if (this.routeCulture == undefined || this.routeCulture == null) 
    {      
      this.router.navigate(["/" + this.global.culture + "/home"]);
    }
  }
}