import { Injectable } from '@angular/core';
import { Globals } from 'src/classes/global';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(public global: Globals) { }

  onShow(){
    this.global.loading = true;
  }

  onHide(){
    this.global.loading = false;
  }

  onDelayHide(delay:number){
    setTimeout(() => {
      this.global.loading = false;
    }, delay);
  }
}
