import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faChartSimple, faCheck, faFilePdf, faHeart, faInfoCircle, faMagnifyingGlassPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Globals } from 'src/classes/global';
import { LogoScheda, Product } from 'src/classes/product';
import { LatestProductService } from 'src/services/latest-product.service';
import { SearchService } from 'src/services/search.service';
import { SeoService } from 'src/services/seo.service';
import { TranslatorService } from 'src/services/translator.service';
import { BaseRoutedComponent } from '../../base-route.component';
import { UtilyService } from 'src/services/utility.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalZoomComponent } from '../../shared/modal-zoom/modal-zoom.component';
import { LoadingService } from 'src/services/loading.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Applicazione } from 'src/classes/applicazione';
import { ApplicationService } from 'src/services/application.service';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrl: './datasheet.component.scss'
})
export class DatasheetComponent extends BaseRoutedComponent implements OnDestroy {
  product!:Product;
  codiceProdotto: string = "";
  tabActive: number = 1;
  gallery:Array<any> =[];
  selectedImg="";
  linkVariante:string = "";
  loghi: Array<LogoScheda>=[];
  marche: string[] = []
  modelli: string[] = []
  searchMarca: string = "";
  searchModello: string = "";
  applicazioni: Array<Applicazione>=[];

  faPdf = faFilePdf;
  faStar = faStar;
  faCheck = faCheck;
  faHeart = faHeart;    
  faInfoCircle = faInfoCircle;
  faChartSimple = faChartSimple;
  faMagnifyingGlassPlus = faMagnifyingGlassPlus;

  slideConfig!:any;
  navigationSubscription: Subscription;

  public chart: any;

  constructor(
    public global: Globals,
    protected router: Router,
    protected modalService: NgbModal,
    protected utilyService: UtilyService,
    protected searchService: SearchService,
    protected loadingService: LoadingService,
    protected deviceService: DeviceDetectorService,
    protected applicationService: ApplicationService,
    protected latestProductService: LatestProductService,    
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
      super(route, seoService, storageService, translatorService, cookieService);

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.route.params.subscribe(params => {
            this.codiceProdotto = params['codiceprodotto'].slice(1);
          });

          this.initialiseInvites();
        }
      });
    }    

    get isMobile(){
      return this.deviceService.isMobile();
    }

    initialiseInvites() {
      this.loadingService.onShow();
      this.gallery=[];
      this.loadConfigCarousel();
      this.searchService.getSingleProduct(this.codiceProdotto).subscribe((res: Product) => {    
        this.setSeo(res);
        this.selectedImg = res.foto;                  
        this.gallery.push({ small: this.selectedImg.replace("big", "small"), big: this.selectedImg });
        for(var i = 1; i <= 4; i++){
          let particolare = this.selectedImg.substring(0, this.selectedImg.length-4) + "_" + i + this.selectedImg.substring(this.selectedImg.length-4, this.selectedImg.length);
          this.checkIfImageExists(particolare, (exists:any) => {
            if (exists) {
              this.gallery.push({ small: particolare.replace("big", "small"), big: particolare });
            }
          });
        }

        if(res.hasApplicazioni){
          this.tabActive = 3;
          this.getApplicazioniProdotto();
        } else {
          this.tabActive = 1;
        }


        this.latestProductService.addProduct(res);

        if(res.idVideo.length > 0){
          const tag = document.createElement('script');
          tag.src = "https://www.youtube.com/iframe_api";
          document.body.appendChild(tag);
        }
        
        this.product = res;
        this.loadingService.onDelayHide(500);
      });
    }

    setSeo(product:Product){
      let title = "EVOK Performance | " + product.modello + " - " + product.variante;
      let description =  product.descrizioneMarketing.length > 0 ? "EVOK Performance | " + product.descrizioneMarketing : "EVOK Performance | " + product.descrizioneComparto + " - " + product.descrizioneCategoria + " - " + product.modello + " - " + product.variante;

      this.seoService.setTitle(title);
      this.seoService.updateTag("description", description);
    }

    loadConfigCarousel() {
      if(this.deviceService.isDesktop())
      {
        this.slideConfig = {
          "slidesToShow": 4,
          "slidesToScroll": 4,
          "dots": false,
          "infinite": true,
          "arrows":false,
          "adaptiveHeight":true,
          "responsive":[],
          "cssEase": 'linear'
        };
      }
      else if(this.deviceService.isTablet())
      {
        this.slideConfig = {
          "slidesToShow": 3,
          "slidesToScroll": 1,
          "autoplay": true,
          "dots": false,
          "infinite": true,
          "arrows":false,
          "adaptiveHeight":true,
          "responsive":[],
          "cssEase": 'linear'
        };
      }
      else
      {
        this.slideConfig = {
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "autoplay": true,
          "dots": false,
          "infinite": true,
          "arrows":false,
          "adaptiveHeight":true,
          "responsive":[],
          "cssEase": 'linear'
        };
      }
    }

    setActiveTab(id:number, scrollTo:boolean):void {
      this.tabActive=id;     

      if(scrollTo){
        window.scrollTo(0, 500);
      }      
    }

    
    getApplicazioniProdotto():void {
      this.applicationService.getApplicazioniFromCodice(this.codiceProdotto).subscribe((res: Applicazione[]) => {
        this.applicazioni = res;
        this.marche = [...new Set(this.applicazioni.map(item => item.marca))];
        if(this.marche.length == 1){
          this.searchMarca = this.marche[0];
          this.filterModelli();
        }else{
          this.marche = this.marche.sort((a, b) => {
            const nameA = a.toUpperCase();
            const nameB = b.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            return 0;
          });
        }        

        if(this.applicazioni.length == 0){
          this.setActiveTab(1, false);
        }
       });
    }

    filterModelli(){
      this.searchModello = "";
      this.modelli = [...new Set(this.applicazioni.filter(x => x.marca.toUpperCase() == this.searchMarca).map(item => item.descrizione))];
        this.modelli = this.modelli.sort((a, b) => {
          const nameA = a.toUpperCase();
          const nameB = b.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
                  
          return 0;
        });
    }


    changeSelectedImage(urlImg:string): void{
      this.selectedImg = urlImg;
    }

    openModalFoto(foto:string):void {
      const modalRef = this.modalService.open(ModalZoomComponent, {size: 'lg'});
      modalRef.componentInstance.foto = foto;
    }

    checkIfImageExists(url:string, callback:Function) {
      const img = new Image();
      img.src = url;
  
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
        
        img.onerror = () => {
          callback(false);
        };
      }
    }        

    cleanUp(testo:string):string{
      return this.utilyService.encodeURLParameter(testo);
    }

    slugBrand(testo:string){
      return this.utilyService.encodeBrandDescription(testo);
    }
  
    ngOnDestroy() {
      if (this.navigationSubscription) {
        this.navigationSubscription.unsubscribe();
      }
    }
}
