import { Injectable } from '@angular/core';
import { Globals } from 'src/classes/global';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';


declare var require: any
var availableLanguages=[ "en", "it", "es", "fr" ];

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {    
    constructor(
      public global: Globals,
      protected http: HttpClient,
      protected translateService: TranslateService) 
    {
      this.loadFile();
    }

    loadFile(){

      let i18nFr = require("src/assets/i18n/fr.json");
      this.translateService.setTranslation("fr", i18nFr, true);
      let i18nGlobalFr = require("src/assets/i18n/fr.json");
      this.translateService.setTranslation("fr", i18nGlobalFr, true);

      let i18nEs = require("src/assets/i18n/es.json");
      this.translateService.setTranslation("es", i18nEs, true); 
      let i18nGlobalEs = require("src/assets/i18n/es.json");
      this.translateService.setTranslation("es", i18nGlobalEs, true);

      let i18nEn = require("src/assets/i18n/en.json");
      this.translateService.setTranslation("en", i18nEn, true);
      let i18nGlobalEn = require("src/assets/i18n/en.json");
      this.translateService.setTranslation("en", i18nGlobalEn, true);

      let i18nIt = require("src/assets/i18n/it.json");
      this.translateService.setTranslation("it", i18nIt, true);
      let i18nGlobalIt = require("src/assets/i18n/it.json");
      this.translateService.setTranslation("it", i18nGlobalIt, true);
      
      
      this.translateService.addLangs(availableLanguages);
      this.translateService.setDefaultLang("en");
    }

    setComponentLanguage(lang: string): void {      
      this.translateService.use(lang);
    }

    getLanguage(routeCulture: string): any {
      const availableLanguagesMatch = availableLanguages.toString().replace(/,/g, '|');
  
      const re = new RegExp(availableLanguagesMatch, 'gi');
  
      var browserCulture = this.translateService.getBrowserLang();
  
      if (routeCulture !== undefined && routeCulture !== null && browserCulture !== undefined) {
        this.global.cultureRoutingMatching = routeCulture.match(re) ? true : false;
        return routeCulture.match(re) ?
          routeCulture
          : browserCulture.match(re) ?
            browserCulture
            : 'en';
      }
  
      return browserCulture ? browserCulture.match(re) ? browserCulture : 'en' : 'en';
    }

    setGlobals(currentCulture: string): void {
      this.global.culture = currentCulture.toLocaleLowerCase();

      this.loadFile();
    }


    getLocalization(key: string): string {
        return this.translateService.instant(key);
    }

    getLocalizationData<TData>(key: string) : TData {
      return this.translateService.instant(key);
    }
}