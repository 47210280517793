<ngb-carousel *ngIf="global.culture == 'it'">
    <ng-template ngbSlide *ngFor="let slide of slidesIT">
        <a href="{{slide.href}}">
            <div class="picsum-img-wrapper" style="background-image: url('{{slide.img}}');">          
                <img src="../../../../assets/images/slide-100x540.png" alt="{{slide.title}}">            
            </div>
        </a>            
    </ng-template>
</ngb-carousel>

<ngb-carousel *ngIf="global.culture == 'en'">
    <ng-template ngbSlide *ngFor="let slide of slidesEN">
        <a href="{{slide.href}}">
            <div class="picsum-img-wrapper" style="background-image: url('{{slide.img}}');">
                <img src="../../../../assets/images/slide-100x540.png" alt="{{slide.title}}">
            </div>
        </a>        
    </ng-template>
</ngb-carousel>

<ngb-carousel *ngIf="global.culture == 'es'">
    <ng-template ngbSlide *ngFor="let slide of slidesES">
        <a href="{{slide.href}}">
            <div class="picsum-img-wrapper" style="background-image: url('{{slide.img}}');">
                <img src="../../../../assets/images/slide-100x540.png" alt="{{slide.title}}">
            </div>
        </a>        
    </ng-template>
</ngb-carousel>

<ngb-carousel *ngIf="global.culture == 'fr'">
    <ng-template ngbSlide *ngFor="let slide of slidesFR">
        <a href="{{slide.href}}">
            <div class="picsum-img-wrapper" style="background-image: url('{{slide.img}}');">
                <img src="../../../../assets/images/slide-100x540.png" alt="{{slide.title}}">
            </div>
        </a>        
    </ng-template>
</ngb-carousel>