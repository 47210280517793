import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { HtmlPipe } from 'src/pipe/html-pipe';
import { FilterPipe } from 'src/pipe/filter-pipe';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ChiSiamoComponent } from './components/pages/chi-siamo/chi-siamo.component';
import { ChiSiamoItComponent } from './components/pages/chi-siamo/it/it.component';
import { ChiSiamoEnComponent } from './components/pages/chi-siamo/en/en.component';
import { ChiSiamoEsComponent } from './components/pages/chi-siamo/es/es.component';
import { ChiSiamoFrComponent } from './components/pages/chi-siamo/fr/fr.component';
import { HomeCarouselComponent } from './components/shared/home-carousel/home-carousel.component';
import { SearchFulltextComponent } from './components/pages/search-fulltext/search-fulltext.component';
import { LatestProductsComponent } from './components/shared/latest-products/latest-products.component';
import { SearchProductListComponent } from './components/shared/search-product-list/search-product-list.component';
import { ProductSearchComponent } from './components/shared/product-search/product-search.component';
import { DatasheetComponent } from './components/pages/datasheet/datasheet.component';
import { ModalZoomComponent } from './components/shared/modal-zoom/modal-zoom.component';
import { ProductVariantComponent } from './components/shared/product-variant/product-variant.component';
import { SearchProductsComponent } from './components/pages/search-products/search-products.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyItComponent } from './components/pages/privacy-policy/it/it.component';
import { PrivacyPolicyEnComponent } from './components/pages/privacy-policy/en/en.component';
import { PrivacyPolicyEsComponent } from './components/pages/privacy-policy/es/es.component';
import { PrivacyPolicyFrComponent } from './components/pages/privacy-policy/fr/fr.component';
import { ErrorComponent } from './components/pages/error/error/error.component';
import { NotFoundComponent } from './components/pages/error/not-found/not-found.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TokenInterceptorService } from 'src/services/token-interceptor.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslatorService } from 'src/services/translator.service';
import { Globals } from 'src/classes/global';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from 'ngx-slider-v2';
import { HomeItComponent } from './components/pages/home/it/it.component';
import { HomeEnComponent } from './components/pages/home/en/en.component';
import { HomeFrComponent } from './components/pages/home/fr/fr.component';
import { HomeEsComponent } from './components/pages/home/es/es.component';
import { FilterLikePipe } from 'src/pipe/filter-like-pipe';
import { SearchApplicationComponent } from './components/pages/search-application/search-application.component';
import { ProductSponsoredMediumComponent } from './components/shared/product-sponsored-medium/product-sponsored-medium.component';
import { ProductSponsoredMobileComponent } from './components/shared/product-sponsored-mobile/product-sponsored-mobile.component';
import { ContactComponent } from './components/shared/contact/contact.component';
import { ModalConfirmComponent } from './components/shared/modal-confirm/modal-confirm.component';
import { ProductSliderComponent } from './components/shared/product-slider/product-slider.component';
import { StoreLocatorComponent } from './components/pages/store-locator/store-locator.component';
import { GoogleAutocompleteComponent } from './components/shared/google-autocomplete/google-autocomplete.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { HomeNewsComponent } from './components/shared/home-news/home-news.component';
import { DownloadCatalogComponent } from './components/pages/download-catalog/download-catalog.component';
import { DownloadInstructionsComponent } from './components/pages/download-instructions/download-instructions.component';
import { NewsComponent } from './components/pages/news/news.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "https://www.evok-performance.it/"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#4c4d4f",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {      
      "background": "#f3722a",
      "text": "#4c4d4f",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "header": "Cookies used on the website!",
    "message": "This website uses cookies to ensure a better browsing experience.",
    "dismiss": "Got it!",
    "allow": "Allow cookies",
    "deny": "Refuse cookies",
    "link": "Learn more",    
    "policy": "Cookie Policy",
    "href": "https://www.evok-performance.it/en/privacy-policy"
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HtmlPipe,
    FilterPipe,
    FilterLikePipe,
    AppComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HomeItComponent,
    HomeEnComponent,
    HomeEsComponent,
    HomeFrComponent,
    ChiSiamoComponent,
    ChiSiamoItComponent,
    ChiSiamoEnComponent,
    ChiSiamoEsComponent,
    ChiSiamoFrComponent,
    HomeCarouselComponent,
    SearchFulltextComponent,    
    LatestProductsComponent,
    SearchProductListComponent,
    SearchApplicationComponent,
    ProductSearchComponent,
    ProductSponsoredMediumComponent,
    ProductSponsoredMobileComponent,
    DatasheetComponent,
    ModalZoomComponent,
    HomeNewsComponent,
    ProductVariantComponent,
    SearchProductsComponent,
    ErrorComponent,
    NotFoundComponent,    
    PrivacyPolicyComponent,
    PrivacyPolicyItComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyEsComponent,
    PrivacyPolicyFrComponent,
    ModalConfirmComponent,
    ProductSliderComponent,
    StoreLocatorComponent,
    GoogleAutocompleteComponent,
    DownloadCatalogComponent,
    DownloadInstructionsComponent,
    NewsComponent
  ],
  imports: [
    NgbModule, 
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgxSliderModule,
    GoogleMapsModule,
    NgxGpAutocompleteModule,
    NgxWebstorageModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)', 
      backdropBorderRadius: '4px',
      primaryColour: '#f3722a',
      secondaryColour: '#f3722a', 
      tertiaryColour: '#f3722a'      
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    SlickCarouselModule,
    YouTubePlayerModule
  ],
  providers: [
    Globals,
    TranslatorService,
    DeviceDetectorService,    
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyDcevTzsyck2oWuL-eXhbduEV3vz9cZCaY',
        libraries: ['places']
      })
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
