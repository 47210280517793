import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Globals } from 'src/classes/global';
import { TranslatorService } from 'src/services/translator.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  year!:number;

  faInstagram = faInstagram;
  faFacebook = faFacebook;
  
  constructor(public global: Globals, 
    protected transalateService:TranslatorService,
    protected router: Router,
    protected route: ActivatedRoute) {
      this.year = (new Date()).getFullYear();
  }
}
