<!-- site__body -->
<div class="site__body">
    <div class="block-header block-header--has-breadcrumb">
        <div class="container-fluid mainContent">
            <div class="block-header__body">
                <nav class="breadcrumb block-header__breadcrumb" aria-label="breadcrumb">
                    <ol class="breadcrumb__list">
                        <li class="breadcrumb__spaceship-safe-area" role="presentation"></li>
                        <li class="breadcrumb__item breadcrumb__item--parent breadcrumb__item--first">
                            <a routerLink="/{{global.culture}}/home" class="breadcrumb__item-link">{{'home' | translate}}</a>
                        </li>
                        <li class="breadcrumb__item breadcrumb__item--parent" *ngIf="product != null">
                            <a routerLink="/{{global.culture}}/category-list/K{{product.idComparto}}/{{cleanUp(product.descrizioneComparto)}}" class="breadcrumb__item-link">{{product.descrizioneComparto}}</a>
                        </li>
                        <li class="breadcrumb__item breadcrumb__item--parent" *ngIf="product != null">
                            <a routerLink="/{{global.culture}}/search-products/{{'C'+product.idComparto+'-'+product.idCategoria}}/{{cleanUp(product.descrizioneComparto+'-'+product.descrizioneCategoria)}}" class="breadcrumb__item-link">{{product.descrizioneCategoria}}</a>
                        </li>
                        <li *ngIf="product != null" class="breadcrumb__item breadcrumb__item--current breadcrumb__item--last" aria-current="page">
                            <span class="breadcrumb__item-link">{{product.codiceProdotto}}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <div *ngIf="!global.loading" class="block-split">
        <div class="container-fluid mainContent">
            <div class="block-split__row row no-gutters">
                <div class="block-split__item block-split__item-content col-auto" style="padding-left: 0; padding-right: 0;">
                    <div class="product product--layout--full">
                        <div class="product__body" *ngIf="product != null">
                            <div class="product__card product__card--one"></div>
                            <div class="product__card product__card--two"></div>
                            <div class="product-gallery product__gallery product-gallery--layout--product-full" data-layout="product-full">
                                <div class="product-gallery__featured">
                                    <img class="img-first" src="{{selectedImg}}" onerror="this.src='../../../../assets/images/no-image-found.jpg'" alt="{{product.modello}}" (click)="openModalFoto(selectedImg)">
                                </div>
                                <div class="product-gallery__thumbnails" *ngIf="gallery.length > 1">
                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                                        <div ngxSlickItem *ngFor="let img of gallery" class="slide">                       
                                            <img class="img-gallery link-p" src="{{img.small}}"  [ngClass]="{'border--selected': img.big == selectedImg, 'border--gallery' : img.big != selectedImg}" (click)="changeSelectedImage(img.big)">
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>
                            <div *ngIf="!isMobile" class="product__header">
                                <h1 class="product__title">{{product.modello | uppercase}}</h1>
                            </div>
                            <h6 *ngIf="isMobile" class="block-header__title block-header__title__mobile border-bottom">{{product.modello | uppercase}}</h6>
                            
                            <div class="product__main">
                                <div class="product-card__brandLogo" [ngStyle]="{'margin-left': isMobile ? '-15px' : '0' }">
                                    <a routerLink="/{{global.culture}}/category-list/B{{product.idBrand}}/{{cleanUp(product.descrizioneBrand)}}">
                                        <img src="https://static.rms.it/b2b/loghi/brand/{{slugBrand(product.descrizioneBrand)}}_small.png" [alt]="product.descrizioneBrand" class="img-fluid">
                                    </a>
                                    <span *ngIf="!isMobile">
                                        <img *ngFor="let logo of product.loghi" [src]="logo.image" [title]="logo.nome" alt="{{logo.nome}}" class="img-fluid">
                                    </span>
                                    <span *ngIf="isMobile">
                                        <img *ngFor="let logo of product.loghi | filter:'tipo':1" [src]="logo.image" [title]="logo.nome" alt="{{logo.nome}}" class="img-fluid">
                                    </span>
                                </div>
                                <div *ngIf="product.quickInfo.length > 0 && product.descrizioneMarketing.length > 1" class="product__excerpt link-p" (click)="setActiveTab(1, true)">
                                    <span innerHTML="{{product.descrizioneMarketing | html:'description':250}}"></span>
                                </div>
                                <div *ngIf="product.quickInfo.length == 0 && product.descrizioneMarketing.length > 1" class="product__excerpt link-p" (click)="setActiveTab(1, true)">
                                    <span innerHTML="{{product.descrizioneMarketing | html:'description':700}}"></span>
                                </div>
                                <div *ngIf="product.quickInfo.length > 0 && product.descrizioneMarketing.length <= 1"  class="product__excerpt link-p" (click)="setActiveTab(1, true)">
                                    <span innerHTML="{{product.descrizioneTecnica | html:'description':250}}"></span>
                                </div>
                                <div *ngIf="product.quickInfo.length == 0 && product.descrizioneMarketing.length <= 1"  class="product__excerpt link-p" (click)="setActiveTab(1, true)">
                                    <span innerHTML="{{product.descrizioneTecnica | html:'description':500}}"></span>
                                </div>
                                <div *ngIf="product.quickInfo.length > 0" class="product__features">
                                    <div class="product__features-title">{{'datasheet.specificheTecniche' | translate}}:</div>
                                    <ul>
                                        <li *ngFor="let quick of product.quickInfo | slice:0:4">
                                            {{quick.quick}}: <span>{{quick.valore}}</span>
                                        </li>
                                    </ul>
                                    <div *ngIf="product.quickInfo.length > 4" class="product__features-link link-p" (click)="setActiveTab(2, true)">
                                        <a>...{{'datasheet.mostraTutte' | translate}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="product__info">
                                <div class="product__info-card">
                                    <div class="product__info-body">
                                        <div class="product__prices-stock">
                                            <div class="product__prices">
                                                <div class="product__price product__price--current">
                                                    {{product.prezzoPubblico | html:'prezzoPubblico':0}} <br>
                                                    <span *ngIf="product.prezzoPubblico > 0" class="info-price">{{'price.consigliatoAlPubblico' | translate}} {{'price.ivaInclusa' | translate}}</span>
                                                </div>                                                
                                            </div>                                      
                                        </div>                                  
                                        <div class="product__meta">                                          
                                            <table>                                                
                                                <tr>
                                                    <th>{{'codiceProdotto' | translate | uppercase}}</th>
                                                    <td>
                                                        <strong style="font-size: 16px;">{{product.codiceProdotto}}</strong>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="product.riferimentoOriginale.length == 1">
                                                    <th>{{'riferimentoOriginale' | translate | uppercase}}</th>
                                                    <td>
                                                        <span>{{product.riferimentoOriginale[0]}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="product.riferimentoOriginale.length > 1">
                                                    <th>{{'riferimentiOriginali' | translate | uppercase}}</th>
                                                    <td>
                                                        <span>{{product.riferimentoOriginale[0]}}</span> - <span>{{product.riferimentoOriginale[1]}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="product.upc.length > 1">
                                                    <th>{{'codiceFornitore' | translate | uppercase}}</th>
                                                    <td>{{product.upc}}</td>
                                                </tr>
                                                <tr *ngIf="product.ean.length > 0">
                                                    <th>{{'ean' | translate | uppercase}}</th>
                                                    <td>
                                                        <span *ngFor="let e of product.ean"> {{e}}<br></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                   </div>
                                   <div class="product-form product__form p-0"></div>
                                </div>                              
                                <div class="product__info-card" *ngFor="let spec of product.tc">
                                    <div class="product__info-body">
                                        <div *ngIf="!isMobile" class="product__badge tag-badge tag-badge--new">{{spec.quick}}</div>
                                        <h5 *ngIf="isMobile">{{spec.quick | uppercase}}</h5>
                                        <div *ngIf="spec.idQuick == '43'" class="input-radio-label">
                                            <div class="input-radio-label__list">
                                                <label class="input-radio-label__item" *ngFor="let sval of spec.valoreCodiceProdotto">
                                                    <a href="/{{global.culture}}/datasheet/{{'P'+sval.codiceProdotto}}/{{cleanUp(sval.modello)}}" class="colore-{{sval.keyValue}}" [ngClass]="sval.codiceProdotto == product.codiceProdotto ? 'inactiveLink': ''">
                                                        <label class="input-radio-color__item" ngbTooltip="{{sval.value}}">
                                                            <input type="radio" name="color">
                                                            <span></span>                                                            
                                                        </label>
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div *ngIf="spec.idQuick != '43'" class="input-radio-label">
                                            <div class="input-radio-label__list">
                                                <label class="input-radio-label__item" *ngFor="let sval of spec.valoreCodiceProdotto">
                                                    <a href="/{{global.culture}}/datasheet/{{'P'+sval.codiceProdotto}}/{{cleanUp(sval.modello)}}" [ngClass]="sval.codiceProdotto == product.codiceProdotto ? 'inactiveLink': ''">
                                                        <input type="radio" name="material" class="input-radio-label__input">
                                                        <span class="input-radio-label__title" [ngClass]="sval.codiceProdotto == product.codiceProdotto ? 'option-bg--selected' : 'option-bg'">{{sval.value}}</span>
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-form product__form p-0">
                                    </div>
                                </div>
                                <div class="card widget widget-products d-none d-lg-block" style="margin-top: 20px;">
                                    <div class="widget-products__list" style="padding-bottom: 0;">
                                        <div class="card mb-3" style="padding: 0px 10px;">
                                            <div class="row g-0">
                                              <div class="col-md-4">
                                                <img src="../../../../assets/images/evokShop.png" class="img-fluid rounded-start" style="margin-top: 0.5rem;"  alt="WAG SHOP">
                                              </div>
                                              <div class="col-md-8">
                                                <div class="card-body">
                                                    <h5 class="card-title card-title-locator">
                                                    <a routerLink="/{{global.culture}}/store-locator" class="btn btn-primary">
                                                        <strong>{{'cercaRivenditore' | translate}}</strong>
                                                    </a>
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <app-product-variant [idScheda]="product.idSchedaProdotto"></app-product-variant>
                                <app-latest-products></app-latest-products>                               
                            </div>
                            <div class="product__tabs product-tabs product-tabs--layout--full">
                                <ul class="product-tabs__list">
                                    <li class="product-tabs__item" (click)="setActiveTab(1, false)" [ngClass]="{'product-tabs__item--active' : tabActive === 1}">
                                        <a><strong>{{'descrizione' | translate | uppercase}}</strong></a>
                                    </li>
                                    <li class="product-tabs__item" (click)="setActiveTab(2, false)" [ngClass]="{'product-tabs__item--active' : tabActive === 2}" *ngIf="product.quickInfo.length > 0">
                                        <a>
                                            <strong>
                                                <span class="d-none d-md-inline">{{'datasheet.specificheTecniche' | translate | uppercase}}</span>
                                                <span class="d-block d-md-none">{{'datasheet.sTecniche' | translate | uppercase}}</span>
                                            </strong>
                                        </a>
                                    </li>
                                    <li *ngIf="product.hasApplicazioni" class="product-tabs__item" (click)="setActiveTab(3, false)" [ngClass]="{'product-tabs__item--active' : tabActive === 3}">
                                        <a>
                                            <strong>
                                                {{'application.applicazioni' | translate | uppercase}}
                                            </strong>
                                            <span class="product-tabs__item-counter">{{applicazioni.length}}</span>
                                        </a>
                                    </li>
                                    <li *ngIf="product.idVideo.length > 0 && !isMobile" class="product-tabs__item" (click)="setActiveTab(3, false)" [ngClass]="{'product-tabs__item--active' : tabActive === 3}">
                                        <a>
                                            <strong>
                                                <span class="d-none d-md-inline">{{'datasheet.videoTutorial' | translate | uppercase}}</span>
                                                <span class="d-block d-md-none">{{'datasheet.videoTutorial' | translate | uppercase}}</span>
                                            </strong>                                            
                                        </a>
                                    </li>
                                </ul>
                                <div class="product-tabs__content">
                                    <div class="product-tabs__pane" [ngClass]="{'product-tabs__pane--active' : tabActive === 1}" id="product-tab-description">
                                        <div class="typography">
                                            <p [innerHTML]="product.descrizioneMarketing"></p>
                                            <h4 *ngIf="product.descrizioneMarketing && product.descrizioneTecnica"><br>{{'datasheet.caratteristicheTecniche' | translate | uppercase}}</h4>
                                            <p [innerHTML]="product.descrizioneTecnica"></p>
                                        </div>
                                    </div>
                                    <div class="product-tabs__pane" [ngClass]="{'product-tabs__pane--active' : tabActive === 2}" id="product-tab-specification">
                                        <div class="spec">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-12 m-3" [ngClass]="{'col-lg-6' : product.manuali && product.manuali.length >0 }">
                                                    <div class="product__features-title">{{'datasheet.informazioniAggiuntive' | translate | uppercase}}</div>
                                                    <div class="spec__section">
                                                        <div class="spec__row m-3" *ngFor="let quick of product.quickInfo">
                                                            <div class="spec__name">{{quick.quick}}</div>
                                                            <div class="spec__value">{{quick.valore}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-1" *ngIf="product.manuali && product.manuali.length >0"></div>
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-4 ml-2 m-3" *ngIf=" product.manuali && product.manuali.length >0">
                                                    <div class="product__features-title" >{{'datasheet.materialeTecnico' | translate | uppercase}}</div>
                                                    <div class="spec__section">
                                                        <div class="spec__row m-3" *ngFor="let m of product.manuali">
                                                            <a target="_blank" href="https://static.rms.it/b2b/manuali/{{m.manuale}}" style="text-decoration: none; color: inherit;">
                                                                <fa-icon class="fa-2x" [icon]="faPdf" style="vertical-align: middle; margin-right: 15px;"></fa-icon>
                                                                <span class="spec__name">{{ m.titolo }}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="spec__disclaimer">
                                                    {{'datasheet.infoSpecificheTecniche' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-tabs__pane" [ngClass]="{'product-tabs__pane--active' : tabActive === 3}" id="product-tab-reviews">
                                        <div *ngIf="!isMobile" class="row row-info-application">
                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <strong>{{'application.filtraMarca' | translate | uppercase}}</strong>
                                                <select class="form-control" [(ngModel)]="searchMarca" (change)="filterModelli()">
                                                    <option value="">{{'tutti' | translate | uppercase}}</option>
                                                    <option *ngFor="let marca of marche" [value]="marca">{{marca}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="searchMarca" class="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <strong>{{'application.filtraModello' | translate | uppercase}}</strong>
                                                <select class="form-control" [(ngModel)]="searchModello">
                                                    <option value="">{{'tutti' | translate | uppercase}}</option>
                                                    <option *ngFor="let modello of modelli" [value]="modello">{{modello}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <table *ngIf="applicazioni && applicazioni.length > 0" class="table table-striped table-responsive">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th>{{'application.marca' | translate | uppercase}}</th>
                                                    <th>{{'application.modello' | translate | uppercase}}</th>
                                                    <th>{{'application.cc' | translate | uppercase}}</th>
                                                    <th>{{'application.anni' | translate | uppercase}}</th>
                                                    <th>{{'application.telaio' | translate | uppercase}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let app of applicazioni | filterLike:['marca']:searchMarca | filterLike:['descrizione']:searchModello">
                                                    <td>{{app.marca}}</td>
                                                    <td>{{app.descrizione}}</td>
                                                    <td>{{app.cilindrata}}</td>
                                                    <td>{{app.anni}}</td>
                                                    <td>{{app.telaio}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="product-tabs__pane" [ngClass]="{'product-tabs__pane--active' : tabActive === 4}" id="product-tab-video">
                                        <div *ngFor="let id of product.idVideo" class="embed-responsive embed-responsive-16by9 text-center">
                                            <youtube-player [videoId]="id" [suggestedQuality]="'highres'" class="embed-responsive-item"></youtube-player>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-space block-space--layout--divider-nl"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="block-space block-space--layout--before-footer"></div>
</div>
<!-- site__body / end -->