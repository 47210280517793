import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Globals } from 'src/classes/global';
import { Product } from 'src/classes/product';
import { StorageService } from './storage.service';
import { TranslatorService } from './translator.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LatestProductService {

  constructor(
    public global: Globals,
    protected http: HttpClient,    
    protected storageService: StorageService,
    protected translatorService: TranslatorService) { }

  get() {
    var products = new Array<Product>();
    if(this.storageService.contains(environment.latestProductKey))
    {
      products = this.storageService.read<any>(environment.latestProductKey);
    }       
    return of(products);
  }

  addProduct(product: Product) {
    var products: Array<Product> = [];
    if(this.storageService.contains(environment.latestProductKey))
    {
      products = this.storageService.read<Array<Product>>(environment.latestProductKey);
      products.forEach((item, index) => {
        if(item.codiceProdotto === product.codiceProdotto) {                    
          products.splice(index,1);
        }
      });

      if(products.length >= 5) { 
        var index = products.length - 1;       
        products.splice(index, 1);
      }
    }

    var orderProducts: Array<Product> = [];
    orderProducts.push(product);

    products.forEach((item) => {
      orderProducts.push(item);
    });
    
    this.storageService.write(environment.latestProductKey, orderProducts);
  }
}