import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-es',
  templateUrl: './es.component.html',
  styleUrl: './es.component.scss'
})
export class PrivacyPolicyEsComponent {

}
