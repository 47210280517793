<div class="site__body" *ngIf="searchResult!=null">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
        <div class="container-fluid mainContent content-search">
            <div class="block-header__body">
                <nav class="breadcrumb block-header__breadcrumb" aria-label="breadcrumb">
                    <ol class="breadcrumb__list">
                        <li class="breadcrumb__spaceship-safe-area" role="presentation"></li>
                        <li class="breadcrumb__item breadcrumb__item--parent breadcrumb__item--first">
                            <a routerLink="/{{global.culture}}/home" class="breadcrumb__item-link">{{'home' | translate}}</a>
                        </li>
                        <li class="breadcrumb__item breadcrumb__item--parent" *ngFor="let crumb of breadCrumb">
                            <a routerLink="/{{global.culture}}/{{crumb.url}}" class="breadcrumb__item-link">{{crumb.label}}</a>
                        </li>
                        <li class="breadcrumb__item breadcrumb__item--current breadcrumb__item--last" aria-current="page">
                            <span class="breadcrumb__item-link">{{searchResult.titoloRicerca}}</span>
                        </li>
                        <li class="breadcrumb__title-safe-area" role="presentation"></li>
                    </ol>
                </nav>
                <h1 class="block-header__title">{{searchResult.titoloRicerca}}</h1>
            </div>
        </div>
    </div>
    <div *ngIf="!global.loading" class="block-split block-split--has-sidebar">
        <div class="container-fluid mainContent content-search">
            <div class="block-split__row row no-gutters">
                <div class="block-split__item block-split__item-sidebar col-auto">
                    <div class="sidebar sidebar--offcanvas--mobile">
                        <div class="sidebar__backdrop"></div>
                        <div class="sidebar__body">
                            <div class="sidebar__header">
                                <div class="sidebar__title">
                                    <strong>
                                        {{'search.filtri' | translate | uppercase}}
                                    </strong>
                                </div>
                                <button class="sidebar__close" type="button">
                                    <svg width="12" height="12">
                                        <path d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4C11.2,9.8,11.2,10.4,10.8,10.8z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="sidebar__content">
                                <div class="widget widget-filters widget-filters--offcanvas--mobile">
                                    <div class="widget__header widget-filters__header">
                                        <h4>
                                            {{'search.filtri' | translate | uppercase}}
                                            <button class="btn btn-primary btn-sm btn-close-sidebar" style="float: right; margin-left: 10px; margin-right: -10px;">{{'chiudi' | translate | uppercase}}</button>
                                            <button *ngIf="(!filtriSelezionati || filtriSelezionati.length == 0)" class="btn btn-secondary btn-sm" style="float: right;">{{'search.reset' | translate | uppercase}}</button>
                                            <button *ngIf="(filtriSelezionati && filtriSelezionati.length > 0)" class="btn btn-primary btn-sm" style="float: right;" (click)="cleanFiltriAttivi()">{{'search.reset' | translate | uppercase}}</button>
                                        </h4>
                                    </div>
                                    <!-- FILTRO BRAND/CATEGORIA/PROMO -->
                                    <div class="widget-filters__list">                                 
                                        <div *ngFor="let filtri of filtriQuick">
                                            <div class="widget-filters__item" *ngIf="filtri.id =='BRAND' || filtri.id =='CATEGORIE' || filtri.id == 'PROMO'">
                                                <div class="filter filter--opened">
                                                    <button type="button" class="filter__title" (click)="filtri.isOpen = !filtri.isOpen" [attr.aria-expanded]="filtri.isOpen">
                                                        {{filtri.quick | uppercase}}
                                                        <span class="filter__arrow">
                                                            <fa-icon *ngIf="filtri.isOpen" [icon]="faChevronDown"></fa-icon>
                                                            <fa-icon *ngIf="!filtri.isOpen" [icon]="faChevronUp"></fa-icon>
                                                        </span>
                                                    </button>
                                                    <div class="filter__body" [ngbCollapse]="!filtri.isOpen">
                                                        <div class="filter__container">
                                                            <div class="filter-list">
                                                                <div class="filter-list__list">
                                                                    <label class="filter-list__item" *ngFor="let voceFiltro of filtri.valori">
                                                                        <span class="input-check filter-list__input">
                                                                            <span class="input-check__body">
                                                                                <input class="input-check__input" type="checkbox" [(ngModel)]="voceFiltro.checked" (change)="addToFiltri(filtri.id, filtri.quick, voceFiltro)">
                                                                                <span class="input-check__box"></span>
                                                                                <span class="input-check__icon">
                                                                                    <svg width="9px" height="7px">
                                                                                        <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <span class="filter-list__title" [ngClass]="{'inactiveFilter': voceFiltro.inactive}">
                                                                            {{voceFiltro.value | uppercase}}
                                                                        </span>
                                                                        <span class="filter-list__counter" [ngClass]="{'inactiveFilter': voceFiltro.inactive}">{{voceFiltro.tot}}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- FILTRO BRAND/CATEGORIA/PROMO -->
                                        <!-- FILTRO PREZZO -->
                                        <div *ngIf="searchResult.results.length > 0" class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title" (click)="collapse.toggle()" [attr.aria-expanded]="!togglePrice">
                                                    {{'price.prezzo' | translate}}
                                                    <span class="filter__arrow">
                                                        <fa-icon *ngIf="!togglePrice" [icon]="faChevronDown"></fa-icon>
                                                        <fa-icon *ngIf="togglePrice" [icon]="faChevronUp"></fa-icon>
                                                    </span>
                                                </button>
                                                <div class="filter__body" #collapse="ngbCollapse" [(ngbCollapse)]="togglePrice">
                                                    <div class="filter__container">
                                                        <div class="filter-price">
                                                            <div class="custom-slider">
                                                                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (userChangeEnd)="onUserChangeEnd($event)"></ngx-slider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- FILTRO PREZZO -->
                                        <!-- FILTRI QUICK -->
                                        <div *ngFor="let filtri of filtriQuick">
                                            <div class="widget-filters__item" *ngIf="filtri.id !='CATEGORIE' && filtri.id !='BRAND' && filtri.id !='PROMO' && (filtri.idTipo != 2 || (filtri.idTipo == 2 && filtri.valori.length <= limitValueToSlide))">
                                                <div class="filter filter--opened">
                                                    <button type="button" class="filter__title" (click)="filtri.isOpen = !filtri.isOpen" [attr.aria-expanded]="filtri.isOpen">
                                                        {{filtri.quick | uppercase}}
                                                        <span class="filter__arrow">
                                                            <fa-icon *ngIf="filtri.isOpen" [icon]="faChevronDown"></fa-icon>
                                                            <fa-icon *ngIf="!filtri.isOpen" [icon]="faChevronUp"></fa-icon>
                                                        </span>
                                                    </button>
                                                    <div class="filter__body" [ngbCollapse]="!filtri.isOpen">
                                                        <div class="filter__container">
                                                            <div class="filter-list">
                                                                <div class="filter-list__list">
                                                                    <label class="filter-list__item" *ngFor="let voceFiltro of filtri.valori">
                                                                        <span class="input-check filter-list__input">
                                                                            <span class="input-check__body">
                                                                                <input class="input-check__input" type="checkbox" [(ngModel)]="voceFiltro.checked" (change)="addToFiltri(filtri.id, filtri.quick, voceFiltro)">
                                                                                <span class="input-check__box"></span>
                                                                                <span class="input-check__icon">
                                                                                    <svg width="9px" height="7px">
                                                                                        <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <span class="filter-list__title" [ngClass]="{'inactiveFilter': voceFiltro.inactive}">
                                                                            {{voceFiltro.value | uppercase}}
                                                                        </span>
                                                                        <span class="filter-list__counter" [ngClass]="{'inactiveFilter': voceFiltro.inactive}">{{voceFiltro.tot}}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="widget-filters__item" *ngIf="filtri.id !='CATEGORIE' && filtri.id !='BRAND' && filtri.idTipo == 2 && filtri.valori.length > limitValueToSlide">
                                                <div class="filter filter--opened">
                                                    <button type="button" class="filter__title" (click)="filtri.isOpen = !filtri.isOpen" [attr.aria-expanded]="filtri.isOpen">
                                                        {{filtri.quick | uppercase}} ({{filtri.unitaDiMisura}})
                                                        <span class="filter__arrow">
                                                            <fa-icon *ngIf="filtri.isOpen" [icon]="faChevronDown"></fa-icon>
                                                            <fa-icon *ngIf="!filtri.isOpen" [icon]="faChevronUp"></fa-icon>
                                                        </span>
                                                    </button>
                                                    <div class="filter__body" [ngbCollapse]="!filtri.isOpen">
                                                        <div class="filter__container">
                                                            <div class="filter-price">
                                                                <div class="custom-slider">
                                                                    <ngx-slider [(value)]="filtri.minValueSelected" [(highValue)]="filtri.maxValueSelected" [options]="{ floor: filtri.minValue, ceil: filtri.maxValue }" (userChangeEnd)="onUserChangeEndFilter($event, filtri)"></ngx-slider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- FILTRI QUICK -->
                                    </div>
                                </div>
                                <app-latest-products *ngIf="isDesktop"></app-latest-products>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-split__item block-split__item-content col-auto">
                    <div class="block">
                        <div class="products-view">
                            <div class="products-view__options view-options view-options--offcanvas--mobile">
                                <div class="view-options__body">
                                    <button type="button" class="view-options__filters-button filters-button">
                                        <span class="filters-button__icon">
                                            <svg width="16" height="16">
                                                <path d="M7,14v-2h9v2H7z M14,7h2v2h-2V7z M12.5,6C12.8,6,13,6.2,13,6.5v3c0,0.3-0.2,0.5-0.5,0.5h-2C10.2,10,10,9.8,10,9.5v-3C10,6.2,10.2,6,10.5,6H12.5z M7,2h9v2H7V2z M5.5,5h-2C3.2,5,3,4.8,3,4.5v-3C3,1.2,3.2,1,3.5,1h2C5.8,1,6,1.2,6,1.5v3C6,4.8,5.8,5,5.5,5z M0,2h2v2H0V2z M9,9H0V7h9V9z M2,14H0v-2h2V14z M3.5,11h2C5.8,11,6,11.2,6,11.5v3C6,14.8,5.8,15,5.5,15h-2C3.2,15,3,14.8,3,14.5v-3C3,11.2,3.2,11,3.5,11z" />
                                            </svg>
                                        </span>
                                        <span class="filters-button__title">{{'search.filtri' | translate}}</span>
                                        <span class="filters-button__counter">{{filtriSelezionati.length}}</span>
                                    </button>
                                    <div *ngIf="!isMobile" class="view-options__legend">
                                        <strong>{{searchResult.totaleProdotti}}</strong> 
                                        <span *ngIf="searchResult.totaleProdotti > 1" style="margin-left: 5px;"> {{'search.prodottiTrovati' | translate | uppercase}}</span>
                                        <span *ngIf="searchResult.totaleProdotti == 1" style="margin-left: 5px;"> {{'search.prodottoTrovato' | translate | uppercase}}</span>
                                    </div>
                                    <div *ngIf="!isMobile" class="view-options__spring"></div>
                                    <div *ngIf="!isMobile" class="view-options__select">
                                        <label for="view-option-sort">{{'search.ordina' | translate | uppercase}}:</label>
                                        <select id="view-option-sort" class="form-control form-control-sm" [(ngModel)]="ordinamento" (change)="cerca(false)">
                                            <option [value]="0">{{'search.rilevanza' | translate}}</option>
                                            <option [value]="1">{{'search.ordinaPrezzoBasso' | translate}}</option>
                                            <option [value]="2">{{'search.ordinaPrezzoAlto' | translate}}</option>
                                            <option [value]="3">{{'search.ordinaCodiceProdotto' | translate}}</option>
                                            <option [value]="4">{{'search.ordinaCodiceProdotto2' | translate}}</option>
                                            <option [value]="5">{{'search.ordinaDescrizione' | translate}}</option>
                                            <option [value]="6">{{'search.ordinaDescrizione2' | translate}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="isMobile" class="view-options__select" style="float: right; margin-left: auto;">
                                        <select id="view-option-sort" class="form-control form-control-sm" [(ngModel)]="ordinamento" (change)="cerca(false)">
                                            <option [value]="0">{{'search.rilevanza' | translate}}</option>
                                            <option [value]="1">{{'search.ordinaPrezzoBasso' | translate}}</option>
                                            <option [value]="2">{{'search.ordinaPrezzoAlto' | translate}}</option>
                                            <option [value]="3">{{'search.ordinaCodiceProdotto' | translate}}</option>
                                            <option [value]="4">{{'search.ordinaCodiceProdotto2' | translate}}</option>
                                            <option [value]="5">{{'search.ordinaDescrizione' | translate}}</option>
                                            <option [value]="6">{{'search.ordinaDescrizione2' | translate}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="view-options__body view-options__body--filters">
                                    <div class="view-options__label">{{'search.filtriAttivi' | translate}}</div>
                                    <div class="applied-filters col-11">
                                        <ul class="applied-filters__list">
                                            <li class="applied-filters__item" *ngFor="let filter of filtriSelezionati" style="cursor: pointer;">
                                                <a class="applied-filters__button applied-filters__button--filter" (click)="cleanFiltriAttivi(filter.id, filter.idValue)">
                                                    {{filter.quick +' : '+ filter.value}}
                                                    <fa-icon [icon]="faTimes" [styles]="{ height: '11px', width: '8px' }"></fa-icon>
                                                </a>
                                            </li>
                                            <li class="applied-filters__item">
                                                <button type="button" class="applied-filters__button applied-filters__button--clear" (click)="cleanFiltriAttivi()">{{'search.pulisciFiltri' | translate}}</button>
                                            </li>
                                        </ul>
                                    </div>                                   
                                </div>
                            </div>
                            <app-search-product-list (onScroll)="onScroll()" [prodotti]="searchResult.results"></app-search-product-list>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
    </div>
</div>