import { Component, OnInit } from '@angular/core';
import { BaseRoutedComponent } from '../../base-route.component';
import { Product } from 'src/classes/product';
import { Globals } from 'src/classes/global';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilyService } from 'src/services/utility.service';
import { SearchService } from 'src/services/search.service';
import { SeoService } from 'src/services/seo.service';
import { StorageService } from 'src/services/storage.service';
import { TranslatorService } from 'src/services/translator.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { faCircleXmark, faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-download-instructions',
  templateUrl: './download-instructions.component.html',
  styleUrl: './download-instructions.component.scss'
})
export class DownloadInstructionsComponent extends BaseRoutedComponent implements OnInit {
  products: Product[] = [];
  categories: any[] = [];
  categorySelected:string = "";

  faPdf = faFilePdf;
  faCircleXmark = faCircleXmark;
  constructor(public global: Globals,
    protected router: Router,
    protected utilyService: UtilyService,
    protected searchService: SearchService,    
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);
    }

    ngOnInit() {
      this.getProductManuals();
    }
    
    getProductManuals(){
      this.searchService.getProductManuals(this.categorySelected).subscribe((res:any) => {
        this.products = res;

        if(this.products.length > 0){
          const cat:any[] = [];
          this.products.forEach((item:any) => {
            var categoria = { id: item.idCategoria, descrizione: item.descrizioneCategoria };
            cat.push(categoria);
          });
    
          const distinctCategories = cat.filter(
            (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
          );
          
          distinctCategories.sort((a, b) => {
            const nameA = a.descrizione.toUpperCase(); // ignore upper and lowercase
            const nameB = b.descrizione.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
              
          this.categories = distinctCategories;
        }
      });
    }

    setCategory(idCategoria:string){
      this.categorySelected = idCategoria;
      
      this.searchService.getProductManuals(this.categorySelected).subscribe((res:any) => {
        this.products = res;
      });
    }

    close(){
      this.categorySelected = "";
    }

    cleanUp(testo:string):string{
      return this.utilyService.encodeURLParameter(testo);
    }
}