<div *ngIf="product && !promoCarrello" class="product-card">
    <div class="product-card__image">
        <div class="image image--type--product">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" class="image__body">
                <img class="image__tag" [src]="product.foto" onerror="this.src='../../../../assets/images/no-image-found.jpg'" [alt]="product.modello">
            </a>
        </div>
    </div>

    <div class="product-card__info d-flex flex-column">
        <div class="product-card__name" style="padding-top: 5px;">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">
                {{product.modello | uppercase}}
            </a>
        </div>
        <div class="product-card__brandLogo">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" style="margin-right: 10px;">
                <img src="https://static.rms.it/b2b/loghi/brand/{{slugBrand(product.descrizioneBrand)}}_small.png" [alt]="product.descrizioneBrand" class="img-fluid">
            </a>
            <span *ngIf="!isMobile">
                <img *ngFor="let logo of product.loghi | slice:0:3" [src]="logo.image" [title]="logo.nome" class="img-fluid">
            </span>
            <span *ngIf="isMobile">
                <img *ngFor="let logo of product.loghi | filter:'tipo':1" [src]="logo.image" [title]="logo.nome" class="img-fluid">
            </span>
        </div>
        <div class="product-card__meta product-card__meta-info">
            <div>
                {{'codiceProdotto' | translate | uppercase}}
                <strong style="font-size: 16px;">{{product.codiceProdotto}}</strong>
            </div>
            <div *ngIf="product.upc.length > 1" class="pt-1">
                <span *ngIf="product.upc.length < 12">{{'codiceFornitore' | translate | uppercase}}</span>
                <span *ngIf="product.upc.length >= 12">{{'codFornitore' | translate | uppercase}}</span>
                <strong> {{product.upc}}</strong>
            </div> 
            <div *ngIf="product.ean.length > 0" class="pt-1">
                {{'ean' | translate | uppercase}}
                <strong *ngFor="let e of product.ean"> {{e}} </strong>
            </div>
        </div>
        <div *ngIf="!isMobile" class="product-card__features">
            <a class="desc" routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">
                <span *ngIf="product.descrizioneMarketing.length > 0 && product.quickInfo.length > 0" innerHTML="{{product.descrizioneMarketing | html:'description':300}}"></span>
                <span *ngIf="product.descrizioneMarketing.length > 0 && product.quickInfo.length == 0" innerHTML="{{product.descrizioneMarketing | html:'description':300}}"></span>
                <span *ngIf="product.descrizioneMarketing.length <= 0" innerHTML="{{product.descrizioneTecnica | html:'description':300}}"></span>
            </a>
            <a *ngIf="(product.quickInfo.length > 0 && product.descrizioneMarketing.length > 0)" routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let quick of product.quickInfo | slice:0:4">
                        <b>{{quick.quick}}:</b> {{quick.valore}}
                    </li>
                </ul>
            </a>
        </div>
    </div>
    <div class="product-card__footer">
        <div class="product-card__prices w-100">            
            {{product.prezzoPubblico | html:'prezzoPubblico':0}} <em *ngIf="product.prezzoPubblico > 0"> {{'price.ivaInclusa' | translate}}</em>
        </div>
    </div>
</div>

<div class="product-card product-card-promocart" *ngIf="product && promoCarrello">   
    <div class="product-card__image">
        <div class="image image--type--product">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" class="image__body">
                <img class="image__tag" [src]="product.foto" onerror="this.src='../../../../assets/images/no-image-found.jpg'" [alt]="product.modello">
            </a>
        </div>
    </div>
    <div class="product-card__info d-flex flex-column">
        <div class="product-card__name" style="padding-top: 5px;">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}">
                {{product.modello | uppercase}}
            </a>
        </div>
        <div class="product-card__brandLogo">
            <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" style="margin-right: 10px;">
                <img src="https://static.rms.it/b2b/loghi/brand/{{slugBrand(product.descrizioneBrand)}}_small.png" [alt]="product.descrizioneBrand" class="img-fluid">
            </a>
        </div>
        <div class="product-card__meta product-card__meta-info">
            <div>
                {{'codiceProdotto' | translate | uppercase}}
                <strong style="font-size: 16px;">{{product.codiceProdotto}}</strong>
            </div>
        </div>
    </div>
</div>