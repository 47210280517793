import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/classes/global';
import { Product } from 'src/classes/product';
import { TranslatorService } from 'src/services/translator.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-product-sponsored-mobile',
  templateUrl: './product-sponsored-mobile.component.html',
  styleUrls: ['./product-sponsored-mobile.component.scss']
})
export class ProductSponsoredMobileComponent {
  @Input() product!: Product;    
  
  constructor(public global:Globals,
    protected router: Router,
    protected utilyService: UtilyService,
    protected deviceService: DeviceDetectorService,
    protected translatorService: TranslatorService) { 
    }

  get isMobile(){
    return this.deviceService.isMobile();
  }
    
  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }

  slugBrand(testo:string){
    return this.utilyService.encodeBrandDescription(testo);
  }
}