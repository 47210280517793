<div class="container-fluid mainContent">
    <div *ngIf="isMobile" class="block-products-columns__title" style="text-align: center;">
        {{'news' | translate}}
    </div>
    <div *ngIf="!isMobile"  class="section-header">
        <div class="section-header__body">
            <h2 class="section-header__title">{{'news' | translate}}</h2>
            <div class="section-header__spring"></div>
            <div class="section-header__arrows">
                <div class="arrow section-header__arrow section-header__arrow--prev arrow--prev" (click)="slickModal.slickPrev()">
                    <button class="arrow__button" type="button">
                        <fa-icon [icon]="faAngleLeft" [styles]="{height: '16px', width: '16px', color: '#FFF'}"></fa-icon>
                    </button>
                </div>
                <div class="arrow section-header__arrow section-header__arrow--next arrow--next" (click)="slickModal.slickNext()">
                    <button class="arrow__button" type="button">
                        <fa-icon [icon]="faAngleRight" [styles]="{height: '16px', width: '16px', color: '#FFF'}"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="section-header__divider"></div>
        </div>
    </div>
    <div class="slick-wrapper">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let n of news" class="slide">
                <div class="post-card">
                    <div class="post-card__image">
                        <a routerLink="/{{global.culture}}/news/products/{{n.id}}">
                            <img src="https://static.rms.it/news/images/1/{{n.img}}" [alt]="n.titolo" class="img-fluid">
                        </a>
                    </div>
                    <div class="post-card__content">
                        <div class="post-card__title">
                            <h2>
                                <a routerLink="/{{global.culture}}/news/products/{{n.id}}" innerHTML="{{n.titolo | html:'description':250}}"></a>
                            </h2>
                        </div>
                        <div class="post-card__date">
                            {{n.data | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="post-card__excerpt">
                            <div class="typography">
                                {{n.descrizione}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>