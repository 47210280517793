import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatorService } from 'src/services/translator.service';
import { Globals } from 'src/classes/global';

import { NavigationService } from 'src/services/navigation.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  form!: UntypedFormGroup;
  submitted = false;
  hideInvia=false;

  constructor(public global: Globals,
    protected formBuilder: UntypedFormBuilder, 
    protected navigationService: NavigationService,
    protected modalService: NgbModal,
    protected translatorService: TranslatorService) { 

    this.form= this.formBuilder.group({
      nome: ['', Validators.required],
      telefono: ['',  [Validators.pattern('^[0-9\+]{1,}[0-9\-]{3,15}$')]],
      email: ['', [Validators.required, Validators.email]],
      messaggio: ['', Validators.required],
      tipologia:['', Validators.required],
      informazioni:['', Validators.required],
      acconsento:[false]
     });
  }

  get f() {
    return this.form.controls; 
  }

  onSubmit():void {
    this.submitted = true;

    if (this.form.invalid || this.form.get('acconsento')?.value == false) {
        return;
    }

    this.hideInvia=true;

    var dto={
      'Nome': this.form.get('nome')?.value,
      'Telefono': this.form.get('telefono')?.value ?? '',
      'Email': this.form.get('email')?.value,
      'Messaggio': this.form.get('messaggio')?.value,
      "Tipologia": this.form.get('tipologia')?.value,
      "Informazioni": this.form.get('informazioni')?.value
    }

    this.navigationService.sendEmail(dto).subscribe(
      (res: any) => {
        this.hideInvia=false;
        const modalRef = this.modalService.open(ModalConfirmComponent);
        modalRef.componentInstance.description = this.translatorService.getLocalization('confirmOk');
        modalRef.componentInstance.out.subscribe((confirm:any) => {
          this.modalService.dismissAll();   
        });
      },
    (error: any) => {
      this.hideInvia=false;
      const modalRef = this.modalService.open(ModalConfirmComponent);
      modalRef.componentInstance.description = this.translatorService.getLocalization('confirmKo');
      modalRef.componentInstance.out.subscribe((confirm:any) => {
        this.modalService.dismissAll();
      });
    });
  }
}