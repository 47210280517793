import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { NavigationService } from 'src/services/navigation.service';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {  
  products!:any;
  slideConfig!:any;
  
  constructor(public global: Globals,
    protected utilyService: UtilyService,
    protected navigationService: NavigationService,    
    protected deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.navigationService.getProductTop()
        .subscribe((res:any)=>{
          this.products = res;
        });

    this.slideConfig = {
      "slidesToShow": this.deviceService.isMobile() ? 1: 4,
      "slidesToScroll":  this.deviceService.isMobile() ? 1: 4,
      "dots": true,
      "infinite": true,
      "arrows":false,
      "autoplay":true,
    };
  }

  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }
}
