import { Injectable } from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, Data } from '@angular/router';
import { PageSeoInfo } from 'src/classes/page-seo-info';
import { TranslatorService } from './translator.service';
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class SeoService { 
    constructor(
        protected titleService: Title, 
        protected metaService: Meta,
        private router: Router,
        private translatorService: TranslatorService) { }


    setTitle(title: string){
        this.titleService.setTitle(title);
    }

    updateTag(name: string, content: string){
        this.metaService.updateTag({name: name, content: content});
    }

    setSeoData() : void {
        this.router.events.pipe(filter((event : any) => event instanceof NavigationEnd))
            .subscribe(() => {
                var root = this.router.routerState.snapshot.root;
                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data) {
                        this.setinfoPage(root.data);
                        return;
                    } else {
                        return;
                    }
                }
            });
    }
    
    private setinfoPage(data : Data) : void {
        if (data && data["seoNode"]) {
            var pageinfos = this.translatorService.getLocalizationData<Array<PageSeoInfo>>(data["seoNode"]);
            if (pageinfos) {
              let pageinfo = pageinfos.find(info => info.component == data["pageInfo"]);
              if(pageinfo){
                this.titleService.setTitle(pageinfo.title);
              
                for (let tg in pageinfo.metatags ) {
                  var name = tg;
                  var content = pageinfo.metatags[tg];
    
                  this.metaService.updateTag({name: name, content: content});
                }
              }              
            }
          }
    }
}