import { Component, OnInit } from '@angular/core';
import { BaseRoutedComponent } from '../../base-route.component';
import { Globals } from 'src/classes/global';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/services/navigation.service';
import { SeoService } from 'src/services/seo.service';
import { StorageService } from 'src/services/storage.service';
import { TranslatorService } from 'src/services/translator.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-download-catalog',
  templateUrl: './download-catalog.component.html',
  styleUrl: './download-catalog.component.scss'
})
export class DownloadCatalogComponent extends BaseRoutedComponent implements OnInit {
  catalog: any[] = [];
  constructor(public global: Globals,
    protected router: Router,
    protected navigationService: NavigationService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);
    }

    ngOnInit() {
      this.getCatalog();
    }

    getCatalog(){
      this.navigationService.getCatalogs().subscribe((res:any) => {
        this.catalog = res;
      });
    }
}