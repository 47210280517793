import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'html'
})
export class HtmlPipe implements PipeTransform {
 
 public transform(value: any, type: string, limit: number) {
    switch (type) {
        case 'dispo': return value > limit ? "+100" : value;
        case 'prezzoPubblico': return value > limit ? value.toFixed(2) + " €" : "N.D.";
        case 'description': return value.length > limit ? value.substring(0, limit)+'...' : value;
        default: throw new Error(`Invalid transform type specified: ${type}`);
    }
  }
}