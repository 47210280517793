import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Globals } from 'src/classes/global';

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss']
})
export class GoogleAutocompleteComponent implements OnChanges {
  @Input() country: string = "IT";
  @Output() out = new EventEmitter();

  options:any = {
    fileds: ["address_component", "formatted_address" ]
  };

  @ViewChild("placesRef") placesRef!: NgxGpAutocompleteDirective;
  constructor(public global:Globals) {
    this.options.componentRestrictions = { country: this.country };
  }

  ngOnChanges(): void {
    if(this.placesRef){
      this.options.componentRestrictions = { country: this.country };
      this.placesRef.options = this.options;
      this.placesRef.reset();
    }
  }

  AddressChange(googlePlace: any) {
    var address = this.getAddressComponent(googlePlace.address_components, "route", true);
    address += " " + this.getAddressComponent(googlePlace.address_components, "street_number", false);

    var city = this.getAddressComponent(googlePlace.address_components, "administrative_area_level_3", true);
    if(city == ""){
      city = this.getAddressComponent(googlePlace.address_components, "locality", true);
    }

    var province = this.getAddressComponent(googlePlace.address_components, "administrative_area_level_2", false);

    var cap = this.getAddressComponent(googlePlace.address_components, "postal_code", false);

    var location = googlePlace.geometry.location;

    var addressSelected = { address: address, city: city, cap: cap, province: province, location: location };
    this.out.emit(addressSelected);
  }

  getAddressComponent(addressComponents:any, type:string, dimension:boolean): string {
    var result = "";
    addressComponents.forEach((element:any) => {
      element.types.forEach((t:any) => {
        if(t == type) {
          if(dimension){
            result = element.long_name;
          }else{
            result = element.short_name;
          }
        }
      });        
    });
    return result;
  }

}
