import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faCheck, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Globals } from 'src/classes/global';
import { SeoService } from 'src/services/seo.service';
import { TranslatorService } from 'src/services/translator.service';
import { BaseRoutedComponent } from '../../base-route.component';
import { SearchService } from 'src/services/search.service';
import { LoadingService } from 'src/services/loading.service';
import { FiltroQuick, FiltroQuickFlat, QuickValue, SearchResult } from 'src/classes/search-result';
import { KeyValue, KeyValueSlide } from 'src/classes/key-value';
import { UtilyService } from 'src/services/utility.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ChangeContext, LabelType, Options } from 'ngx-slider-v2';
import { StorageService } from 'src/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.component.html',
  styleUrl: './search-products.component.scss'
})
export class SearchProductsComponent extends BaseRoutedComponent implements OnDestroy {
  categoria!: KeyValue;
  comparto!: KeyValue;
  brand !:KeyValue;
  quickSelected !:FiltroQuickFlat;
  searchResult: SearchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
  minValue: number = 0;
  maxValue: number = 9999;
  page: number = 0;
  rowPage: number = 20;
  ordinamento: number = 0;
  breadCrumb: Array<any>= [];
  controlSearch!: number;
  @Output() dataEmitter = new EventEmitter<boolean>();

  options: Options = {
    floor: 0,
    ceil: 9999,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '€ ' + value;
        case LabelType.High:
          return '€ ' + value;
        default:
          return '€ ' + value;
      }
    }
  };

  faCheck = faCheck;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  filtriSelezionati: Array<FiltroQuickFlat> = [];
  filtriSlideSelezionati: Array<KeyValueSlide> = [];
  filtriQuick: Array<FiltroQuick> = [];
  togglePrice: boolean = false;
  limitValueToSlide:number = 50;
  disableScrolling:boolean = false;  
  showFiltriQuick!:boolean;

  navigationSubscription: Subscription;
  constructor(
    public global: Globals,
    protected router: Router,
    protected utilityService: UtilyService,
    protected searchService: SearchService,
    protected loadingService: LoadingService,
    protected deviceService: DeviceDetectorService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
    super(route, seoService, storageService, translatorService, cookieService);

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.searchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
          this.categoria = {key:"", value:""} as KeyValue;   
          this.comparto = {key:"", value:""} as KeyValue;         
          this.brand = {key:"", value:""} as KeyValue;
          this.breadCrumb = [];
          this.disableScrolling = false;
          this.filtriSlideSelezionati = [];
          this.filtriSelezionati = [];
          this.ordinamento = 0;
          this.minValue = 0;
          this.maxValue = 9999;
          this.options = {
            floor: this.minValue,
            ceil: this.maxValue
          };

          let _idParam = params['id'];
          let _descrizioneParam = utilityService.decodeURLParameter(params['descrizione']);
          let _pArr;
          let _dArr;

          switch (_idParam[0]) {
            case 'B':
              //ricerca per BRAND-----------------------------------------------------------------------------
              this.showFiltriQuick = false;
              _pArr = _idParam.slice(2);
              _dArr =  _descrizioneParam;

              this.brand = { key: _pArr, value:_dArr };          
              break;
            case 'C':
              //ricerca per CATEGORIA-----------------------------------------------------------------------------
              this.showFiltriQuick = true;
              _pArr = _idParam.slice(1).split("-");
              _dArr =  _descrizioneParam.split("-");

              this.comparto = { key:_pArr[0], value:_dArr[0] };
              this.categoria = { key:_pArr[1], value:_dArr[1] };

              this.breadCrumb.push({ url:'/category-list/K'+_pArr[0]+'/'+this.cleanUp(_dArr[0]), label:_dArr[0] });              
              break;
            case 'X':
               //ricerca per CATEGORIA + BRAND--------------------------------------------------------------------
               this.showFiltriQuick = true;
               _pArr = _idParam.slice(1).split("-");
               _dArr =  _descrizioneParam.split("-");

               this.categoria = { key:_pArr[0], value:_dArr[0] };
               this.brand = { key: _pArr[1], value:_dArr[1] };  

               this.breadCrumb.push({ url:'/category-list/B'+_pArr[1]+'/'+this.cleanUp(_dArr[1]), label:_dArr[1] });
              break;
            case 'Y':
               //ricerca per COMPARTO + BRAND---------------------------------------------------------------------
               this.showFiltriQuick = false;
              _pArr = _idParam.slice(1).split("-");
              _dArr =  _descrizioneParam.split("-");

              this.comparto = { key:_pArr[0], value:_dArr[0] };
              this.brand = { key:_pArr[1], value:_dArr[1] };
              break;
            case 'Q':
                //ricerca per QUICK---------------------------------------------------------------------
                this.showFiltriQuick = false;
               _pArr = _idParam.slice(1).split("-");
               _dArr =  _descrizioneParam.split("-");
 
               this.quickSelected = { id:_pArr[0], idValue:_pArr[1], quick: _dArr[0], value: "" };
               break;
          }
        });

        this.initialiseInvites();
      }
    });
  }

  get isMobile(){
    return this.deviceService.isMobile() ;
  }

  get isDesktop(){
    return this.deviceService.isDesktop() ;
  }

  initialiseInvites() {
    this.setSeo();
    this.cerca(false);        
  }

  setSeo(){
    if(this.quickSelected){
      let title = "EVOK Performance | " + this.quickSelected.quick;
      this.seoService.setTitle(title);
    }
    else if (this.categoria.value){
      let title = "EVOK Performance | " + this.categoria.value;
      this.seoService.setTitle(title);
    }    
  }

  dynamicOpenFilterTab(){
    this.filtriQuick.forEach((item) => {
      item.isOpen = false;
      if(item.id == 'CATEGORIE' || item.id == 'BRAND' || item.id == 'PROMO'){
        item.isOpen = true;
      }
      else 
      {
        if(this.filtriSelezionati.length > 0 || this.filtriSlideSelezionati.length > 0){
          item.isOpen = true;
        }
      }
    });
  }

  filtersMobile() {
    const body = $('body');
    const sidebar = $('.sidebar');
    const offcanvas = sidebar.hasClass('sidebar--offcanvas--mobile') ? 'mobile' : 'always';
    const media = matchMedia('(max-width: 1280px)');

    if (sidebar.length) {
        const open = function () {
            if (offcanvas === 'mobile' && !media.matches) {
                return;
            }

            const bodyWidth = body.width();
            body.css('overflow', 'hidden');
            body.css('paddingRight', (body.width() - bodyWidth) + 'px');

            sidebar.addClass('sidebar--open');
        };
        const close = function () {
            body.css('overflow', 'auto');
            body.css('paddingRight', '');

            sidebar.removeClass('sidebar--open');
        };
        const onMediaChange = function () {
            if (offcanvas === 'mobile') {
                if (!media.matches && sidebar.hasClass('sidebar--open')) {
                    close();
                }
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onMediaChange);
        } else {
            media.addListener(onMediaChange);
        }

        $('.filters-button').on('click', function () {
            open();
        });
        $('.sidebar__backdrop, .sidebar__close').on('click', function () {
            close();
        });
        $('.btn-close-sidebar').on('click', function () {
            close();
        });
    }
  }

  cerca(filtraPrezzi: boolean) {
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    if(this.quickSelected){
      quickSel.push({ key: this.quickSelected.id, value: this.quickSelected.idValue } as KeyValue)
    }
    this.page = 0;
    this.loadingService.onShow();
    this.searchService.getSearchProduct(this.categoria.key, this.brand.key, this.comparto.key, this.rowPage, this.page, this.showFiltriQuick, quickSel, this.filtriSlideSelezionati,
                                    filtraPrezzi ? this.minValue : null, filtraPrezzi ? this.maxValue : null, this.ordinamento)
      .subscribe((res: any) => {
        this.disableScrolling = false;
        this.searchResult = res;
  
        if(this.quickSelected){
          this.searchResult.titoloRicerca = this.quickSelected.quick;
        }

        this.loadingService.onDelayHide(500);
        this.dataEmitter.emit(res.results.length == 0);

        setTimeout(() => {
          this.filtersMobile();
        }, 1000);
      });

    this.searchService.getSearchQuicks(this.categoria.key, this.brand.key, this.comparto.key, this.rowPage, this.page, this.showFiltriQuick, quickSel, this.filtriSlideSelezionati,
                                    filtraPrezzi ? this.minValue : null, filtraPrezzi ? this.maxValue : null, this.ordinamento)
      .subscribe((res: any) => {
        this.disableScrolling = false;

        var ricalcoloFiltri: Array<FiltroQuick> = [];
        res.quicks.forEach((item:FiltroQuick)=> {
          var filter = this.filtriSelezionati.filter(obj => obj.id == item.id);
          var filterSlide = this.filtriSlideSelezionati.filter(obj => obj.key == item.id);
          if(filter.length > 0){
            var lastFilter = this.filtriQuick.filter(obj => obj.id == filter[0].id)[0];
            ricalcoloFiltri.push(lastFilter);
          }

          if(filterSlide.length > 0){
            var lastFilter = this.filtriQuick.filter(obj => obj.id == filterSlide[0].key)[0];
            ricalcoloFiltri.push(lastFilter);
          }
          
          if(filter.length == 0 && filterSlide.length == 0){
            if(item.idTipo == 2 && item.valori.length > this.limitValueToSlide) {
              var split = item.valori[0].value.split(' ');
              if(split.length > 1){
                item.unitaDiMisura = split[1];
              }
              item.minValue = Math.min.apply(Math, item.valori.map(function(o) { return +o.value.split(' ')[0]; }));
              item.minValueSelected = item.minValue;
  
              item.maxValue = Math.max.apply(Math, item.valori.map(function(o) { return +o.value.split(' ')[0]; }));
              item.maxValueSelected = item.maxValue;
            }            
            ricalcoloFiltri.push(item);
          }
        });

        this.filtriQuick = ricalcoloFiltri;       
        this.setInactive();

        res.quicks.forEach((f1: FiltroQuick) => {
          f1.valori.forEach((v1: QuickValue) => {
            this.filtriQuick.find((x) => {
              if (x.id == f1.id) {
                x.valori.find(k => {
                  if (k.idValue == v1.idValue) {
                    k.inactive = false;
                  }
                });
              }
            });
          });
        });

        this.dynamicOpenFilterTab();

        if (!filtraPrezzi) {
          this.minValue = res.minPrice;
          this.maxValue = res.maxPrice;
          this.options = {
            floor: res.minPrice,
            ceil: res.maxPrice
          };
        }
                
        setTimeout(() => {
          this.filtersMobile();
        }, 1000);
      });
  }  
  
  cleanUp(testo: string): string {
    return this.utilityService.encodeURLParameter(testo);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  addToFiltri(id: string, idQuick: string, voceFiltro: QuickValue) {
    const elem = { id: id, quick: idQuick, idValue: voceFiltro.idValue, value: voceFiltro.value } as FiltroQuickFlat;

    if (voceFiltro.checked) {
      this.filtriSelezionati.push(elem);
    }
    else {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == elem.id && obj.idValue == elem.idValue));
    }

    this.cerca(true);
  }

  cleanFiltriAttivi(id: string = '', idValue: string = ''): void {
    if (id != '' && idValue != '') {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == id && obj.idValue == idValue));

      this.filtriQuick.find((x) => {
        if (x.id == id) {
          x.valori.find(k => {
            if (k.idValue == idValue) {
              k.checked = false;
            }
          });
        }
      });

      this.cerca(true);
    }
    else {
      this.filtriSelezionati = [];
      this.filtriSlideSelezionati = [];
      this.cerca(false);
    }
  }

  setInactive(idEccezione:string| null = null) {
    this.filtriQuick.forEach(element => {
      if(element.id != null && element.id != idEccezione){
        element.valori.forEach(v => {
          v.inactive = true;
        });
      }
    });
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.cerca(true);
  }

  onUserChangeEndFilter(changeContext: ChangeContext, filter: FiltroQuick): void {    
    var filterExsist = false;
    this.filtriSlideSelezionati.forEach(f => {
      if(f.key == filter.id){
        filterExsist = true;
        if(changeContext.pointerType == 0){
          f.minValue = changeContext.value;
        }
        if(changeContext.pointerType == 1){
          f.maxValue = changeContext.value;
        }
      }
    });

    if(!filterExsist){
      var filterSlide = { key: filter.id, minValue: filter.minValue, maxValue: filter.maxValue };
      if(changeContext.pointerType == 0){
        filterSlide.minValue = changeContext.value;
      }
      if(changeContext.pointerType == 1){
        filterSlide.maxValue = changeContext.value;
      }

      this.filtriSlideSelezionati.push(filterSlide);
    }
    this.cerca(true);
  }

  onScroll() {
    if(!this.disableScrolling){
      this.appendItem(true);
    }
  }

  appendItem(filtraPrezzi: boolean) {
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    if(this.quickSelected){
      quickSel.push({ key: this.quickSelected.id, value: this.quickSelected.idValue } as KeyValue)
    }
    this.page += 1;
    this.disableScrolling= true;
    this.searchService.getSearchProduct(this.categoria.key, this.brand.key, this.comparto.key, this.rowPage, this.page, this.showFiltriQuick, quickSel, this.filtriSlideSelezionati, filtraPrezzi ? this.minValue : null, filtraPrezzi ? this.maxValue : null, this.ordinamento)
      .subscribe((res: any) => {
        this.disableScrolling = res.results.length != 0 ? false : true; //se torna risultati sblocco l'infinity scroll 
        
        res.results.forEach((item:any) => {
          this.searchResult.results.push(item);
        });
      });
  }
}