import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-foto',
  template: `
  <div class="modal-body" style="display: flex; align-items: center; justify-content: center;">
    <img src="{{foto}}" width="70%">
  </div>
  `
})

export class ModalZoomComponent {
  @Input() foto!: string;
  constructor(public modal: NgbActiveModal) { }
}