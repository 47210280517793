<div class="container-fluid main-content">
    <section id="pageError" class="500">
        <div class="row row-flex">
            <div class="col-12 col-md-6 offset-md-3 ">
                <p class="text-center">
                    <strong [innerHTML]="'pageError' | translate | uppercase"></strong>
                </p>
                <img src="../../../../../assets/images/errore-500.svg" alt="Error 500" class="img-fluid img-error">
                <p class="text-center">
                    {{'error500' | translate}}
                </p>
            </div>
        </div>
    </section>
    <div class="block-space block-space--layout--before-footer"></div>
</div>