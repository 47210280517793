import { Component, Input, Output } from '@angular/core';
import { Product } from 'src/classes/product';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-product-list',
  templateUrl: './search-product-list.component.html',
  styleUrl: './search-product-list.component.scss'
})
export class SearchProductListComponent {
  throttle = 200;
  scrollDistance = 5;
  scrollUpDistance = 2;
  @Input()  prodotti! :Array<Product>;
  @Output("onScroll") onScroll: EventEmitter<any> = new EventEmitter();

  scrollEvent(){
    this.onScroll.emit();
  }
}
