<div class="site__body">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
        <div class="container-fluid mainContent content-search">
            <div class="block-header__body">                
                <h1 class="block-header__title">PRIVACY POLICY</h1>
            </div>
        </div>
    </div>
    <div class="block-split block-split--has-sidebar">
        <div class="container-fluid mainContent content-search">
            <div class="post__body typography history">
                <div class="iub_content legal_pp">
                    <h3 id="navigators">Information on the Processing of Personal Data pursuant to Articles 13-14 of EU Regulation 2016/679</h3><br>
                    <h4>Data Subjects: Website Visitors</h4>
                    
                    <p>
                        RMS S.P.A., as the Data Controller of your personal data, in accordance with and for the purposes of EU Regulation 2016/679, hereinafter 'GDPR,' hereby informs you that the aforementioned regulation provides protection for data subjects regarding the processing of personal data, and that such processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your privacy and rights.<br><br>
                        Your personal data will be processed in accordance with the legislative provisions of the aforementioned regulation and the confidentiality obligations therein.<br><br>
                        Purposes and legal basis of the processing: In particular, your data will be processed for the following purposes related to the fulfillment of legal or contractual obligations:
                    </p>
                    
                    <ul>
                        <li>Technical and functional access to the website; no data is retained after closing the browser;</li>
                        <li>Advanced navigation purposes or personalized content management;</li>
                        <li>Statistical and navigational analysis purposes.</li>
                    </ul>
                    
                    <p>Your data will also be used for the following purposes related to the execution of contractual or pre-contractual measures:</p>
                    <ul>
                        <li>
                            Purposes related to the execution of a contract to which you are a party or the execution of pre-contractual measures taken at your request (e.g., contact request via the Contact form, etc.).
                        </li>
                    </ul>
                    <p>
                        Processing methods: Your personal data may be processed in the following ways:
                    </p>
                    <ul>
                        <li>Using electronic computers with the use of software systems operated by third parties;</li>
                        <li>Using electronic computers with the use of software systems managed or programmed directly;</li>
                        <li>Temporary processing in anonymous form.</li>
                    </ul>
                    <p>
                        Each processing is carried out in compliance with the methods specified in Articles 6 and 32 of the GDPR and through the adoption of suitable security measures as required.
                        Your data will be processed only by personnel expressly authorized by the Data Controller, in particular, by the following categories of staff:
                    </p>
                    <ul>
                        <li>Programmers and Analysts;</li>
                        <li>Marketing Office.</li>
                    </ul>
                    <p>
                        Communication: Your data may be communicated to external parties for the proper management of the relationship, in particular to the following categories of Recipients, including all duly appointed Data Processors:
                    </p>
                    <ul>
                        <li>Google Analytics: Advertising Targeting, Analytics/Measurement, Optimization.</li>
                    </ul>
                    <p>
                        Dissemination: Your personal data will not be disseminated in any way.
                        Your personal data may also be transferred, limited to the purposes mentioned above, to the following countries:
                    </p>
                    <ul>
                        <li>EU countries;</li>
                        <li>United States.</li>
                    </ul>
                    <p>
                        Retention Period: We inform you that, in accordance with the principles of lawfulness, purpose limitation, and data minimization, as provided for in Article 5 of the GDPR, the retention period for your personal data is as follows:
                    </p>
                    <ul>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed for the execution and fulfillment of contractual purposes;
                        </li>
                        <li>Established for a period not exceeding the provision of the services offered;</li>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed and in compliance with the mandatory time limits prescribed by law.
                        </li>
                    </ul>
                    <p>
                        Cookie Management: In case you have doubts or concerns about the use of cookies, you can always intervene to prevent their setting and reading, for example, by modifying the privacy settings within your browser to block certain types of cookies.<br><br>
                        Since each browser - and often different versions of the same browser - differs significantly from others, if you prefer to act independently through your browser preferences, you can find detailed information on the necessary procedure in your browser's guide. For an overview of the actions for the most common browsers, you can visit www.cookiepedia.co.uk.<br><br>
                        Advertising companies also allow you to opt out of receiving targeted ads, if you wish. This does not prevent the setting of cookies but stops the use and collection of some data by these companies.<br><br>
                        For more information and the possibility to opt out, visit www.youronlinechoices.eu/.<br><br>
                        Data Controller: The Data Controller, as defined by law, is RMS S.P.A. (Via Macalle 156, 20831 SEREGNO(MB); email: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; phone: 036227301; VAT: 00864010962), in the person of its current legal representative.<br><br>
                        You have the right to obtain from the data controller the erasure (right to be forgotten), limitation, updating, rectification, portability, and objection to the processing of personal data concerning you, as well as, in general, exercise all the rights provided for in Articles 15, 16, 17, 18, 19, 20, 21, 22 of the GDPR.
                    </p>
                    <p>
                        You can also view the updated version of this information at any time by visiting the following website:
                        <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>

                    <br><h4 id="ecommerce">Data Subjects: E-commerce Users</h4>

                    <p>
                        RMS S.P.A., as the Data Controller of your personal data, in accordance with and for the purposes of EU Regulation 2016/679, hereinafter 'GDPR,' hereby informs you that the aforementioned regulation provides protection for data subjects regarding the processing of personal data, and that such processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your privacy and rights.<br><br>
                        Your personal data will be processed in accordance with the legislative provisions of the aforementioned regulation and the confidentiality obligations therein.<br><br>
                        Purposes and legal basis of the processing: In particular, your data will be processed for the following purposes related to the fulfillment of legal or contractual obligations:
                    </p>

                    <ul>
                        <li>Online sales.</li>
                    </ul>
                    <p>Processing methods: Your personal data may be processed in the following way:</p>
                    <ul>
                        <li>Processing by electronic computers.</li>
                    </ul>
                    <p>
                        Each processing is carried out in compliance with the methods specified in Articles 6 and 32 of the GDPR and through the adoption of suitable security measures as required.
                        Your data will be processed only by personnel expressly authorized by the Data Controller, in particular, by the following categories of staff:
                    </p>
                    <ul>
                        <li>Programmers and Analysts;</li>
                        <li>Marketing Office.</li>
                    </ul>
                    <p>
                        Communication: Your data may be communicated to external parties for the proper management of the relationship, in particular to the following categories of Recipients, including all duly appointed Data Processors:
                    </p>
                    <ul>
                        <li>Carriers, Transporters, Delivery Services, Postal Services, Logistics Companies.</li>
                    </ul>
                    <p>
                        Dissemination: Your personal data will not be disseminated in any way.<br><br>
                        Retention Period: We inform you that, in accordance with the principles of lawfulness, purpose limitation, and data minimization, as provided for in Article 5 of the GDPR, the retention period for your personal data is as follows:
                    </p>
                    <ul>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed for the execution and fulfillment of contractual purposes;
                        </li>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed and in compliance with the mandatory time limits prescribed by law.
                        </li>
                    </ul>
                    <p>
                        Data Controller: The Data Controller, as defined by law, is RMS S.P.A. (Via Macalle 156, 20831 SEREGNO(MB); email: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; phone: 036227301; VAT: 00864010962), in the person of its current legal representative.<br><br>
                        You have the right to obtain from the data controller the erasure (right to be forgotten), limitation, updating, rectification, portability, and objection to the processing of personal data concerning you, as well as, in general, exercise all the rights provided for in Articles 15, 16, 17, 18, 19, 20, 21, 22 of the GDPR.
                    </p>

                    <br><h4 id="mailinglist">Data Subjects: Mailing List Users</h4>

                    <p>
                        RMS S.P.A., as the Data Controller of your personal data, in accordance with and for the purposes of EU Regulation 2016/679, hereinafter 'GDPR,' hereby informs you that the aforementioned regulation provides protection for data subjects regarding the processing of personal data, and that such processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your privacy and rights.<br><br>
                        Your personal data will be processed in accordance with the legislative provisions of the aforementioned regulation and the confidentiality obligations therein.<br><br>
                        Purposes and legal basis of the processing: In particular, your data will be processed for the following purposes related to the fulfillment of legal or contractual obligations:
                    </p>

                    <ul>
                        <li>Sending commercial information via email or SMS.</li>
                    </ul>
                    <p>The provision of data for the above-mentioned purposes is optional for you, and your possible refusal to process it will not affect the continuation of the relationship or the appropriateness of the processing itself.<br><br>
                        Processing methods: Your personal data may be processed in the following way:
                    </p>
                    <ul>
                        <li>Processing by electronic computers.</li>
                    </ul>
                    <p>
                        Each processing is carried out in compliance with the methods specified in Articles 6 and 32 of the GDPR and through the adoption of suitable security measures as required.
                        Your data will be processed only by personnel expressly authorized by the Data Controller, in particular, by the following categories of staff:
                    </p>
                    <ul>
                        <li>Programmers and Analysts;</li>
                        <li>Marketing Office.</li>
                    </ul>
                    <p>
                        Communication: Your data may be communicated to external parties for the proper management of the relationship, in particular to the following categories of Recipients, including all duly appointed Data Processors:
                    </p>
                    <ul>
                        <li>Google Analytics: Targeted Advertising, Analytics/Measurement, Optimization</li>
                    </ul>
                    <p>
                        Dissemination: Your personal data will not be disseminated in any way.<br><br>
                        Transfer of your personal data may also occur, limited to the purposes mentioned above, to the following countries:
                    </p>
                    <ul>
                        <li>EU countries;</li>
                        <li>United States.</li>
                    </ul>
                    <p>
                        Retention Period: We inform you that, in accordance with the principles of lawfulness, purpose limitation, and data minimization, as provided for in Article 5 of the GDPR, the retention period for your personal data is as follows:
                    </p>
                    <ul>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed for the execution and fulfillment of contractual purposes;
                        </li>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed and in compliance with the mandatory time limits prescribed by law.
                        </li>
                    </ul>
                    <p>
                        Cookie Management: In case you have doubts or concerns regarding the use of cookies, you can always intervene to prevent their setting and reading, for example, by modifying the privacy settings within your browser to block certain types.<br><br>
                        Since each browser - and often different versions of the same browser - can vary significantly from one another, if you prefer to act independently through your browser preferences, you can find detailed information on the necessary procedure in your browser's guide. For an overview of the action methods for the most common browsers, you can visit the address www.cookiepedia.co.uk.<br><br>
                        Advertising companies also allow you to opt-out of receiving targeted ads if you wish. This does not prevent the setting of cookies but interrupts the use and collection of some data by such companies.<br><br>
                        For more information and the option to opt-out, visit the address www.youronlinechoices.eu/.<br><br>
                        Data Controller: The Data Controller, as defined by law, is RMS S.P.A. (Via Macalle 156, 20831 SEREGNO(MB); email: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; phone: 036227301; VAT: 00864010962), in the person of its current legal representative.<br><br>
                        You have the right to obtain from the data controller the erasure (right to be forgotten), limitation, updating, rectification, portability, and objection to the processing of personal data concerning you, as well as, in general, exercise all the rights provided for in Articles 15, 16, 17, 18, 19, 20, 21, 22 of the GDPR.
                    </p>
                    <p>
                        You can also view the updated version of this notice at any time by visiting the website
                        <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>

                    <br><h4 id="lavoro">Data Subjects: Candidates to be considered for establishing an employment relationship</h4>

                    <p>
                        RMS S.P.A., as the Data Controller of your personal data, in accordance with and for the purposes of EU Regulation 2016/679, hereinafter 'GDPR,' hereby informs you that the aforementioned regulation provides protection for data subjects regarding the processing of personal data, and that such processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your privacy and rights.<br><br>
                        Your personal data will be processed in accordance with the legislative provisions of the aforementioned regulation and the confidentiality obligations therein.<br><br>
                        Purposes and legal basis of the processing: In particular, your data will be processed for the following purposes related to the fulfillment of legal or contractual obligations:
                    </p>

                    <ul>
                        <li>Personnel selection for the establishment of an employment relationship</li>
                    </ul>

                    <p>The provision of data for the above-mentioned purposes is optional for you, and your possible refusal to process it will not affect the continuation of the relationship or the appropriateness of the processing itself.<br><br>
                        Processing methods: Your personal data may be processed in the following way:
                    </p>
                    <ul>
                        <li>
                            Processing by electronic computers;
                        </li>
                        <li>
                            Manual processing using paper archives.
                        </li>
                    </ul>
                    <p>
                        Each processing is carried out in compliance with the methods specified in Articles 6 and 32 of the GDPR and through the adoption of suitable security measures as required.<br><br>
                        Your data will be processed only by personnel expressly authorized by the Data Controller, in particular, by the following categories of staff:
                    </p>
                    <ul>
                        <li>Within the scope of personnel management.</li>
                    </ul>
                    <p>
                        Communication: Your data may be communicated to external parties for the proper management of the relationship, in particular to the following categories of Recipients, including all duly appointed Data Processors:
                    </p>
                    <ul>
                        <li>
                            Consultants and freelance professionals, also in an associated form.
                        </li>
                    </ul>
                    <p>
                        Dissemination: Your personal data will not be disseminated in any way.<br><br>
                        Transfer of your personal data may also occur, limited to the purposes mentioned above, to the following countries:
                    </p>
                    <ul>
                        <li>EU countries;</li>
                        <li>United States.</li>
                    </ul>
                    <p>
                        Retention Period: We inform you that, in accordance with the principles of lawfulness, purpose limitation, and data minimization, as provided for in Article 5 of the GDPR, the retention period for your personal data is as follows:
                    </p>
                    <ul>
                        <li>Established for a period not exceeding the completion of the services provided;</li>
                        <li>
                            Established for a period not exceeding the time necessary for the purposes for which they are collected and processed and in compliance with the mandatory time limits prescribed by law.
                        </li>
                    </ul>
                    <p>
                        Data Controller: The Data Controller, as defined by law, is RMS S.P.A. (Via Macalle 156, 20831 SEREGNO(MB); email: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; phone: 036227301; VAT: 00864010962), in the person of its current legal representative.<br><br>
                        You have the right to obtain from the data controller the erasure (right to be forgotten), limitation, updating, rectification, portability, and objection to the processing of personal data concerning you, as well as, in general, exercise all the rights provided for in Articles 15, 16, 17, 18, 19, 20, 21, 22 of the GDPR.
                    </p>

                    <br><h4>EU Regulation 2016/679: Articles 15, 16, 17, 18, 19, 20, 21, 22 - Data Subject Rights</h4>

                    <p>
                        1. The data subject has the right to obtain confirmation of the existence or absence of personal data concerning them, even if not yet recorded, their communication in intelligible form, and the possibility of lodging a complaint with the supervisory authority.
                    </p>
                    <p>2. The data subject has the right to obtain information:</p>
                    <ul>
                        <li>a. the origin of personal data;</li>
                        <li>b. the purposes and methods of processing;</li>
                        <li>c. the logic applied in case of processing carried out with the aid of electronic tools;</li>
                        <li>d. the identification details of the controller, the processors, and the designated representative pursuant to Article 5(2);</li>
                        <li>
                            e. the subjects or categories of subjects to whom personal data may be communicated or who may become aware of it as designated representative in the territory of the State, processors, or persons in charge.
                        </li>
                    </ul>
                    <p>3. The data subject has the right to obtain:</p>
                    <ul>
                        <li>a. updating, rectification, or, when interested, integration of data;</li>
                        <li>
                            b. the deletion, transformation into anonymous form, or blocking of data processed unlawfully, including data whose retention is unnecessary for the purposes for which the data was collected or subsequently processed;
                        </li>
                        <li>
                            c. certification that the operations referred to in letters a) and b) have been brought to the attention, including their content, of those to whom the data have been communicated or disclosed, except in the case where this proves impossible or involves a disproportionate use of means compared to the right being protected;
                        </li>
                        <li>d. data portability.
                    </li></ul>
                    <p>4. The data subject has the right to object, in whole or in part:</p>
                    <ul>
                        <li>a. on legitimate grounds, to the processing of personal data concerning them, even if pertinent to the purpose of the collection;</li><li>
                            b. to the processing of personal data concerning them for the purpose of sending advertising or direct sales material or for the performance of market research or commercial communication.
                        </li>
                    </ul>

                    <br>
                    <p>
                        Last modified: November 20, 2018
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>