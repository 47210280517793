import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-confirm',
  template: `
  <div class="modal-header">    
  </div>
  <div class="modal-body">
    <br>
    <div style="text-align:center; font-weight:900">
      <span [innerHTML]="description"></span>
    <br><br>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">OK </button>
  </div>
  `
})
export class ModalConfirmComponent {
  @Input() description!: string;  
  @Output() out = new EventEmitter();

  constructor(public modal: NgbActiveModal,
    protected translateService: TranslateService) {}
    
    confirm(param:any){    
      this.out.emit(param);
    }

}
