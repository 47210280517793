import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from 'src/classes/global';
import { SeoService } from 'src/services/seo.service';
import { TranslatorService } from 'src/services/translator.service';
import { BaseRoutedComponent } from '../../base-route.component';
import { FiltroQuick, FiltroQuickFlat, QuickValue, SearchResult } from 'src/classes/search-result';
import { UtilyService } from 'src/services/utility.service';
import { KeyValue } from 'src/classes/key-value';
import { SearchService } from 'src/services/search.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingService } from 'src/services/loading.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ChangeContext, LabelType, Options } from 'ngx-slider-v2';
import { StorageService } from 'src/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-search-fulltext',
  templateUrl: './search-fulltext.component.html',
  styleUrl: './search-fulltext.component.scss'
})
export class SearchFulltextComponent extends BaseRoutedComponent implements OnDestroy {
  throttle = 200;
  scrollDistance = 5;
  scrollUpDistance = 2;
  togglePrice: boolean = false;

  searchResult: SearchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
  keyword!:string;
  ordinamento: number = 0;
  firstQuickSelected!: string|null; //deve mantenere tutte le opzioni attive 
  minValue: number = 0;
  maxValue: number = 9999;
  page: number = 0;
  rowPage: number = 20;

  @Output() dataEmitter = new EventEmitter<boolean>();
  helperInfo!: any;

  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  options: Options = {
    floor: 0,
    ceil: 9999,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '€ ' + value;
        case LabelType.High:
          return '€ ' + value;
        default:
          return '€ ' + value;
      }
    }
  };

  keywordDisplayed:string ="";

  filtriSelezionati: Array<FiltroQuickFlat> = [];
  filtriQuick: Array<FiltroQuick> = [];
  disableScrolling:boolean = false;
  navigationSubscription: Subscription;
  
  constructor(
    public global: Globals,
    protected router: Router,
    protected searchService: SearchService,
    protected utilityService: UtilyService,
    protected loadingService: LoadingService,
    protected deviceService: DeviceDetectorService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
    super(route, seoService, storageService, translatorService, cookieService);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          if(params['keyword']) {
            this.keyword = params['keyword'];
            this.keywordDisplayed = this.keyword.replace(/\$/g, '/');
          }
        });
        
        this.searchResult = { minPrice: 0, maxPrice: 9999, totaleProdotti: 0, titoloRicerca: "", results: [] };
        this.initialiseInvites();
      }
    });
  }

  get isMobile(){
    return this.deviceService.isMobile() ;
  }

  get isDesktop(){
    return this.deviceService.isDesktop() ;
  }

  initialiseInvites() {   
    this.disableScrolling = false;
    this.filtriSelezionati = [];
    this.ordinamento = 0;
    this.cerca(true, false);
  }

  dynamicOpenFilterTab(){
    this.filtriQuick.forEach((item) => {
      item.isOpen = false;
      if(item.id == 'CATEGORIE' || item.id == 'BRAND'){
        item.isOpen = true;
      } else {
        this.filtriSelezionati.forEach((itemSelected) => {
          if(item.id == itemSelected.id){
            item.isOpen = true;
          }
        });        
      }
    });
  }

  switch(layout:string) {
      const layoutSwitcher = $(layout).closest('.layout-switcher');
      const productsView = $(layout).closest('.products-view');
      const productsList = productsView.find('.products-list');

      layoutSwitcher
          .find('.layout-switcher__button')
          .removeClass('layout-switcher__button--active')
          .removeAttr('disabled');

      $(layout).addClass('layout-switcher__button--active')
          .attr('disabled', '');

      productsList.attr('data-layout', $(layout).attr('data-layout'));
      productsList.attr('data-with-features', $(layout).attr('data-with-features'));
  }

  cerca(aggiornaFiltri: boolean, filtraPrezzi: boolean, aggiornaQuickAggiuntive:boolean=false) {
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    this.page = 0;
    this.loadingService.onShow();
    this.searchService.getSearchFullText(this.keyword, quickSel, filtraPrezzi ? this.minValue : null, filtraPrezzi ? this.maxValue : null, this.ordinamento, this.rowPage, this.page)
      .subscribe((res: any) => {
        if(res.isLink){
          this.router.navigate([res.result]);
        }else{
          if(aggiornaFiltri) {
            this.filtriQuick = res.result.quicks;
          }
          else 
          {
            this.setInactive();
  
            res.result.quicks.forEach((f1: FiltroQuick) => {
              f1.valori.forEach((v1: QuickValue) => {
  
                this.filtriQuick.find((x) => {
                  if (x.id == f1.id) {
                    x.valori.find(k => {
                      if (k.idValue == v1.idValue) {
                        k.inactive = false;
                      }
                    });
                  }
                });
               });
            });
  
            if(aggiornaQuickAggiuntive) 
            { //tengo i filtri brand categorie e promo come sono, vado a caricare i filtri quick aggiuntivi, se sono + di 1 categoria i filtri quick aggiuntivi da api sono vuoti
  
              var filtriTmp:Array<FiltroQuick> = [];
  
              this.filtriQuick.forEach((el:any, index:number) => {
                if(el.id =="CATEGORIE" || el.id=="BRAND" || el.id=="PROMO"){
                  filtriTmp.push(el);
                }
              });
  
              res.result.quicks.forEach((el:any) => {
                if(el.id !="CATEGORIE" && el.id!="BRAND" && el.id!="PROMO") {
                  filtriTmp.push(el);
                }
              });
  
              this.filtriQuick=filtriTmp;
            }
          }
  
          this.dynamicOpenFilterTab();
            
          if (!filtraPrezzi) {
            this.minValue = res.result.minPrice;
            this.maxValue = res.result.maxPrice;

            this.options = {
              floor: res.result.minPrice,
              ceil: res.result.maxPrice
            };
          }

          this.loadingService.onDelayHide(500);
          this.searchResult = res.result;

          this.helperInfo ={
            zeroResult :  res.result.results.length == 0,
            keyword: this.keyword
          };
   
          this.dataEmitter.emit(this.helperInfo);

          setTimeout(() => {
            this.filtersMobile();
          }, 1000);  
        }        
      });
  }
  
  filtersMobile() {
    const body = $('body');
    const sidebar = $('.sidebar');
    const offcanvas = sidebar.hasClass('sidebar--offcanvas--mobile') ? 'mobile' : 'always';
    const media = matchMedia('(max-width: 1280px)');

    if (sidebar.length) {
        const open = function () {
            if (offcanvas === 'mobile' && !media.matches) {
                return;
            }

            const bodyWidth = body.width();
            body.css('overflow', 'hidden');
            body.css('paddingRight', (body.width() - bodyWidth) + 'px');

            sidebar.addClass('sidebar--open');
        };
        const close = function () {
            body.css('overflow', 'auto');
            body.css('paddingRight', '');

            sidebar.removeClass('sidebar--open');
        };
        const onMediaChange = function () {
            if (offcanvas === 'mobile') {
                if (!media.matches && sidebar.hasClass('sidebar--open')) {
                    close();
                }
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onMediaChange);
        } else {
            media.addListener(onMediaChange);
        }

        $('.filters-button').on('click', function () {
            open();
        });
        $('.sidebar__backdrop, .sidebar__close').on('click', function () {
            close();
        });
        $('.btn-close-sidebar').on('click', function () {
            close();
        });
    }
  }

  addToFiltri(id: string, idQuick: string, voceFiltro: QuickValue) {
    const elem = { id: id, quick: idQuick, idValue: voceFiltro.idValue, value: voceFiltro.value } as FiltroQuickFlat;

    if (voceFiltro.checked) {
      this.filtriSelezionati.push(elem);
    }
    else {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == elem.id && obj.idValue == elem.idValue));
      this.firstQuickSelected = this.filtriSelezionati.length == 0 ? null : this.firstQuickSelected;
    }

    var newFilter:Array<FiltroQuickFlat>=[];

    if(idQuick.toUpperCase() =="CATEGORIE" ) {       

      this.filtriSelezionati.forEach(element => {
       if(element.id =="CATEGORIE" || element.id == 'BRAND' || element.id == 'PROMO'){
         newFilter.push(element);
       }
      });
      this.filtriSelezionati=newFilter;
     }

    this.firstQuickSelected = this.firstQuickSelected == null ? id :this.firstQuickSelected;

    if(idQuick.toUpperCase() == "CATEGORIE") {
      this.cerca(false, true, true);
    }
    else{
      this.cerca(false, true, false);
    }
  }

  cleanFiltriAttivi(id: string = '', idValue: string = ''): void {
    if (id != '' && idValue != '') {
      this.filtriSelezionati = this.filtriSelezionati.filter(obj => !(obj.id == id && obj.idValue == idValue));

      this.filtriQuick.find((x) => {
        if (x.id == id) {
          x.valori.find(k => {
            if (k.idValue == idValue) {
              k.checked = false;
            }
          });
        }
      });

      this.cerca(false, true);
    }
    else {
      this.filtriSelezionati = [];
      this.cerca(true, false);
    }

    if((id == '' && idValue == '') ||  this.filtriSelezionati.length == 0) {
      this.firstQuickSelected = null;
    }
  }

  setInactive(idEccezione:string| null = null) {
    this.filtriQuick.forEach(element => {
      if(element.id != null && element.id != idEccezione){
        element.valori.forEach(v => {
          v.inactive = true;
        });
      }
    });
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.cerca(false, true);
  }

  onScroll() {
    if(!this.disableScrolling){
      this.appendItem(true);
    }
  }

  appendItem(filtraPrezzi: boolean) {
    let quickSel: Array<KeyValue> = this.filtriSelezionati.map(el => { return { key: el.id, value: el.idValue } as KeyValue });
    this.page += 1;
    this.disableScrolling= true;
    this.searchService.getSearchFullText(this.keyword, quickSel, filtraPrezzi ? this.minValue : null, filtraPrezzi ? this.maxValue : null, this.ordinamento, this.rowPage, this.page)    
      .subscribe((res: any) => {

        if(res.isLink) {
          this.router.navigate([res.result]);
        } else {
          this.disableScrolling = res.result.results.length != 0 ? false : true; //se torna risultati sblocco l'infinity scroll 

          res.result.results.forEach((item:any) => {
            this.searchResult.results.push(item);
          });
        }        
      });
  }

  cleanUp(testo: string): string {
    return this.utilityService.encodeURLParameter(testo);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}