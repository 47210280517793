import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterLike'
})
export class FilterLikePipe implements PipeTransform {
    transform(items: any[], filter: string[], param:any): any {
        if (!items || !filter) {
            return items;
        }

        let countFilter = filter.length;
        switch(countFilter){
            case 1:
                return items.filter(item => item[filter[0]].toLowerCase().includes(param.toLowerCase()));
            case 2:
                return items.filter(item => item[filter[0]].toLowerCase().includes(param.toLowerCase()) 
                                         || item[filter[1]].toLowerCase().includes(param.toLowerCase()));
            case 3:
                return items.filter(item => item[filter[0]].toLowerCase().includes(param.toLowerCase()) 
                                         || item[filter[1]].toLowerCase().includes(param.toLowerCase())
                                         || item[filter[2]].toLowerCase().includes(param.toLowerCase()));
        }
    }
}