import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-chi-siamo-es',
  templateUrl: './es.component.html',
  styleUrl: './es.component.scss'
})
export class ChiSiamoEsComponent {
constructor(protected deviceService: DeviceDetectorService) { }

get isMobile(){
  return this.deviceService.isMobile() ;
}

get isDesktop(){
  return this.deviceService.isDesktop() ;
}
}