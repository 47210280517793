import { Component } from '@angular/core';
import { BaseRoutedComponent } from '../../base-route.component';
import { Globals } from 'src/classes/global';
import { ActivatedRoute } from '@angular/router';
import { TranslatorService } from 'src/services/translator.service';
import { SeoService } from 'src/services/seo.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrl: './chi-siamo.component.scss'
})
export class ChiSiamoComponent  extends BaseRoutedComponent {
  constructor(public global: Globals,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) 
    {
      super(route, seoService, storageService, translatorService, cookieService);
    }
}