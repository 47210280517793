import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: string, param:any): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter(item => item[filter] == param);
    }
}