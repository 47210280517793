<section id="contatti" class="container-fluid text-center">
    <h3>{{'contattaci'| translate|uppercase}}</h3>
    <form [formGroup]="form" class="text-start container" (ngSubmit)="onSubmit()">
        <div class="row g-3">
          <div class="col-12 col-md">
            <div class="form-group ">
              <label for="tipologia" class="form-label">{{'spuntaLaCasella' | translate}}<span class="text-danger">*</span></label>
              <div>
                <label style="margin-right:10%">
                  <input type="radio" value="Rivenditore" formControlName="tipologia">
                  <span class="p-1">{{'rivenditore'| translate}}</span>
                </label>
                <label  class="mr-5">
                  <input type="radio" value="Privato" formControlName="tipologia">
                  <span  class="p-1">{{'utenteFinale'| translate}}</span>
                </label>
                <div *ngIf="submitted && f['tipologia'].errors">
                  <div class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
                </div>
              </div>
            </div>

            <label for="informazioni" class="form-label">{{'cercoInformazioni' | translate}}<span class="text-danger">*</span></label>
            <select class="form-control form-select" id="informazioni" formControlName="informazioni">
              <option value="Prodotto">{{'prodotto' | translate}}</option>
              <option value="Distribuzione e partner">{{'distribuzionePartner' | translate}}</option>
              <option value="Informazioni tecniche">{{'informazioniTecniche' | translate}}</option>
              <option value="Altro">{{'altro' | translate}}</option>
            </select>
            <div *ngIf="submitted && f['informazioni'].errors">
              <div *ngIf="f['informazioni'].errors['required']" class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
            </div>

            <label for="nome" class="form-label">{{'nome' | translate}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="nome" placeholder="{{'ilTuoNome' | translate}}" formControlName="nome">
            <div *ngIf="submitted && f['nome'].errors">
              <div *ngIf="f['nome'].errors['required']" class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
            </div>

            <label for="telefono" class="form-label">{{'telefono' | translate}}</label>
            <input type="text" class="form-control" id="telefono" placeholder="{{'ilTuoNumero' | translate}}" formControlName="telefono">
            <div *ngIf="submitted && f['telefono'].errors">
              <div class="text-danger"><strong>{{'valueNotPermitted'| translate}}</strong></div>
            </div>
            <label for="email" class="form-label">{{'laTuaMail' | translate}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="email" placeholder="{{'inserisciLaTuaMail' | translate}}" aria-label="La tua email" formControlName="email">
            <div *ngIf="submitted && f['email'].errors">
              <div *ngIf="f['email'].errors['required']" class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
              <div *ngIf="f['email'].errors['email']" class="text-danger"><strong>{{'valueNotPermitted'| translate}}</strong></div>         
            </div>
          </div>
          <div class="col-12 col-md">
              <label for="messaggio">{{'ilTuoMessaggio' | translate}}<span class="text-danger">*</span></label>
              <textarea class="form-control" placeholder="{{'scriviIlTuoMessaggio' | translate}}" id="messaggio" style="height: 100%" formControlName="messaggio"></textarea>
              <div *ngIf="submitted && f['messaggio'].errors">
                <div *ngIf="f['messaggio'].errors['required']" class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
              </div>
          </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-md">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  formControlName="acconsento">
                  <label class="form-check-label" for="flexCheckDefault">{{'acconsentoAlTrattamento' | translate}} </label>
                  <div *ngIf="submitted && f['acconsento'].value == false">
                    <div  class="text-danger"><strong>{{'requiredField'| translate}}</strong></div>
                  </div>
                </div>
                <div class="text-center my-5"><input type="submit" value="{{'invia'| translate| uppercase}}" class="btn btn-primary btn-lg" [hidden]="hideInvia"/></div>
            </div>
        </div>
    </form>
</section>