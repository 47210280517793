<div class="container-fluid main-content g-0">
    <section *ngIf="!isMobile" id="headImage" class="bkgTestata" style="background-image: url('https://static.rms.it/evok/bkg-chi-siamo.jpg');">  
        <div class="bottombar">
            <div class="bottombar-left"></div>
            <div class="bottombar-middle"></div>
            <div class="bottombar-right"></div>
        </div>
    </section>
    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">QUI SOMMES-NOUS</h3>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok est né en 2019 avec son propre objectif ambitieux : garantir une amélioration de votre véhicule, là où commence la performance.
            Dès ce moment, nous avons été motivés par la détermination d'offrir des produits pour répondre et dépasser les besoins des motocyclistes
            Chaque pièce détachée Evok est le résultat d'une recherche méticuleuse et d'un amour inconditionnel pour le deux-roues, conçu pour garantir des performances améliorées. 
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">La mission d'Evok</em>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            La mission de la marque est d'être le lien entre l'original et la performance. 
            Les pièces détachées de la marque garantissent une amélioration technique et esthétique par rapport à l'original, ainsi qu'une expérience de conduite supérieure.
            Evok est un pionnier du soft tuning, avec un équilibre parfait entre passion, dédicace et développement continu.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">L'ADN de la marque</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Les produits Evok sont plug and play, nous croyons en la simplicité et la facilité d'utilisation, sans oublier la qualité du produit.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">Notre attention au produit</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">            
            Tous les composants Evok sont sujet à des contrôles de qualité, de la production à l'emballage, pour garantir une amélioration des performances de votre véhicule. 
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">                        
            Les pièces détachées Evok sont conçues pour offrir une compatibilité et des performances maximales pour une large gamme de motos. 
            Que vous possédiez une Vespa, un scooter, un maxi scooter ou une moto de 50 à 125 cm3, nos pièces détachées sont le choix idéal pour assurer une augmentation des performances. 
        </p>
        <br>
    </section>

    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">CE QUI NOUS DISTINGUE</h3>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/supporto.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>SUPPORT</h5>
                        <span class="text-small">
                            Le support proposé par Evok se caractérise par son excellence et son dévouement envers le client. 
                            L'équipe de spécialiste produit est toujours prête à fournir une assistance personnalisée, à répondre aux questions techniques, à offrir des conseils sur les produits et à aider à l'installation de pièces détachées.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/qualità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>QUALITÉ </h5>
                        <span class="text-small">
                            La qualité des produits Evok est une combinaison des matériaux de premier choix et des contrôles de production rigoureux.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/performance.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PERFORMANCE</h5>
                        <span class="text-small">
                            Avec Evok, chaque voyage se transforme en une expérience de conduite unique, où innovation et passion s'unissent pour dépasser toutes vos attentes.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div  class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/affidabilità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>FIABILITÉ</h5>
                        <span class="text-small">
                            Pour ceux qui recherchent sécurité et confort, Evok représente le choix idéal, offrant des composants largement fiables.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/plugandplay.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PLUG & PLAY</h5>
                        <span class="text-small">
                            Tous les produits Evok sont plug and play avec un remplacement facile et une installation sûre et fiable. 
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- VERSIONE MOBILE -->

    <section *ngIf="isMobile" id="intro" class="container overflow-hidden">
        <h5 class="mb-5 text-center">CE QUI NOUS DISTINGUE</h5>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>SUPPORT</h6>
                <span class="text-small">
                    Le support proposé par Evok se caractérise par son excellence et son dévouement envers le client. 
                    L'équipe de spécialiste produit est toujours prête à fournir une assistance personnalisée, à répondre aux questions techniques, à offrir des conseils sur les produits et à aider à l'installation de pièces détachées.
                </span>                                            
            </div>           
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>QUALITÉ </h6>
                <span class="text-small">
                    La qualité des produits Evok est une combinaison des matériaux de premier choix et des contrôles de production rigoureux.
                </span>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>PERFORMANCE</h6>
                <span class="text-small">
                    Avec Evok, chaque voyage se transforme en une expérience de conduite unique, où innovation et passion s'unissent pour dépasser toutes vos attentes.
                </span>                                            
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>FIABILITÉ</h6>
                <span class="text-small">
                    Pour ceux qui recherchent sécurité et confort, Evok représente le choix idéal, offrant des composants largement fiables.
                </span>
            </div>                
        </div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">                
                <h6>PLUG & PLAY</h6>
                <span class="text-small">
                    Tous les produits Evok sont plug and play avec un remplacement facile et une installation sûre et fiable. 
                </span>
            </div>
        </div>
    </section>
</div>
<div class="block-space block-space--layout--divider-xl"></div>
<div class="block-space block-space--layout--divider-xl"></div>