<div class="block-products-columns__list-item my-2">
    <div class="product-card">
        <div class="product-card__image">
            <div class="image image--type--product">
                <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" class="image__body">
                    <img class="image__tag" [src]="product.foto" onerror="this.src='../../../../assets/images/no-image-found.jpg'" [alt]="product.modello">
                </a>
            </div>
        </div>
        <div class="product-card__info">
            <div class="product-card__name">
                <div>
                    <div class="product-card__badges">
                        <div class="tag-badge tag-badge--theme" translate>{{'badgePiuAcquistati' | translate}}</div>
                    </div>
                </div>
            </div>
			<div class="product-card__meta mb-2">
                <a routerLink="/{{global.culture}}/datasheet/{{'P'+product.codiceProdotto}}/{{cleanUp(product.modello)}}" style=" color: black;">
                    <div class="product-card__meta-title mb-1">
                        <strong  class="mr-3">{{'global.codice' | translate | uppercase}}: </strong> <span>{{product.codiceProdotto}}</span>
                    </div>
                    <div class="product-card__meta-title mb-3">
                        <strong  class="mr-3">{{'global.brand' | translate | uppercase}}: </strong> <span>{{product.descrizioneBrand | uppercase}}</span>
                    </div>
                    <div class="product-card__meta-model border-bottom pb-2">
                        <strong>{{product.modello | uppercase}}</strong>                   
                    </div>                    
                </a>                
			</div>
        </div>
        <div class="product-card__footer">
            <div class="product-card__prices d-flex flex-column">
                <div class="product-card__price product-card__price--current mt-auto">
                    {{product.prezzoPubblico | html:'prezzoPubblico':0}}
                </div>
            </div>
        </div>
    </div>
</div>