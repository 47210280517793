import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { BaseRoutedComponent } from 'src/app/components/base-route.component';
import { Globals } from 'src/classes/global';
import { SeoService } from 'src/services/seo.service';
import { StorageService } from 'src/services/storage.service';
import { TranslatorService } from 'src/services/translator.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent extends BaseRoutedComponent {
  constructor(
    public global: Globals,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
      super(route, seoService, storageService, translatorService, cookieService);
    }    
}
