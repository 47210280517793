<header>
    <div class="topbar">
        <div class="topbar-left"></div>
        <div class="topbar-middle"></div>
        <div class="topbar-right"></div>
    </div>
    <nav class="navbar navbar-expand-lg bg-light" [ngClass]="{'navbar-mobile': isMobile}">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/{{global.culture}}/home">
                <img src="assets/images/logo-evok.png" class="img-fluid d-none d-md-block" alt="EVOK Performance">
                <img src="assets/images/logo-evok.png" class="img-fluid d-block d-md-none" alt="EVOK Performance">
            </a>
            <button id="buttonMenuMobile" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" >
                    <li *ngIf="!isMobile" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="buttonMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                            {{'prodotti' | translate | uppercase}}
                        </a>
                        <ul *ngIf="vociMenu.length > 0" class="dropdown-menu">
                            <li class="nav-item dropdown dropend">
                                <ul [hidden]="selectedComparto == null || selectedComparto.categorie == null" class="dropdown-menu show" data-bs-popper="static">
                                    <li *ngFor="let category of selectedComparto.categorie">
                                        <a class="nav-link dropdown-item" routerLink="/{{global.culture}}/search-products/{{'C' + selectedComparto.comparto.key +'-'+ category.key}}/{{cleanUp(selectedComparto.comparto.value +'-'+ category.value)}}" (click)="closeMenu()">
                                            <img src="https://static.rms.it/b2b/icons/categories/cat-{{category.key}}.svg" onerror="this.src='../../../../assets/images/icons/cat-generico.svg'" [alt]="category.value" class="img-fluid">
                                            {{category.value| uppercase}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li *ngFor="let voce of vociMenu" class="nav-item dropdown dropend">
                                <a href="#" class="nav-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" (click)="caricaCategorie(voce.comparto.key, true)">
                                    {{voce.comparto.value | uppercase}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="isMobile" class="nav-item">
                        <a class="nav-link" routerLink="/{{global.culture}}/search-products" (click)="closeMenu()">{{'prodotti' | translate | uppercase}}</a>
                    </li>                  
                    <li *ngIf="!isMobile" class="nav-item">
                        <a class="nav-link" routerLink="/{{global.culture}}/search-application" (click)="closeMenu()"> {{'application.ricercaVeicolo'| translate}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/{{global.culture}}/about-us" (click)="closeMenu()">{{'chiSiamo'| translate}}</a>
                    </li>
                    <li *ngIf="isMobile" class="nav-item">
                        <a class="nav-link" routerLink="/{{global.culture}}/download/catalog" (click)="closeMenu()">{{'cataloghi' | translate | uppercase}}</a>
                    </li>
                    <li *ngIf="isMobile" class="nav-item">
                        <a class="nav-link" routerLink="/{{global.culture}}/download/instructions" (click)="closeMenu()">{{'istruzioni' | translate | uppercase}}</a>
                    </li>
                </ul>
                <div id="searchbar" class="d-flex" role="search">
                    <input *ngIf="isMobile" class="search__input" type="text" placeholder="{{'ricercaLiberaOCodice' | translate}}" (keyup.enter)="onSearchComplete()" [(ngModel)]="keyword">
                    <input *ngIf="!isMobile" class="search__input" type="text" placeholder="{{'ricercaLiberaOCodice' | translate}}" (input)="onSearch()" (keyup.enter)="onSearchComplete()" [(ngModel)]="keyword">
                    <div *ngIf="!isMobile" class="line-after"></div>
                    <fa-icon *ngIf="!isMobile" [icon]="faSearch" [styles]="{ height: '24px', width: '24px' }" style="margin-top: 10px; margin-left: 5px; cursor: pointer;" (click)="onSearchComplete()"></fa-icon>
                </div>                

                <div *ngIf="!isMobile" ngbDropdown class="btn-group dropleft">
                    <button ngbDropdownToggle class="btn" style="font-size: 18px; font-weight: 900; color: #4c4d4f; margin: 0 0.5rem; text-transform: uppercase;" type="dropdown-toggle" aria-expanded="false">
                        {{'download' | translate | uppercase}}
                    </button>
                    <div class="dropdown-menu" >
                        <ul ngbDropdownMenu>
                            <li>
                                <a ngbDropdownItem routerLink="/{{global.culture}}/download/catalog" (click)="closeMenu()">{{'cataloghi' | translate | uppercase}}</a>
                            </li>
                            <li>
                                <a ngbDropdownItem routerLink="/{{global.culture}}/download/instructions" (click)="closeMenu()">{{'istruzioni' | translate | uppercase}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
              
                <div ngbDropdown class="btn-group dropleft" style="margin-right:1rem; padding-left: 10px;">
                    <a href="https://www.instagram.com/evok_performance/" class="indicator__button" target="_blank" style="margin-top: 1px;" rel="nofollow">
                        <div class="logo__image">
                            <fa-icon [icon]="faInstagram" [styles]="{ height: '27px', width: '27px'}"></fa-icon>
                        </div>
                    </a>
                </div>
                <div ngbDropdown class="btn-group dropleft" style="margin-right:1rem; padding-left: 10px;">
                    <a href="https://www.facebook.com/profile.php?id=61560960101822" class="indicator__button" target="_blank" style="margin-top: 1px;" rel="nofollow">
                        <div class="logo__image">
                            <fa-icon [icon]="faFacebook" [styles]="{ height: '27px', width: '27px'}"></fa-icon>
                        </div>
                    </a>
                </div>
                <div ngbDropdown class="btn-group dropleft" style="margin-right:1.5%;" >
                    <button ngbDropdownToggle class="btn" type=" dropdown-toggle" id="dropdownMenuLng" aria-expanded="false">
                        <img src="../../../../assets/images/languages/lang-{{global.culture}}.png">
                    </button>
                    <div class="dropdown-menu" >
                        <ul ngbDropdownMenu aria-labelledby="dropdownMenuLng" style="margin-right:1.5%; " class="dropdown-content">
                            <li>
                                <a ngbDropdownItem href="/it/home">
                                    <img src="../../../../assets/images/languages/lang-it.png">
                                    {{'italiano' | translate}}
                                </a>
                            </li>
                            <li>
                                <a ngbDropdownItem href="/en/home">
                                    <img src="../../../../assets/images/languages/lang-en.png">
                                    {{'inglese' | translate}}
                                </a>
                            </li>
                            <li>
                                <a ngbDropdownItem href="/es/home">
                                    <img src="../../../../assets/images/languages/lang-es.png">
                                    {{'spagnolo' | translate}}
                                </a>
                            </li>
                            <li>
                                <a ngbDropdownItem href="/fr/home">
                                    <img src="../../../../assets/images/languages/lang-fr.png">
                                    {{'francese' | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="bottombar">
        <div class="bottombar-left"></div>
        <div class="bottombar-middle"></div>
        <div class="bottombar-right"></div>
    </div>
</header>