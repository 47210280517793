import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { News } from 'src/classes/news';
import { LoadingService } from 'src/services/loading.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss']
})
export class HomeNewsComponent implements OnInit {
  news:Array<News> = [];

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  slideConfig!:any;
  constructor(public global: Globals,
    protected router: Router,
    protected loadingService: LoadingService,
    protected deviceService: DeviceDetectorService,
    protected navigationService: NavigationService) {
      this.loadConfigCarousel();
    }

  ngOnInit() {
    this.navigationService.getNews().subscribe((res:News[]) => {
      this.news = res;
    });
  }

  get isMobile(){
    return this.deviceService.isMobile();
  }

  get isDesktopSmall(){
    return window.innerWidth < 1280;
  }

  get isDesktopSmallSmall(){
    return window.innerWidth < 1080;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.loadConfigCarousel();
  }

  loadConfigCarousel() {
    if(this.deviceService.isDesktop() && !this.isDesktopSmallSmall)
    {
      this.slideConfig = {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "autoplay": true,
        "dots": true,
        "infinite": true,
        "arrows": true
      };
    }
    else if(this.deviceService.isDesktop() && this.isDesktopSmallSmall)
    {
      this.slideConfig = {
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "autoplay": true,
        "dots": true,
        "infinite": true,
        "arrows":true
      };
    }
    else if(this.deviceService.isTablet())
    {
      this.slideConfig = {
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "autoplay": true,
        "dots": false,
        "infinite": true,
        "arrows": false
      };
    }
    else
    {
      this.slideConfig = {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": false,
        "dots": false,
        "infinite": true,
        "arrows": false
      };
    }
  }
}