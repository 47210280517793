<div [hidden]="global.loading" class="site__body">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
        <div class="container-fluid mainContent content-search">
            <div class="block-header__body">
                <nav class="breadcrumb block-header__breadcrumb" aria-label="breadcrumb">
                    <ol class="breadcrumb__list">
                        <li class="breadcrumb__spaceship-safe-area" role="presentation"></li>
                        <li class="breadcrumb__item breadcrumb__item--parent breadcrumb__item--first">
                            <a routerLink="/{{global.culture}}/home" class="breadcrumb__item-link">{{'home' | translate}}</a>
                        </li>
                        <li class="breadcrumb__item breadcrumb__item--current breadcrumb__item--last" aria-current="page">
                            <span class="breadcrumb__item-link">{{'application.applicazioni' | translate}}</span>
                        </li>
                        <li class="breadcrumb__title-safe-area" role="presentation"></li>
                    </ol>
                </nav>
                <h2 class="block-header__title">{{'application.title' | translate}}</h2>
                <h6 *ngIf="!isMobile" class="block-header__subtitle">
                    {{'application.lbl1' | translate}}
                    <br><br>
                </h6>
            </div>
        </div>
    </div>
    <div class="block-split block-split--has-sidebar">
        <div class="container-fluid mainContent content-search">
            <div class="block-split__row row no-gutters">
                <div class="block-split__item block-split__item-sidebar col-auto">
                    <div class="sidebar sidebar--offcanvas--mobile">
                        <div class="sidebar__backdrop"></div>
                        <div class="sidebar__body">
                            <div class="sidebar__header">
                                <div class="sidebar__title">
                                    <strong>
                                        {{'search.filtri' | translate | uppercase}}
                                    </strong>
                                </div>
                                <button class="sidebar__close" type="button">
                                    <svg width="12" height="12">
                                        <path
                                            d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4C11.2,9.8,11.2,10.4,10.8,10.8z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="sidebar__content">
                                <div class="widget widget-filters widget-filters--offcanvas--mobile">
                                    <div class="widget__header widget-filters__header">
                                        <h4>
                                            {{'search.filtri' | translate | uppercase}}
                                            <button class="btn btn-primary btn-sm btn-close-sidebar" style="float: right; margin-left: 10px; margin-right: -10px;">{{'chiudi' | translate | uppercase}}</button>
                                            <button class="btn btn-primary btn-sm" style="float: right;" (click)="reset()">{{'search.reset' | translate | uppercase}}</button>
                                        </h4>
                                    </div>
                                    <div class="widget-filters__list">
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.marca' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-control" [(ngModel)]="filtri.marca" (change)="onSelectedMarca(true)" name="marca">
                                                            <option *ngFor="let marca of marche" [value]="marca">{{marca | uppercase}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.modelloGenerico' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.modelloGenerico"
                                                            (change)="onSelected('modelloGenerico')"
                                                            class="form-control" [disabled]="modelliGenerici.length ==0"
                                                            name="modelloGenerico">
                                                            <option [value]=""></option>
                                                            <option *ngFor="let modelloGenerico of modelliGenerici"
                                                                [value]="modelloGenerico">{{modelloGenerico |
                                                                uppercase}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.cilindrata' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.cilindrata"
                                                            (change)="onSelected('cilindrata');" class="form-control"
                                                            [disabled]="cilindrate.length ==0" name="cilindrata">
                                                            <option [value]="0"></option>
                                                            <option *ngFor="let cilindrata of cilindrate"
                                                                [value]="cilindrata">{{cilindrata}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.modello' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.modello"
                                                            (change)="onSelected('modello');" class="form-control"
                                                            [disabled]="modelli.length==0" name="modello">
                                                            <option [value]=""></option>
                                                            <option *ngFor="let modello of modelli" [value]="modello">
                                                                {{modello | uppercase}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.categoria' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.idCategoria"
                                                            (change)="setCategoriaValue();onSelected('idCategoria')"
                                                            class="form-control" [disabled]="categorie.length==0"
                                                            name="idCategoria">
                                                            <option [value]=0></option>
                                                            <option *ngFor="let categoria of categorie"
                                                                [value]="categoria.key">{{categoria.value | uppercase}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.telaio' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.telaio"
                                                            (change)="onSelected('telaio')" class="form-control"
                                                            [disabled]="telai.length ==0" name="telaio">
                                                            <option [value]=""></option>
                                                            <option *ngFor="let t of telai" [value]="t">{{t |
                                                                uppercase}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened">
                                                <button type="button" class="filter__title">
                                                    {{'application.anno' | translate}}
                                                </button>
                                                <div class="filter__body">
                                                    <div class="filter__container">
                                                        <select class="form-select" [(ngModel)]="filtri.anno"
                                                            (change)="onSelected('anno')" class="form-control"
                                                            [disabled]="anni.length ==0" name="anno">
                                                            <option [value]=""></option>
                                                            <option *ngFor="let a of anni" [value]="a.key">{{a.value}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-split__item block-split__item-content col-auto">
                    <div class="block">
                        <div class="products-view">
                            <div *ngIf="totaleProdotti > 0" class="products-view__options view-options view-options--offcanvas--mobile">
                                <div class="view-options__body">
                                    <button type="button" class="view-options__filters-button filters-button">
                                        <span class="filters-button__icon"><svg width="16" height="16">
                                                <path d="M7,14v-2h9v2H7z M14,7h2v2h-2V7z M12.5,6C12.8,6,13,6.2,13,6.5v3c0,0.3-0.2,0.5-0.5,0.5h-2C10.2,10,10,9.8,10,9.5v-3C10,6.2,10.2,6,10.5,6H12.5z M7,2h9v2H7V2z M5.5,5h-2C3.2,5,3,4.8,3,4.5v-3C3,1.2,3.2,1,3.5,1h2C5.8,1,6,1.2,6,1.5v3C6,4.8,5.8,5,5.5,5z M0,2h2v2H0V2z M9,9H0V7h9V9z M2,14H0v-2h2V14z M3.5,11h2C5.8,11,6,11.2,6,11.5v3C6,14.8,5.8,15,5.5,15h-2C3.2,15,3,14.8,3,14.5v-3C3,11.2,3.2,11,3.5,11z" />
                                            </svg>
                                        </span>
                                        <span class="filters-button__title">{{'search.filtri' | translate | uppercase}}</span>
                                    </button>                                   
                                    <div class="view-options__legend">
                                        <strong>{{totaleProdotti}}</strong>
                                        <span *ngIf="totaleProdotti > 1"> {{'search.prodottiTrovati' | translate}}</span>
                                        <span *ngIf="totaleProdotti == 1"> {{'search.prodottoTrovato' | translate}}</span>
                                    </div>
                                    <div class="view-options__spring"></div>
                                </div>
                                <div *ngIf="!isMobile && brandProdotti.length > 0"  class=" bg--gray mt-2">
                                    <h5  class=" pl-5" style="display: inline-block; padding: 0.15rem;">{{'application.filtraBrand' | translate }}</h5>
                                    <button class="btn btn-primary " style="float: right;" (click)="filtri.idBrand=''; loadProducts();">
                                        {{'search.reset' | translate}} 
                                    </button>
                                </div>                  
                                <div *ngIf="!isMobile" class="view-options__body view-options__body--filters">
                                    <div class="applied-filters">
                                        <div class="brand d-flex align-content-start">
                                            <div class="brand d-flex align-content-start flex-wrap">
                                                <div *ngFor="let brand of brandProdotti | slice:0:24"
                                                    class="main-menu__item" style="width: 10%;">
                                                    <a class="menu__link" (click)="filtraBrand(brand.key);">
                                                        <div class="brand-image " [ngClass]="filtri.idBrand==brand.key ? 'active': ''">
                                                            <img class="img-fluid " src="https://static.rms.it/b2b/loghi/brand/{{slugBrand(brand.value)}}_big.jpg" [alt]="brand.key">
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center m-5" *ngIf="totaleProdotti >= 200">
                               <h5 style="color: red;" [innerHTML]="'application.attenzioneTroppiRisultati'| translate"></h5> 
                            </div>
                            <div *ngFor="let _prodGrouped of products">
                                <div class="title-container bg--primary">
                                    <h5 class="category-title text-center">{{_prodGrouped.categoria}}</h5>
                                </div>
                                <app-search-product-list [prodotti]="_prodGrouped.prodotti"></app-search-product-list>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="block-space block-space--layout--before-footer"></div>
        </div>
    </div>
</div>