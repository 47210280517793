import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseRoutedComponent } from '../../base-route.component';
import { Globals } from 'src/classes/global';
import { ActivatedRoute } from '@angular/router';
import { TranslatorService } from 'src/services/translator.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { SeoService } from 'src/services/seo.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { StorageService } from 'src/services/storage.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.scss']
})
export class StoreLocatorComponent extends BaseRoutedComponent implements OnInit {
  country: string = "IT";
  countries: any = [];
  store = [] as any;
  markers = [] as any;
  radius = [] as any;
  radiusSelected: number = 50000;
  infoContent = '';
    
  constructor(public global: Globals,
    private navigationService: NavigationService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
    super(route, seoService, storageService, translatorService, cookieService);

    this.countries = [
      {
        "key": "IT",
        "value": "Italia"
      }];

    this.radius = [
      {
        "key": 5000,
        "value": "Raggio 5 km"
      },
      {
        "key": 10000,
        "value": "Raggio 10 km"
      },
      {
        "key": 25000,
        "value": "Raggio 25 km"
      },
      {
        "key": 50000,
        "value": "Raggio 50 km"
      }];
  }

  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;

  zoom = 11;
  maxZoom = 15;
  minZoom = 8;
  // center: google.maps.LatLngLiteral = { lat: 45.6375974, lng: 9.2183916 };
  center!: google.maps.LatLngLiteral;
  centerMap!: google.maps.LatLngLiteral | null;
  centerSearch!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    mapTypeId: 'roadmap',
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  };

  svgCenterMap = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(15, 30),
  };
   
  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }

      this.centerSearch = this.center;
      this.centerMap = this.center;
      this.getStoreLocator();
    }, ()=>{
      this.center = {
        lat: 45.637687,
        lng: 9.218978
      }

      this.centerSearch = this.center;
      this.centerMap = this.center;
      this.getStoreLocator();
    });
  }

  getStoreLocator() {
    this.radiusSelected = +this.radiusSelected;

    if (this.radiusSelected == 5000) {
      this.zoom = 12;
    }
    if (this.radiusSelected == 10000) {
      this.zoom = 11;
    }
    if (this.radiusSelected == 25000) {
      this.zoom = 10;
    }
    if (this.radiusSelected == 50000) {
      this.zoom = 9;
    }

    if (this.info) {
      this.info.close();
    }
    
    this.navigationService.getStoreLocator(this.center.lat, this.center.lng, this.radiusSelected)
    .subscribe((res: any) => {
      this.markers = [];
      this.store = res;
      this.store.forEach((item:any) => {
        this.markers.push({
          id: item.id,
          position: {
            lat: item.lat,
            lng: item.lng,
          },
          info: this.generateInfoMarker(item),
          options: {
            animation: google.maps.Animation.DROP,
          }
        })
      });
    });
  }

  generateInfoMarker(item: any) {
    var result = '<div class="box-google">' +
      '<p>' +
      '<span class="title-marker"><strong>' + item.ragioneSociale + '</strong></span><br><br>' +
      item.indirizzo + ' ' + item.localita + '(' + item.provincia + ') - ' + item.cap + '<br>' +
      '<strong>Tel</strong>: ' + item.telefono + ' - <strong>Email</strong>: ' + item.email + '<br>';

    if (item.sito && item.sito.length > 0) {
      result += '<span>' +
        '<br>' +
        '<a target="_blank" href="' + item.sito + '">' + this.translatorService.getLocalization("vaiAlSito") + ' »</a>' +
        '</span>';
    }

    result += '</span></p></div>'

    return result;
  }

  setAddress(addressInfo: any) {
    this.center = {
      lat: addressInfo.location.lat(),
      lng: addressInfo.location.lng()
    }

    this.centerSearch = this.center;
    this.centerMap = this.center;
    this.getStoreLocator();
  }

  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

  eventHandler(event: any, name: string) {
    if (name == 'mapClick') {
      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      this.centerSearch = this.center;
      this.centerMap = this.center;
      this.getStoreLocator();
    }
  }

  setMapStore(id: number) {
    this.markers.forEach((item: any) => {
      if (item.id == id) {
        this.zoom = 12;
        this.centerMap = this.center;
        this.center = item.position;
        this.infoContent = item.info;
        this.info.position = { lat: item.position.lat + 0.01, lng: item.position.lng };
        this.info.open();
      }
    });
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker, false);
  }
}
