import { Component, Input, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { ProductVariant } from 'src/classes/product';
import { SearchService } from 'src/services/search.service';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrl: './product-variant.component.scss'
})
export class ProductVariantComponent implements OnInit {
  @Input() idScheda!:string;

  faCircle = faCircle;

  varianti: Array<ProductVariant> = [];
  constructor(public global: Globals,
    protected utilyService: UtilyService,
    protected searchService: SearchService,
    protected deviceService: DeviceDetectorService) { }

  get isMobile(){
    return this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.getVarianti();
  }

  getVarianti() : void{
    this.searchService.getVarianti(this.idScheda)
    .subscribe((res: ProductVariant[])=> {
      this.varianti = res;
    })
  }

  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }
}
