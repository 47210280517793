import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/classes/global';
import { Product } from 'src/classes/product';
import { LatestProductService } from 'src/services/latest-product.service';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-latest-products',
  templateUrl: './latest-products.component.html',
  styleUrl: './latest-products.component.scss'
})
export class LatestProductsComponent implements OnInit {
  latestProducts: Array<Product> = [];
  constructor(public global:Globals,
              protected utilityService: UtilyService,
              protected latestProductService: LatestProductService) { }

  ngOnInit(): void {
    this.latestProductService.get().subscribe((response:any) => {
      this.latestProducts = response;
    });
  }

  cleanUp(testo: string): string {
    return this.utilityService.encodeURLParameter(testo);
  }
}
