import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { VoceMenu } from 'src/classes/voce-menu';
import { NavigationService } from 'src/services/navigation.service';
import { TranslatorService } from 'src/services/translator.service';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent{
  timer: any;
  keyword:string = "";  

  vociMenu: Array<VoceMenu> = [];
  selectedComparto:VoceMenu = <VoceMenu>{};

  faSearch = faSearch;
  faAngleRight = faAngleRight;
  faFacebook = faFacebook;
  faInstagram = faInstagram;

  constructor(public global: Globals, 
    protected router: Router,
    protected route: ActivatedRoute,
    protected utilyService: UtilyService,        
    protected transalateService:TranslatorService,
    protected navigationService: NavigationService,
    protected deviceService: DeviceDetectorService) {
      this.route.params.subscribe(params => {
        let routeCulture = params['culture'];
        let currentCulture = this.transalateService.getLanguage(routeCulture);

        this.transalateService.setComponentLanguage(currentCulture);
        this.transalateService.setGlobals(currentCulture);
        
        if(this.vociMenu.length == 0){
          this.navigationService.getMenu().subscribe((res:Array<VoceMenu>)=> {
            this.vociMenu = res;
          });
        }
      });                  
  }

  get isMobile(){
    return this.deviceService.isMobile() ;
  }

  get isDesktop(){
    return this.deviceService.isDesktop() ;
  }

  onSearch(){
    if(this.timer != undefined){
      window.clearTimeout(this.timer)
    }
    
    if(this.keyword.length >= 5) {
      var link = this.global.culture + "/search/" + this.keyword.replace(/\//g,'$');   //replace slash with $
      this.timer = window.setTimeout(() => {
        this.router.navigate([link]);
      }, 1000);
    }
  }

  onSearchComplete(){
    if(this.keyword.length >= 2) {
      this.closeMenu();
      var link = this.global.culture + "/search/" + this.keyword.replace(/\//g,'$');
      this.router.navigate([link]);
    }
  }  

  caricaCategorie(chiave: string, master: boolean) {
    this.vociMenu.forEach(e => {
      if(e.comparto.key == chiave) {
        this.selectedComparto=e;
      }
    });

    if(master){
      let element: HTMLElement = document.getElementById('master') as HTMLElement;
      if(element){
        element.click();
      }      
    }
  }

  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }

  closeMenu() {
    let element: HTMLElement = document.getElementById('buttonMenu') as HTMLElement;
    if(element){
      element.click();
    }    

    let elementMobile: HTMLElement = document.getElementById('buttonMenuMobile') as HTMLElement;
    if(elementMobile){
      elementMobile.click();
    }
   }
}
