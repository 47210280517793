import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private localStorage:LocalStorageService) { }

  write(key: string, value: any): void {
    this.localStorage.store(key, value);
  }
  
  read<TData>(key: string): TData {
    var data = this.localStorage.retrieve(key);
    return <TData>data;
  }

  remove(key: string): void {
    this.localStorage.clear(key);
  }

  removeAll(): void {
    this.localStorage.clear();
  }

  contains(key: string): boolean {
    var data = this.localStorage.retrieve(key);
    if(data != null) {
      return true;
    }
    return false;
  }
}