<div class="container-fluid main-content g-0">
    <section *ngIf="!isMobile" id="headImage" class="bkgTestata" style="background-image: url('https://static.rms.it/evok/bkg-chi-siamo.jpg');">  
        <div class="bottombar">
            <div class="bottombar-left"></div>
            <div class="bottombar-middle"></div>
            <div class="bottombar-right"></div>
        </div>
    </section>
    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">CHI SIAMO</h3>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok nasce nel 2019 con una visione chiara e ambiziosa: garantire uno step di miglioramento al tuo mezzo, dove incomincia la performance. 
            Da quel momento, il nostro viaggio è stato guidato dalla determinazione di offrire prodotti che non solo soddisfino le esigenze dei motociclisti, ma che le superino. 
            Ogni ricambio Evok è il frutto di una meticolosa ricerca e di un profondo amore per le due ruote, progettato per garantire un miglioramento delle prestazioni. 
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">La missione di Evok</em>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            La missione del brand è quella di essere il ponte tra l'originale e la performance. 
            I ricambi del marchio non solo garantiscono un miglioramento tecnico ed estetico rispetto all'originale, ma offrono anche un'esperienza di guida superiore. 
            Evok è pioniere del soft tuning, con un equilibrio perfetto tra passione, dedizione e continuo sviluppo.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">L'essenza del brand</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok è sinonimo di plug and play, perché crediamo nella semplicità e nella facilità di utilizzo, senza trascurare la qualità del prodotto.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">La nostra attenzione al prodotto</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">            
            Tutti i componenti Evok sono sottoposti ad accurati controlli di qualità, dalla produzione al confezionamento, per garantire un miglioramento delle prestazioni del tuo mezzo. 
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">                        
            I ricambi Evok sono progettati per offrire la massima compatibilità e performance per una vasta gamma di motoveicoli. 
            Che tu possieda una vespa, uno scooter, un maxiscooter o una moto 50-125 cc, i nostri ricambi sono la scelta ideale per assicurare un incremento delle prestazioni. 
        </p>
        <br>
    </section>

    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">COSA CI CONTRADDISTINGUE</h3>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/supporto.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>SUPPORTO</h5>
                        <span class="text-small">
                            Il supporto offerto da Evok si contraddistingue per la sua eccellenza e dedizione al cliente. 
                            Il team specializzato del prodotto è sempre pronto a fornire assistenza personalizzata, rispondendo a domande tecniche, offrendo consigli sui prodotti e aiutando con l'installazione dei ricambi.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/qualità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>QUALITÀ</h5>
                        <span class="text-small">
                            La qualità dei prodotti Evok nasce dalla sinergia tra i materiali di prima scelta e rigorosi controlli di produzione.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/performance.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PERFORMANCE</h5>
                        <span class="text-small">
                            Con Evok, ogni viaggio si trasforma in un'esperienza di guida unica, dove l'innovazione e la passione si fondono per superare ogni tua aspettativa.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div  class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/affidabilità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>AFFIDABILITÀ</h5>
                        <span class="text-small">
                            Per chi cerca sicurezza e comfort, Evok rappresenta la scelta ottimale, offrendo componenti ampiamente affidabili.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/plugandplay.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PLUG & PLAY</h5>
                        <span class="text-small">
                            Tutti i prodotti Evok sono plug and play, rendendo l'installazione dei ricambi per il tuo mezzo semplice e immediata.  
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- VERSIONE MOBILE -->

    <section *ngIf="isMobile" id="intro" class="container overflow-hidden">
        <h5 class="mb-5 text-center">COSA CI CONTRADDISTINGUE</h5>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>SUPPORTO</h6>
                <span class="text-small">
                    Il supporto offerto da Evok si contraddistingue per la sua eccellenza e dedizione al cliente. 
                    Il team specializzato del prodotto è sempre pronto a fornire assistenza personalizzata, rispondendo a domande tecniche, offrendo consigli sui prodotti e aiutando con l'installazione dei ricambi.
                </span>                                            
            </div>           
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>QUALITÀ</h6>
                <span class="text-small">
                    La qualità dei prodotti Evok nasce dalla sinergia tra i materiali di prima scelta e rigorosi controlli di produzione.
                </span>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>PERFORMANCE</h6>
                <span class="text-small">
                    Con Evok, ogni viaggio si trasforma in un'esperienza di guida unica, dove l'innovazione e la passione si fondono per superare ogni tua aspettativa.
                </span>                                            
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>AFFIDABILITÀ</h6>
                <span class="text-small">
                    Per chi cerca sicurezza e comfort, Evok rappresenta la scelta ottimale, offrendo componenti ampiamente affidabili.
                </span>
            </div>                
        </div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">                
                <h6>PLUG & PLAY</h6>
                <span class="text-small">
                    Tutti i prodotti Evok sono plug and play, rendendo l'installazione dei ricambi per il tuo mezzo semplice e immediata.  
                </span>
            </div>
        </div>
    </section>
</div>
<div class="block-space block-space--layout--divider-xl"></div>
<div class="block-space block-space--layout--divider-xl"></div>