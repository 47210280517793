import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  // Declare this key and iv values in declaration
  key = CryptoJS.enc.Utf8.parse('4512631236589784');
  iv = CryptoJS.enc.Utf8.parse('4512631236589784');

  // Methods for the encrypt and decrypt Using AES
  encryptUsingAES256(input:any) {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(input), this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    this.decryptUsingAES256(encrypted);

    return encrypted;
  }

  decryptUsingAES256(input:any) {
    var decrypted = CryptoJS.AES.decrypt(input, this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted;
  }


  decryptToString(input:any){
    var _tmp=this.decryptUsingAES256(input);
    _tmp.toString(CryptoJS.enc.Utf8);

    return   _tmp.toString(CryptoJS.enc.Utf8);
  }
}
