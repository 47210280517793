import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaderResponse, HttpSentEvent, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/classes/global';
import { CryptoService } from './crypto.service';
import { Observable, catchError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor  {

  constructor(private cryptoService: CryptoService, private router: Router, public global: Globals) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    var pathname = location.pathname;
    var indexSplitUrl = 1;    

    var splitUrl = pathname.split('/');
    var urllang = splitUrl[indexSplitUrl];

    var culture = urllang != "" && urllang != undefined ? urllang : this.global.culture;

    req = req.clone({
      headers: req.headers.set('Accept-Language', culture)
    });

    var clientSecretCrypt = this.cryptoService.encryptUsingAES256(environment.clientSecret).toString();

    req = req.clone({
      setHeaders : {
        ClientSecret: clientSecretCrypt
      }
    });

    return next.handle(req).pipe(
      catchError((error) => {
        let handled: boolean = false;
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
          } else {
            switch (error.status) {
              case 404:
                var notFoundPath = "/" + this.global.culture + '/not-found';
                this.global.loading = false;
                this.router.navigateByUrl(notFoundPath, { replaceUrl: true });
                handled = true;
                break;
              case 500:
                var errorPath = "/" + this.global.culture + '/error';
                this.global.loading = false;
                this.router.navigateByUrl(errorPath, { replaceUrl: true });
                handled = true;
                break;
            }
          }
        }
 
        if (handled) {
          return of(error);
        } else {
          return throwError(error);
        } 
      })
    );
  }
}
