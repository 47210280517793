<div class="site__body">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
        <div class="container-fluid mainContent content-search">
            <div class="block-header__body">                
                <h1 class="block-header__title">PRIVACY POLICY</h1>
            </div>
        </div>
    </div>
    <div class="block-split block-split--has-sidebar">
        <div class="container-fluid mainContent content-search">
            <div class="post__body typography history">
                <div class="iub_content legal_pp">

                    <h3 id="navigatori">Informativa sul trattamento dei dati personali ex artt. 13-14 Reg.to UE 2016/679</h3><br>
                    <h4> Soggetti Interessati: navigatori Sito Internet </h4>

                    <p>
                        RMS S.P.A. nella qualità di Titolare del trattamento dei Suoi dati personali, ai sensi e per gli effetti del Reg.to UE 2016/679 di seguito 'GDPR', con la
                        presente La informa che la citata normativa prevede la tutela degli interessati rispetto al trattamento dei dati personali e che tale trattamento sarà
                        improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.<br><br>
                        I Suoi dati personali verranno trattati in accordo alle disposizioni legislative della normativa sopra richiamata e degli obblighi di riservatezza ivi previsti.<br><br>
                        Finalità e base giuridica del trattamento: in particolare i Suoi dati verranno trattati per le seguenti finalità connesse all'attuazione di adempimenti relativi
                        ad obblighi legislativi o contrattuali:

                    </p>

                    <ul>
                        <li>accesso tecnico e Funzionale al Sito nessun dato viene tenuto dopo la chiusura del Browser;</li>
                        <li>finalità di navigazione Evoluta o gestione deicontenuti personalizzata;</li>
                        <li>finalità Statistica e di Analisi della navigazione e degli utenti.</li>
                    </ul>

                    <p>I Suoi dati saranno inoltre utilizzati per le seguenti finalità relative all’esecuzione di misure connesse ad obblighi contrattuali o pre-contrattuali:</p>
                    <ul>
                        <li>
                            finalità inerenti l’esecuzione di un contratto di cui sei parte o all'esecuzione di misure precontrattuali adottate su tua richiesta( es: richiesta di
                            contatto tramite il modulo Contatti, etc.);.
                        </li>
                    </ul>
                    <p>
                        Modalità del trattamento. I suoi dati personali potranno essere trattati nei seguenti modi:
                    </p>
                    <ul>
                        <li>a mezzo calcolatori elettronici con utilizzo di sistemi software gestiti da Terzi;</li>
                        <li>a mezzo calcolatori elettronici con utilizzo di sistemi software gestiti o programmati direttamente;</li>
                        <li>trattamento temporaneo in Forma Anonima.</li>
                    </ul>
                    <p>
                        Ogni trattamento avviene nel rispetto delle modalità di cui agli artt. 6, 32 del GDPR e mediante l'adozione delle adeguate misure di sicurezza previste.
                        I suoi dati saranno trattati unicamente da personale espressamente autorizzato dal Titolare ed, in particolare, dalle seguenti categorie di addetti:
                    </p>
                    <ul>
                        <li>programmatori e Analisti; </li>
                        <li>ufficio Marketing.</li>
                    </ul>
                    <p>
                        Comunicazione: I suoi dati potranno essere comunicati a soggetti esterni per una corretta gestione delrapporto ed in particolare alle seguenti categorie di
                        Destinatari tra cui tutti i Responsabili del Trattamento debitamente nominati:
                    </p>
                    <ul>
                        <li>Google Analytics: Target pubblicitario, Analitica/Misurazione, Ottimizzazione</li>
                    </ul>
                    <p>
                        Diffusione: I suoi dati personali non verranno diffusi in alcun modo.
                        I suoi dati personali potranno inoltre essere trasferiti, limitatamente alle finalità sopra riportate, nei seguenti stati:
                    </p>
                    <ul>
                        <li>paesi UE;</li>
                        <li>Stati Uniti.</li>
                    </ul>
                    <p>
                        Periodo di Conservazione. Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei dati, ai sensi dell’art. 5 del
                        GDPR, il periodo di conservazione dei Suoi dati personali è
                    </p>
                    <ul>
                        <li>
                            stabilito per un arco di tempo non superiore al conseguimento delle finalità per le quali sono raccolti e trattati per l'esecuzione e l'espletamento
                            delle finalità contrattuali;
                        </li>
                        <li>stabilito per un arco di tempo non superiore all'espletamento dei servizi erogati;</li>
                        <li>
                            stabilito per un arco di tempo non superiore alconseguimento delle finalità per le quali sono raccolti e trattati e nel rispetto dei tempi obbligatori
                            prescritti dalla legge.
                        </li>
                    </ul>
                    <p>
                        Gestione dei cookie: nel caso in cui Lei abbia dubbi o preoccupazioni inmerito all'utilizzo dei cookie Le è sempre possibile intervenire per impedirne
                        l'impostazione e la lettura, ad esempio modificando le impostazioni sulla privacy all'interno del Suo browser al fine di bloccarne determinati tipi.<br><br>
                        Poiché ciascun browser - e spesso diverse versioni dello stesso browser - differiscono anche sensibilmente le une dalle altre se preferisce agire
                        autonomamente mediante le preferenze del Suo browser può trovare informazioni dettagliate sulla procedura necessaria nella guida del Suo browser. Per
                        una panoramica delle modalità di azione per i browser più comuni, può visitare l'indirizzo www.cookiepedia.co.uk.<br><br>
                        Le società pubblicitarie consentono inoltre dirinunciare alla ricezione di annunci mirati, se lo si desidera. Ciò non impedisce l'impostazione dei cookie, ma
                        interrompe l'utilizzo e la raccolta di alcuni dati da parte di tali società.<br><br>
                        Per maggiori informazioni e possibilità di rinuncia, visitare l'indirizzo www.youronlinechoices.eu/.<br><br>
                        Titolare: il Titolare del trattamento dei dati, aisensi della Legge, è RMS S.P.A. (Via Macalle 156 , 20831 SEREGNO(MB); e-mail: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; telefono:
                        036227301; P.Iva: 00864010962) nella persona delsuo legale rappresentante pro tempore.<br><br>
                        Lei ha diritto di ottenere dal titolare la cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione, la portabilità, l'opposizione al
                        trattamento dei dati personaliche La riguardano, nonché in generale può esercitare tutti i diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del
                        GDPR.
                    </p>
                    <p>
                        Potrà inoltre visionare in ognimomento la versione aggiornata della presente informativa collegandosi all'indirizzo internet
                        <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>

                    <br><h4 id="ecommerce"> Soggetti Interessati: utenti ecommerce  </h4>

                    <p>
                        RMS S.P.A. nella qualità di Titolare del trattamento dei Suoi dati personali, ai sensi e per gli effetti del Reg.to UE 2016/679 di seguito 'GDPR', con la
                        presente La informa che la citata normativa prevede la tutela degli interessati rispetto al trattamento dei dati personali e che tale trattamento sarà
                        improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.<br><br>
                        I Suoi dati personali verranno trattati in accordo alle disposizioni legislative della normativa sopra richiamata e degli obblighi di riservatezza ivi previsti.<br><br>
                        Finalità e base giuridica del trattamento: in particolare i Suoi dati verranno trattati per le seguenti finalità connesse all'attuazione di adempimenti relativi
                        ad obblighi legislativi o contrattuali:

                    </p>
                    <ul>
                        <li>vendita online.</li>
                    </ul>
                    <p>Modalità del trattamento. I suoi dati personali potranno essere trattati nei seguenti modi:</p>
                    <ul>
                        <li>trattamento a mezzo di calcolatori elettronici.</li>
                    </ul>
                    <p>
                        Ogni trattamento avviene nel rispetto delle modalità di cui agli artt. 6, 32 del GDPR e mediante l'adozione delle adeguate misure disicurezza previste.
                        I suoi datisaranno trattati unicamente da personale espressamente autorizzato dal Titolare ed, in particolare, dalle seguenticategorie di addetti:
                    </p>
                    <ul>
                        <li>programmatori e Analisti; </li>
                        <li>ufficio Marketing.</li>
                    </ul>
                    <p>
                        Comunicazione: I suoi dati potranno essere comunicati a soggetti esterni per una corretta gestione del rapporto ed in particolare alle seguenti categorie di
                        Destinatari tra cui tutti i Responsabili del Trattamento debitamente nominati:
                    </p>
                    <ul>
                        <li>spedizionieri, Trasportatori, Padroncini, Poste, Aziende per la Logistica.</li>
                    </ul>
                    <p>
                        Diffusione: I suoi dati personali non verranno diffusi in alcun modo.<br><br>
                        Periodo di Conservazione. Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei dati, ai sensi dell’art. 5 del
                        GDPR, il periodo di conservazione dei Suoi dati personali è:
                    </p>
                    <ul>
                        <li>
                            stabilito per un arco di tempo non superiore al conseguimento delle finalità per le quali sono raccolti e trattati per l'esecuzione e l'espletamento
                            delle finalità contrattuali;
                        </li>
                        <li>
                            stabilito per un arco di tempo non superiore al conseguimento delle finalità per le quali sono raccolti e trattati e nel rispetto dei tempi obbligatori
                            prescritti dalla legge.
                        </li>
                    </ul>
                    <p>
                        Titolare: il Titolare del trattamento dei dati, aisensi della Legge, è RMS S.P.A. (Via Macalle 156 , 20831 SEREGNO(MB); e-mail: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; telefono:
                        036227301; P.Iva: 00864010962) nella persona delsuo legale rappresentante pro tempore.<br><br>
                        Lei ha diritto di ottenere dal titolare la cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione, la portabilità, l'opposizione al
                        trattamento dei dati personaliche La riguardano, nonché in generale può esercitare tutti i diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del
                        GDPR.
                    </p>

                    <br><h4 id="mailinglist"> Soggetti Interessati: utenti della MailingList  </h4>

                    <p>
                        RMS S.P.A. nella qualità di Titolare del trattamento dei Suoi dati personali, ai sensi e per gli effetti del Reg.to UE 2016/679 di seguito 'GDPR', con la
                        presente La informa che la citata normativa prevede la tutela degli interessati rispetto al trattamento dei dati personali e che tale trattamento sarà
                        improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.<br><br>
                        I Suoi dati personali verranno trattati in accordo alle disposizioni legislative della normativa sopra richiamata e degli obblighi di riservatezza ivi previsti.<br><br>
                        Finalità e base giuridica del trattamento: in particolare i Suoi dati verranno trattati per le seguenti finalità connesse all'attuazione di adempimenti relativi
                        ad obblighi legislativi o contrattuali:

                    </p>
                    <ul>
                        <li>invio di informazioni commerciali via e-mail o sms.</li>
                    </ul>
                    <p>
                        Il conferimento dei dati è per Lei facoltativo riguardo alle sopra indicate finalità, ed un suo eventuale rifiuto al trattamento non compromette la
                        prosecuzione del rapporto o la congruità del trattamento stesso.<br><br>
                        Modalità del trattamento. I suoi dati personali potranno essere trattati nei seguenti modi:
                    </p>
                    <ul>
                        <li>trattamento a mezzo di calcolatori elettronici.</li>
                    </ul>
                    <p>
                        Ogni trattamento avviene nel rispetto delle modalità di cui agli artt. 6, 32 del GDPR e mediante l'adozione delle adeguate misure disicurezza previste.
                        I suoi datisaranno trattati unicamente da personale espressamente autorizzato dal Titolare ed, in particolare, dalle seguenticategorie di addetti:
                    </p>
                    <ul>
                        <li>programmatori e Analisti; </li>
                        <li>ufficio Marketing.</li>
                    </ul>
                    <p>
                        Comunicazione: I suoi dati potranno essere comunicati a soggetti esterni per una corretta gestione delrapporto ed in particolare alle seguenti categorie di
                        Destinatari tra cui tutti i Responsabili del Trattamento debitamente nominati:
                    </p>
                    <ul>
                        <li>Google Analytics: Target pubblicitario, Analitica/Misurazione, Ottimizzazione</li>
                    </ul>
                    <p>
                        Diffusione: I suoi dati personali non verranno diffusi in alcun modo.
                        I suoi dati personali potranno inoltre essere trasferiti, limitatamente alle finalità sopra riportate, nei seguenti stati:
                    </p>
                    <ul>
                        <li>paesi UE;</li>
                        <li>Stati Uniti.</li>
                    </ul>
                    <p>
                        Periodo di Conservazione. Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei dati, ai sensi dell’art. 5 del
                        GDPR, il periodo di conservazione dei Suoi dati personali è
                    </p>
                    <ul>
                        <li>
                            stabilito per un arco di tempo non superiore al conseguimento delle finalità per le quali sono raccolti e trattati per l'esecuzione e l'espletamento
                            delle finalità contrattuali;
                        </li>
                        <li>stabilito per un arco di tempo non superiore all'espletamento dei servizi erogati;</li>
                        <li>
                            stabilito per un arco di tempo non superiore alconseguimento delle finalità per le quali sono raccolti e trattati e nel rispetto dei tempi obbligatori
                            prescritti dalla legge.
                        </li>
                    </ul>
                    <p>
                        Gestione dei cookie: nel caso in cui Lei abbia dubbi o preoccupazioni inmerito all'utilizzo dei cookie Le è sempre possibile intervenire per impedirne
                        l'impostazione e la lettura, ad esempio modificando le impostazioni sulla privacy all'interno del Suo browser al fine di bloccarne determinati tipi.<br><br>
                        Poiché ciascun browser - e spesso diverse versioni dello stesso browser - differiscono anche sensibilmente le une dalle altre se preferisce agire
                        autonomamente mediante le preferenze del Suo browser può trovare informazioni dettagliate sulla procedura necessaria nella guida del Suo browser. Per
                        una panoramica delle modalità di azione per i browser più comuni, può visitare l'indirizzo www.cookiepedia.co.uk.<br><br>
                        Le società pubblicitarie consentono inoltre dirinunciare alla ricezione di annunci mirati, se lo si desidera. Ciò non impedisce l'impostazione dei cookie, ma
                        interrompe l'utilizzo e la raccolta di alcuni dati da parte di tali società.<br><br>
                        Per maggiori informazioni e possibilità di rinuncia, visitare l'indirizzo www.youronlinechoices.eu/.<br><br>
                        Titolare: il Titolare del trattamento dei dati, aisensi della Legge, è RMS S.P.A. (Via Macalle 156 , 20831 SEREGNO(MB); e-mail: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; telefono:
                        036227301; P.Iva: 00864010962) nella persona delsuo legale rappresentante pro tempore.<br><br>
                        Lei ha diritto di ottenere dal titolare la cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione, la portabilità, l'opposizione al
                        trattamento dei dati personaliche La riguardano, nonché in generale può esercitare tutti i diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del
                        GDPR.
                    </p>
                    <p>
                        Potrà inoltre visionare in ognimomento la versione aggiornata della presente informativa collegandosi all'indirizzo internet
                        <a href="https://www.privacylab.it/informativa.php?10814348636" target="_blank">https://www.privacylab.it/informativa.php?10814348636</a>.
                    </p>

                    <br><h4 id="lavoro"> Soggetti Interessati: candidati da considerare per l'instaurazione di un rapporto di lavoro  </h4>

                    <p>
                        RMS S.P.A. nella qualità di Titolare del trattamento dei Suoi dati personali, ai sensi e per gli effetti del Reg.to UE 2016/679 di seguito 'GDPR', con la
                        presente La informa che la citata normativa prevede la tutela degli interessati rispetto al trattamento dei dati personali e che tale trattamento sarà
                        improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.<br><br>
                        I Suoi dati personali verranno trattati in accordo alle disposizioni legislative della normativa sopra richiamata e degli obblighi di riservatezza ivi previsti.<br><br>
                        Finalità e base giuridica del trattamento: in particolare i Suoi dati verranno trattati per le seguenti finalità connesse all'attuazione di adempimenti relativi
                        ad obblighi legislativi o contrattuali:

                    </p>
                    <ul>
                        <li>Selezione del personale per l'instaurazione di un rapporto di lavoro</li>
                    </ul>

                    <p>
                        Il conferimento dei dati è per Lei facoltativo riguardo alle sopra indicate finalità, ed un suo eventuale rifiuto al trattamento non compromette la
                        prosecuzione del rapporto o la congruità del trattamento stesso.<br><br>
                        Modalità del trattamento. I suoi dati personali potranno essere trattati nei seguenti modi:
                    </p>
                    <ul>
                        <li>
                            trattamento a mezzo di calcolatori elettronici;
                        </li>
                        <li>
                            trattamento manuale a mezzo di archivi cartacei
                        </li>
                    </ul>
                    <p>
                        Ogni trattamento avviene nel rispetto delle modalità dicui agli artt. 6, 32 del GDPR e mediante l'adozione delle adeguate misure di sicurezza previste.<br><br>
                        I suoi dati saranno trattati unicamente da personale espressamente autorizzato dal Titolare ed, in particolare, dalle seguenti categorie di addetti:
                    </p>
                    <ul>
                        <li>nell'ambito della gestione del personale.</li>
                    </ul>
                    <p>
                        Comunicazione: I suoi dati potranno essere comunicati a soggetti esterni per una corretta gestione del rapporto ed in particolare alle seguenti categorie di
                        Destinatari tra cui tutti i Responsabili del Trattamento debitamente nominati:
                    </p>
                    <ul>
                        <li>
                            consulenti e liberi professionisti, anche in forma associata.
                        </li>
                    </ul>
                    <p>
                        Diffusione: I suoi dati personali non verranno diffusi in alcun modo.
                        I suoi dati personali potranno inoltre essere trasferiti, limitatamente alle finalità sopra riportate, nei seguenti stati:
                    </p>
                    <ul>
                        <li>paesi UE;</li>
                        <li>Stati Uniti.</li>
                    </ul>
                    <p>
                        Periodo di Conservazione. Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei dati, ai sensi dell’art. 5 del
                        GDPR, il periodo di conservazione dei Suoi dati personali è
                    </p>
                    <ul>
                        <li>stabilito per un arco di tempo non superiore all'espletamento deiservizi erogati;</li>
                        <li>
                            stabilito per un arco di tempo non superiore al conseguimento delle finalità per le quali sono raccolti e trattati e nel rispetto dei tempi obbligatori
                            prescritti dalla legge.
                        </li>
                    </ul>
                    <p>
                        Titolare: il Titolare del trattamento dei dati, aisensi della Legge, è RMS S.P.A. (Via Macalle 156 , 20831 SEREGNO(MB); e-mail: <a href="info@rms.it" target="_blank">info&#64;rms.it</a>; telefono:
                        036227301; P.Iva: 00864010962) nella persona delsuo legale rappresentante pro tempore.<br><br>
                        Lei ha diritto di ottenere dal titolare la cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione, la portabilità, l'opposizione al
                        trattamento dei dati personaliche La riguardano, nonché in generale può esercitare tutti i diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del
                        GDPR.
                    </p>

                    <br><h4> Reg.to UE 2016/679: Artt. 15, 16, 17, 18, 19, 20, 21, 22 - Diritti dell'Interessato</h4>

                    <p>
                        1. L'interessato ha diritto di ottenere la conferma dell'esistenza omeno di dati personaliche lo riguardano, anche se non ancora registrati, la loro
                        comunicazione in forma intelligibile e la possibilità di effettuare reclamo presso l’Autorità di controllo.
                    </p>
                    <p>2. L'interessato ha diritto di ottenere l'indicazione:</p>
                    <ul>
                        <li>a. dell'origine dei dati personali;</li>
                        <li>b. delle finalità e modalità del trattamento;</li>
                        <li>c. della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;</li>
                        <li>d. degli estremi identificativi del titolare, deiresponsabili e delrappresentante designato aisensi dell'articolo 5, comma 2;</li>
                        <li>
                            e. dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di
                            rappresentante designato nel territorio dello Stato, di responsabili o incaricati.
                        </li>
                    </ul>
                    <p>3. L'interessato ha diritto di ottenere: </p>
                    <ul>
                        <li>a. l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;</li>
                        <li>
                            b. la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli dicui non è necessaria la
                            conservazione in relazione agli scopi per i quali i datisono stati raccolti o successivamente trattati;
                        </li>
                        <li>
                            c. l'attestazione che le operazioni dicui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai
                            quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi
                            manifestamente sproporzionato rispetto al diritto tutelato;
                        </li>
                        <li>d. la portabilità dei dati.
                    </li></ul>
                    <p>4. L'interessato ha diritto di opporsi, in tutto o in parte:</p>
                    <ul>
                        <li>a. permotivi legittimi al trattamento dei dati personaliche lo riguardano, ancorché pertinenti allo scopo della raccolta;
                        </li><li>
                            b. al trattamento di dati personaliche lo riguardano a fini di invio dimateriale pubblicitario o di vendita diretta o per il compimento di ricerche di
                            mercato o dicomunicazione commerciale.
                        </li>
                    </ul>
                    <br>
                    <p>
                        Ultima modifica: 20 Novembre 2018
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>