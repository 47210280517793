<div class="container-fluid main-content g-0">
    <section id="servizi" class="container text-center">
         <div *ngFor="let c of catalog" class="row">
            <div class="item col-12 col-md-3 text-start">
            </div>
            <div class="item col-12 col-md-6 text-start">
                <a href="{{c.urlContenuto}}">
                    <img [src]="c.urlImmagine" class="img-fluid" alt="{{c.titolo}}" />
                    <h5>{{c.titolo}}</h5>
                </a>
            </div>
            <div class="item col-12 col-md-3 text-start">
            </div>
        </div>
    </section>
</div>