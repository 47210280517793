import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from 'src/classes/global';
import { Product } from 'src/classes/product';
import { TranslatorService } from 'src/services/translator.service';
import { UtilyService } from 'src/services/utility.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrl: './product-search.component.scss'
})
export class ProductSearchComponent {
  @Input() product!: Product;
  @Input() promoCarrello: boolean = false;
  @Output() loadProductCart = new EventEmitter();
  @ViewChild('popOver') public popover!: NgbPopover;
  
  flipped:boolean = false;
  hiddenApplicazioni:boolean = true;

  constructor(
    public global:Globals,
    protected router: Router, 
    protected modalService: NgbModal,
    protected utilyService: UtilyService,            
    protected deviceService: DeviceDetectorService,
    protected translatorService: TranslatorService) { }


  get isDesktop(){
    return this.deviceService.isDesktop();
  }

  get isTablet(){
    return this.deviceService.isTablet();
  }

  get isMobile(){
    return this.deviceService.isMobile();
  }

  flipIt() {
    this.flipped = !this.flipped;
  }

  cleanUp(testo:string):string{
    return this.utilyService.encodeURLParameter(testo);
  }

  slugBrand(testo:string){
    return this.utilyService.encodeBrandDescription(testo);
  }

  close(){
    this.popover.close();
  }
}