import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home-en',
  templateUrl: './en.component.html',
  styleUrl: './en.component.scss'
})
export class HomeEnComponent { 
  constructor(protected deviceService: DeviceDetectorService) { }

  get isMobile(){
    return this.deviceService.isMobile() ;
  }

  get isDesktop(){
    return this.deviceService.isDesktop() ;
  }
}