import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilyService {  
  encodeURLParameter(testo: string): string {
    let f=testo.replace(/[^a-zA-Z0-9\-]+/g, " ");

    f=f.trim();
    f=f.replace('[ ]{2,}',f); //rimuovo doppi spazi
    f =f.replace(/\s/g, "_");

    return f;
  }

  decodeURLParameter(testo: string): string {
    let f=testo.replace(/[^a-zA-Z0-9\-]+/g, " ");

    f=f.trim();
    f =f.replace(/\s/g, " ");

    return f;
  }

  encodeBrandDescription(testo: string): string {
    let f=testo.replace(/[^a-zA-Z0-9\-]+/g, " ");

    f=f.trim();
    f=f.replace('[ ]{2,}',f); //rimuovo doppi spazi
    f=f.replace(/\s/g, "_");
    f=f.replace('-', '_');

    return f;
  }
}