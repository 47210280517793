<footer class="container-fluid">
    <div class="topbar row">
        <div class="topbar-left"></div>
        <div class="topbar-middle"></div>
        <div class="topbar-right"></div>
    </div>
    <div class="row">
        <div class="col">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-3 mt-5">
                    <h6>EVOK PERFORMANCE</h6>
                    <p>SEREGNO - ITALY<br>
                    <br>
                    {{'iNostriUfficiSonoAperti'| translate|uppercase}}:<br>
                    {{'daLunediAVenerdi'| translate}}<br>
                    {{'dalle8Alle17'| translate}}</p>
                </div>
                    <div class="col-12 col-md mt-5 mt-md-0">
                        <h6>WE ARE EVOK</h6>
                        <a routerLink="/{{global.culture}}/about-us" class="btn btn-link" rel="nofollow">{{'chiSiamo'| translate | uppercase}}</a>
                        <a routerLink="/{{global.culture}}/store-locator" class="btn btn-link" rel="nofollow">STORE LOCATOR</a>
                    </div>
                    <div class="col-12 col-md mt-5 mt-md-0">
                        <h6>{{'infoUtili'| translate |uppercase}}</h6>
                        <a routerLink="/{{global.culture}}" fragment="contatti" class="btn btn-link">{{'contatti'| translate |uppercase}}</a>
                        <a routerLink="/{{global.culture}}/privacy-policy" class="btn btn-link">PRIVACY POLICY</a>
                    </div>
                    <div class="col-12 col-md mt-5 mt-md-0">
                        <h6>{{'seguiciSu' |translate |uppercase}}</h6>
                        <a href="https://www.instagram.com/rms_moto/" class="btn btn-link" target="_blank" rel="nofollow">
                            <fa-icon [icon]="faInstagram"></fa-icon> INSTAGRAM
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61560960101822" class="btn btn-link" target="_blank" rel="nofollow">
                            <fa-icon [icon]="faFacebook"></fa-icon> FACEBOOK
                        </a>
                    </div>
                </div>
                <div class="row copy">
                    <div class="col text-center">
                        <span> &copy; {{year}} Evok Performance</span><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<button  id="myBtn" title="Torna su"><i class="fas fa-arrow-up"></i></button>