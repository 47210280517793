
<div class="container-fluid main-content g-0">
    <div  class="container-fluid content" id="storelocator" >
        <div class="row" id="marchi">
            <div class="col-md-12 testata mt-5 mb-3">
                <h3 id="top">{{'cercaRivenditore' | translate | uppercase}}</h3>
            </div>
        </div>
        <div class="contBox">
            <div class="row clearfix">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label class="label-strong" translate>{{'country' | translate }}</label>
                                                    <select class="form-select" [(ngModel)]="country" class="form-control" >
                                                        <option *ngFor="let c of countries" [value]="c.key">{{c.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                                                <div class="form-group">
                                                    <label class="label-strong" translate>{{'infoRicercaCitta' | translate }}</label>
                                                    <app-google-autocomplete [country]="country" (out)="setAddress($event)"></app-google-autocomplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                                <div class="form-group" >
                                                    <select [(ngModel)]="radiusSelected" (change)="getStoreLocator()"class="col-lg-7 col-md-7 col-sm-7 form-control" style="display:inline;">
                                                        <option *ngFor="let r of radius" [value]="+r.key">{{r.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="listings mt-5">
                                        <div style="height:563px; overflow-y: auto;">
                                        <div *ngFor="let s of store" class="item" style="cursor:pointer;" (click)="setMapStore(s.id)">
                                            <p style="line-height: 1.5rem;">
                                                <strong>{{s.ragioneSociale}}</strong>
                                                <br>{{s.indirizzo}} {{s.localita}}({{s.provincia}}) - {{s.cap}} - {{s.distance}} km<br>
                                                {{'tel' | translate}}: {{s.telefono}} - {{'email' | translate}}: {{s.email}}<br>
                                                <span style="color: #f3722a;">
                                                    <span *ngIf="s.sito && s.sito.length > 0">
                                                        <a href="http://{{s.sito}}" target="_blank">{{'vaiAlSito' | translate}} »</a>
                                                    </span>
                                                </span>
                                            </p>
                                            <hr>
                                        </div>
                                    </div>
                                    </div>
                                    <p style="color:red;text-align:center;display:none">{{'noresult' | translate}}</p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-8 col-lg-8">                                 
                                    <google-map #myGoogleMap
                                        width="100%"
                                        height="700px"
                                        [zoom]="zoom"
                                        [center]="center"
                                        [options]="options"
                                        (mapClick)="eventHandler($event,'mapClick')">
                                    <map-marker
                                        #markerElem="mapMarker"
                                        *ngFor="let marker of markers"
                                        [position]="marker.position"
                                        [label]="marker.label"
                                        [title]="marker.title"
                                        [options]="marker.options"
                                        [icon]="'../assets/images/icons/evokShop.png'"
                                        (mapClick)="openInfo(markerElem, marker.info)">
                                    </map-marker>
                                    <map-circle #mapCircle
                                        [center]="centerSearch"
                                        [radius]="radiusSelected + 2000"
                                        [options]="{ 
                                            strokeColor: '#FF0000',
                                            strokeOpacity: 0.6,
                                            strokeWeight: 1.0, 
                                            fillColor: '#FF0000',
                                            fillOpacity: 0.2 }">
                                    </map-circle>
                                    <map-info-window [innerHTML]="infoContent"></map-info-window>
                                    </google-map>
                                </div>
                                <br><br>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
    </div>
</div>