<div class="container-fluid main-content g-0">
    <section id="servizi" class="container text-center">        
         <div  class="row">        
            <div *ngFor="let c of categories" class="item col-12 col-md-4 text-start">
                <a style="cursor: pointer;" (click)="setCategory(c.id)">
                    <img *ngIf="categorySelected != c.id" src="https://static.rms.it/evok/istructions.png" class="text-img">
                    <span *ngIf="categorySelected != c.id" class="text-instructions">{{'istruzioni' | translate | uppercase}}:</span>
                    <span *ngIf="categorySelected != c.id" class="text-category">{{c.descrizione | uppercase}}</span>
                </a>                

                <div *ngIf="categorySelected == c.id" class="card widget widget-products d-lg-block">
                    <div class="widget__header">
                        <button class="product-card__action product-card__action--quickview" type="button" (click)="close()">
                            <fa-icon [icon]="faCircleXmark" [styles]="{ height: '24px', width: '24px' }"></fa-icon>
                        </button>
                    </div> 
                    <div class="widget-products__list">
                        <div *ngFor="let product of products" class="widget-products__item">
                            <div class="spec__row m-3" *ngFor="let m of product.manuali">
                                <a target="_blank" href="https://static.rms.it/b2b/manuali/{{m.manuale}}" style="text-decoration: none; color: inherit;">
                                    <fa-icon class="fa-2x" [icon]="faPdf" style="vertical-align: middle; margin-right: 15px;"></fa-icon>
                                    <span class="spec__name">{{ m.titolo }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="block-space block-space--layout--divider-xl"></div>
<div class="block-space block-space--layout--divider-xl"></div>