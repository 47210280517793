import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { Globals } from 'src/classes/global';
import { ApplicationService } from 'src/services/application.service';
import { LoadingService } from 'src/services/loading.service';
import { SeoService } from 'src/services/seo.service';
import { TranslatorService } from 'src/services/translator.service';
import { BaseRoutedComponent } from '../../base-route.component';
import { Location } from '@angular/common';
import { ApplicazioniGroup } from 'src/classes/applicazione-group';
import { UtilyService } from 'src/services/utility.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { StorageService } from 'src/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-search-application',
  templateUrl: './search-application.component.html',
  styleUrl: './search-application.component.scss'
})
export class SearchApplicationComponent extends BaseRoutedComponent implements OnDestroy {
  marche: string[] = [];
  cilindrate: string[] = [];
  modelliGenerici: string[] = [];
  modelli: string[] = [];
  categorie: any[] = [];
  products: ApplicazioniGroup[] = [];
  anni: any[] = [];
  telai: string[] = [];
  brandProdotti: any[] = [];

  filtri!: any;

  filterObj: any = {
    marca: "",
    cilindrata: "",
    modelloGenerico: "",
    modello: "",
    categoria: "",
    idCategoria: "",
    telaio: "",
    anno: "",
    yss: false,
    soloDisponibili: false,
    page: 0,
    rowPage: 200,
    ordinamento: 0,
    idBrand: "",
  };

  lastFilter: string = "";

  totaleProdotti: number = 0

  faCheck = faCheck;
  faXmark = faXmark;

  countfiltri: number = 0;
  navigationSubscription: Subscription;
  disableScrolling: boolean = false;

  constructor(
    public global: Globals,
    protected router: Router,
    protected location: Location,
    protected utilyService: UtilyService,
    protected loadingService: LoadingService,    
    protected deviceService: DeviceDetectorService,
    protected applicationService: ApplicationService,
    protected override route: ActivatedRoute,
    protected override seoService: SeoService,
    protected override storageService: StorageService,
    protected override translatorService: TranslatorService,
    protected override cookieService: NgcCookieConsentService) {
      super(route, seoService, storageService, translatorService, cookieService);

    this.navigationSubscription = this.router.events.subscribe((e: any) => {

      if (e instanceof NavigationEnd) {
        this.route.params.subscribe(params => {

          this.filtri =JSON.parse(JSON.stringify(this.filterObj));

          this.filtri.marca = params['marca'] != '0' ? params['marca'] : '';
          this.filtri.cilindrata = params['cilindrata'] != '0' ? params['cilindrata'] : '';
          this.filtri.modelloGenerico = params['modelloGenerico'] != '0' ? params['modelloGenerico'] : '';
          this.filtri.modello = params['modello'] != '0' ? params['modello'] : '';
          this.filtri.idCategoria = params['idCategoria'] != '0' ? params['idCategoria'] : '';
          this.filtri.telaio = params['telaio'] != '0' ? params['telaio'].replace(/\$/g, '/') : '';
          this.filtri.anno = params['anno'] != '0' ? params['anno'] : '';
          this.filtri.yss = false;
        });

        this.initialiseInvites();
      }
    });
  }

  get isMobile(){
    return this.deviceService.isMobile();
  }

  get isDesktop(){
    return this.deviceService.isDesktop();
  }

  initialiseInvites() {
    this.loadMarche();

    if (this.filtri.marca) {
      this.onSelectedMarca();
      this.loadProducts();
    }
    else {
      setTimeout(() => {
        this.filtersMobile();
        let element: HTMLElement = document.getElementsByClassName('filters-button')[0] as HTMLElement;
        element.click();
      }, 500);
    }
  }

  loadMarche(): void {
    this.applicationService.getMarcheApplicazioni().subscribe((response: any) => {
      this.marche = response;
    });
  }

  loadCilindrate(): void {
    this.applicationService.getCilindrataApplicazioni(this.filtri).subscribe((response: any) => {
      this.cilindrate = response;
    });
  }

  loadModelliGenerici() {
    this.applicationService.getModelloApplicazioni(this.filtri).subscribe((response: any) => {
      this.modelliGenerici = response;
    });
  }

  reset() {
    this.modelliGenerici = [];
    this.cilindrate = [];
    this.modelli = [];
    this.categorie = [];
    this.anni = [];
    this.telai = [];
    this.products = [];

    this.filtri = JSON.parse(JSON.stringify(this.filterObj));
    this.totaleProdotti = 0;
    this.brandProdotti = [];
    this.filtri.yss = false;
  }

  onSelectedMarca(reset: boolean = false) {
    this.lastFilter = "";

    if (reset) {
      var marca = this.filtri.marca;
      this.reset()
      this.filtri.marca = marca;
    }

    this.loadModelliGenerici();
    this.loadCilindrate();
    this.loadModelli();
    this.loadCategorie();
    this.loadTelai();
    this.loadAnni();
  }

  onSelected(filtroAttivato: string) {
    this.lastFilter = filtroAttivato;
    if (!this.filtri.modelloGenerico) {
      this.loadModelliGenerici();
    }
    if (!this.filtri.modello) {
      this.loadModelli();
    }
    if (!this.filtri.cilindrata) {
      this.loadCilindrate();
    }
    if (!this.filtri.categoria) {
      this.loadCategorie();
    }
    if (!this.filtri.telaio) {
      this.loadTelai();
    }
    if (!this.filtri.anno) {
      this.loadAnni();
    }

    this.loadProducts();
    this.updateURL();

  }

  checkCoerenzaFiltri() {
    var flg = false;
    if (this.products.length == 0) {

      for (const property in this.filtri) {
        if (property != this.lastFilter && property != "marca" && typeof this.filtri[property] === 'string') {
          this.filtri[property] = '';
          flg = true;
        }
      }
      if(flg) {
        this.onSelected(this.lastFilter);
      }
    }
  }

  slugBrand(testo:string){
    return this.utilyService.encodeBrandDescription(testo);
  }

  filtraBrand(key: string){
    this.filtri.idBrand = this.filtri.idBrand == key ? '' : key;

    this.loadProducts();
  }  

  loadModelli() {
    this.applicationService.getModelloCompletoApplicazioni(this.filtri).subscribe((response: any) => {
      this.modelli = response;
    });
  }

  loadCategorie() {
    this.applicationService.getCategorieApplicazioni(this.filtri).subscribe((response: any) => {
      this.categorie = response;
    });
  }

  loadTelai() {
    this.applicationService.getTelaioapplicazioni(this.filtri).subscribe((response: any) => {
      this.telai = response;
    });
  }

  loadAnni() {
    this.applicationService.getAnnoApplicazioni(this.filtri).subscribe((response: any) => {
      this.anni = response;
    });
  }

  setCategoriaValue() {
    if (this.filtri.idCategoria != null && this.filtri.idCategoria != "") {
      this.categorie.forEach(item => {
        if (item.key == this.filtri.idCategoria) {
          this.filtri.categoria = item.value;
        }
      });
    }
  }

  loadProducts(scrollingPages: boolean = false) {

    if(!scrollingPages){
      this.loadingService.onShow();
    }
    this.filtri.page = scrollingPages ? (this.filtri.page + 1) : 0;
    
    this.applicationService.getProdottiApplicazioneGroup(this.filtri).subscribe((response: any) => {
      this.totaleProdotti = response.totaleRisultati;
 
      if (scrollingPages) {
        if (response.prodottiCategoria.length == 0) {
          this.disableScrolling = true;
        }
        response.prodottiCategoria.forEach((item: any) => { this.products.push(item); });
      }
      else {
        this.loadingService.onHide();
        this.products = response.prodottiCategoria;
      }

      if (!this.filtri.idBrand) {
        this.brandProdotti = response.brand;
      }

      if (this.isMobile) {
        let element: HTMLElement = document.getElementsByClassName('sidebar__close')[0] as HTMLElement;
        element.click();
      }

      if(this.products.length ==0) {
        this.checkCoerenzaFiltri();
      }

    });
  }


  updateURL() {
    var _cilind = this.filtri.cilindrata ? this.filtri.cilindrata : 0;
    var _modGen = this.filtri.modelloGenerico ? this.filtri.modelloGenerico : 0;
    var _modello = this.filtri.modello ? this.filtri.modello : 0;
    var _cat = this.filtri.idCategoria ? this.filtri.idCategoria : 0;
    var _telaio = this.filtri.telaio ? this.filtri.telaio : 0;
    var _anno = this.filtri.anno ? this.filtri.anno : 0;
    var pageName = this.filtri.yss ? "configuratore-yss" : "search-application";

    this.location.go("/" + this.global.culture + "/"+ pageName + "/" + this.filtri.marca + "/" + _cilind + "/" + _modGen + "/" + _modello + "/" + _cat + "/" + _telaio + "/" + _anno);
  }

  onScroll() {
    if (!this.disableScrolling) {
      this.loadProducts(true);
    }
  }

  filtersMobile() {
    const body = $('body');
    const sidebar = $('.sidebar');
    const offcanvas = sidebar.hasClass('sidebar--offcanvas--mobile') ? 'mobile' : 'always';
    const media = matchMedia('(max-width: 1280px)');

    if (sidebar.length) {
      const open = function () {
        if (offcanvas === 'mobile' && !media.matches) {
          return;
        }

        const bodyWidth = body.width();
        body.css('overflow', 'hidden');
        body.css('paddingRight', (body.width() - bodyWidth) + 'px');

        sidebar.addClass('sidebar--open');
      };
      const close = function () {
        body.css('overflow', 'auto');
        body.css('paddingRight', '');

        sidebar.removeClass('sidebar--open');
      };
      const onMediaChange = function () {
        if (offcanvas === 'mobile') {
          if (!media.matches && sidebar.hasClass('sidebar--open')) {
            close();
          }
        }
      };

      if (media.addEventListener) {
        media.addEventListener('change', onMediaChange);
      } else {
        media.addListener(onMediaChange);
      }

      $('.filters-button').on('click', function () {
        open();
      });
      $('.sidebar__backdrop, .sidebar__close').on('click', function () {
        close();
      });
      $('.btn-close-sidebar').on('click', function () {
        close();
      });
    }
  }

  switch(layout: string) {
    const layoutSwitcher = $(layout).closest('.layout-switcher');
    const productsView = $(layout).closest('.products-view');
    const productsList = productsView.find('.products-list');

    layoutSwitcher
      .find('.layout-switcher__button')
      .removeClass('layout-switcher__button--active')
      .removeAttr('disabled');

    $(layout).addClass('layout-switcher__button--active')
      .attr('disabled', '');

    productsList.attr('data-layout', $(layout).attr('data-layout'));
    productsList.attr('data-with-features', $(layout).attr('data-with-features'));
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}