<div class="container-fluid main-content g-0">
    <section *ngIf="!isMobile" id="headImage" class="bkgTestata" style="background-image: url('https://static.rms.it/evok/bkg-chi-siamo.jpg');">  
        <div class="bottombar">
            <div class="bottombar-left"></div>
            <div class="bottombar-middle"></div>
            <div class="bottombar-right"></div>
        </div>
    </section>
    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">QUIENES SOMOS</h3>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Evok nació en 2019 con un objetivo ambicioso: garantizar una mejora en tu vehículo, "where performance begins".
            A partir de este momento, nos ha impulsado la determinación de ofrecer productos que satisfagan y superen las necesidades de los motociclistas.
            Cada repuesto Evok es el resultado de una investigación meticulosa y un amor incondicional por las dos ruedas, diseñado para garantizar un rendimiento mejorado.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">La misión de Evok</em>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            La misión de la marca es ser el vínculo entre el original y la performance.
            Los repuestos de la marca garantizan una mejora técnica y estética respecto al original, además de brindar una experiencia de conducción superior.
            Evok es pionero del soft tuning, con un equilibrio perfecto entre pasión, dedicación y desarrollo constante.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">El ADN de la marca</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            Los productos Evok son plug and play, creemos en la simplicidad y facilidad de uso, sin descuidar la calidad del producto.
        </p>
        <br>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">
            <em class="subtitle">Nuestra atención al producto</em><br>
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">            
            Todos los componentes de Evok están sujetos a controles de calidad, desde la producción hasta el embalaje, para garantizar un mejor rendimiento de su vehículo.
        </p>
        <p class="text-big px-5" [ngClass]="{'text-center': isMobile}">                        
            Los repuestos Evok están diseñados para proporcionar la máxima compatibilidad y rendimiento para una amplia gama de motocicletas.
            Tanto si tienes una Vespa, un scooter, un maxi scooter o una moto de 50 a 125cc, nuestros recambios son la elección ideal para garantizar un mejor rendimiento.
        </p>
        <br>
    </section>

    <section *ngIf="!isMobile" id="intro" class="container overflow-hidden">
        <h3 class="mb-5 text-center">LO QUE NOS DISTINGUE</h3>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/supporto.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>APOYO</h5>
                        <span class="text-small">
                            El soporte al cliente de Evok se define por su excelencia y dedicación al mismo.
                            El equipo de especialistas en productos siempre está listo para brindar soporte personalizado, responder preguntas técnicas, ofrecer asesoramiento sobre productos y ayudar con la instalación de repuestos.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/qualità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>CALIDAD</h5>
                        <span class="text-small">
                            La calidad de los productos Evok es una combinación de materiales de primera elección y rigurosos controles de producción.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/performance.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PERFORMANCE</h5>
                        <span class="text-small">
                            Con Evok, cada viaje se convierte en una experiencia de conducción única, donde la innovación y la pasión se combinan para superar todas tus expectativas.
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div  class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/affidabilità.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>FIABILIDAD</h5>
                        <span class="text-small">
                            Para quienes buscan seguridad y comodidad, Evok representa la opción ideal, ya que ofrece componentes ampliamente confiables.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-6">
                <div class="row">
                    <div class="item col-12 col-md-2">
                        <img src="https://static.rms.it/evok/icons/plugandplay.png" width="100%" />
                    </div>
                    <div class="item item-text col-12 col-md-10">
                        <h5>PLUG & PLAY</h5>
                        <span class="text-small">
                            Todos los productos Evok son plug and play con un fácil reemplazo y una instalación segura.
                        </span>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- VERSIONE MOBILE -->

    <section *ngIf="isMobile" id="intro" class="container overflow-hidden">
        <h5 class="mb-5 text-center">COSA CI CONTRADDISTINGUE</h5>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>APOYO</h6>
                <span class="text-small">
                    El soporte al cliente de Evok se define por su excelencia y dedicación al mismo.
                    El equipo de especialistas en productos siempre está listo para brindar soporte personalizado, responder preguntas técnicas, ofrecer asesoramiento sobre productos y ayudar con la instalación de repuestos.
                </span>                                            
            </div>           
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>CALIDAD</h6>
                <span class="text-small">
                    La calidad de los productos Evok es una combinación de materiales de primera elección y rigurosos controles de producción.
                </span>
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>PERFORMANCE</h6>
                <span class="text-small">
                    Con Evok, cada viaje se convierte en una experiencia de conducción única, donde la innovación y la pasión se combinan para superar todas tus expectativas.
                </span>                                            
            </div>
        </div>
        <div class="block-space block-space--layout--divider-xl"></div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">
                <h6>FIABILIDAD</h6>
                <span class="text-small">
                    Para quienes buscan seguridad y comodidad, Evok representa la opción ideal, ya que ofrece componentes ampliamente confiables.
                </span>
            </div>                
        </div>
        <div class="row">
            <div class="item col-12 col-md-12 text-center">                
                <h6>PLUG & PLAY</h6>
                <span class="text-small">
                    Todos los productos Evok son plug and play con un fácil reemplazo y una instalación segura.
                </span>
            </div>
        </div>
    </section>
</div>
<div class="block-space block-space--layout--divider-xl"></div>
<div class="block-space block-space--layout--divider-xl"></div>